import { Component, OnInit, Injector, ViewEncapsulation, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Location as backLocation } from '@angular/common';
import { ClientDashboardServiceProxy,PatientDetailsDto } from '@shared/service-proxies/service-proxies';
import { result } from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'client-boxed-view',
    templateUrl: './client-boxed-view.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClientBoxedViewComponent extends AppComponentBase {

    @Input() targateUrl = '';
    @Input() pageSubHeader = 'Select Client';

    subHeader: string;

    filterText = '';
    public displayClientsPane: boolean;
    active = false;
    Record: any;
    filteredRecord: any;
    resManager;
    treatmentsite;
    selectclientName:string;
    isLoading = false;

    clients = [];

    constructor(injector: Injector, private location: backLocation,
                 private _clientDashboardProxy: ClientDashboardServiceProxy,
                 private _router: Router) {
        super(injector);
       // this.subHeader = this.pageSubHeader;
        this.getAssociatedClients();
    };


    serachfilter(event) {
        debugger;
        this.isLoading = true;
        const wordSearch = event.target.value.trim();

        if (wordSearch == '') {
            this.filteredRecord = this.Record;
        }
        else {
            if (wordSearch === event.target.value.trim()) {
                if (event.target.value.trim()) {
                    this.filteredRecord = this.Record.filter(s => s.clientname.toLowerCase().includes(wordSearch.toLowerCase()));
                }
            }
        }
        this.isLoading = false;
    }

    addNew(): void {
        //
    }


    getAssociatedClients(){
        this.isLoading = true;
        this._clientDashboardProxy.getClientsForTileSelect().subscribe(result=> {  this.isLoading = false;
            this.Record = result
            this.filteredRecord = this.Record;
            this.isLoading = false;
          })
    }

    selectClient(patientId) {
        setTimeout(() => {
            this._router.navigate([this.targateUrl, patientId, 0, "new"]);
        }, 100);
      }

    goToClientDashboard(patientId) {
        setTimeout(() => {
            this._router.navigate(["/app/client/dashboard/new/", patientId]);
        }, 100);
    }

    calculateage(dob) {
        if (dob) {
            var today = new Date();
            var birthDate = moment(dob).toDate();
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        } else {
            return ''
        }
    }

    close() {
        this.setcanDeactivate(true); this.active = false;
        this.location.back();
    }
}
