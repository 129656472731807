<div class="emar-log-table-container">
    <table id="emarLog" class="table table-hover table-bordered">
        <thead>
            <tr>
                <th style="vertical-align: middle; width: 75px;" rowspan="3">Time</th>
                <th style="vertical-align: middle; width: 65px;" rowspan="3">Schedule Start Date</th>
            </tr>
            <tr>
                <td *ngFor="let item of dayArray">{{item}}</td>

            </tr>

            <tr>
                <td *ngFor="let item of input">{{item}}</td>

            </tr>
        </thead>

        <tbody>


            <tr *ngFor="let item of emarCalenderData.scheduleTimes" >
                <td *ngIf="scheduleType != 4">{{item.scheduleTime}}</td>
                <td *ngIf="scheduleType == 4">PRN</td>

                <td>{{startDate | date:'M/d/yy'}}</td>

                <td *ngFor="let item2 of item.historyDataList"
                 [ngClass]="item2.status=='Administered'? item2.medicationErrorId>0? 'bg-danger' : 'bg-success' : item2.status=='Omitted'? item2.holdScheduleMedicationId != null? 'bg-warning' : 'bg-info': item2.missingMed==true?'bg-danger' :'bg-white'"
                 (click)="calenderClicked(item2)" >

                    <a class="text-white" *ngIf="item2.missingMed==false" title="{{item2.status}} {{item2.medicationErrorId>0? 'with error,': ''}} On {{item2.administeredOn|date:'M/d/yy'}}  @ {{item2.administeredOn|date:'h:mm a'}} By {{item2.userFName}} {{item2.userLName}}"  >{{item2.userNameInitials}}</a>
                    <a class="text-white" *ngIf="item2.missingMed==true" title="Missed"  >{{item2.userNameInitials}}</a>
                </td>


            </tr>

        </tbody>

    </table>


    <div class="d-flex card-body pb-0 {{isLoading==true ? 'overlay-wrapper' : ''}}">
        <div class="d-flex expand-icon" style="display: none;">
            <i class="flaticon-arrows" style="display: none;"></i>
        </div>

        <div class="overlay-layer bg-dark-o-10" *ngIf="isLoading">
            <div class="spinner spinner-primary"></div>
        </div>
    </div>
    <table class="card card-custom mb-4  {{isLoading==true ? 'overlay overlay-block' : ''}} table table-hover table-bordered"
           *ngIf="isLoading" style="border-bottom-left-radius: 0px;">
        
        <thead>

        </thead>

        <tbody>

            <tr>
            </tr>

        </tbody>

    </table>
</div>
