import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { outputs } from '@syncfusion/ej2-angular-navigations/src/sidebar/sidebar.component';
import { CreateOrEditMedicationErrorDto, CreateOrEditProgramEnrollmentDto, EMarsServiceProxy, GetEMarForViewDto, MedicationErrorsServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
@Component({
    selector: 'add-error-dialog',
    templateUrl: './add-error-dialog.component.html',
    styleUrls: ['add-error-dialog.component.css']
})
export class AddErrorDialogComponent extends AppComponentBase {

    @ViewChild('addErrorModal', { static: true }) modal: ModalDirective;
    @ViewChild('addErrorForm', { static: true }) form: NgForm;
    @Output() saveError: EventEmitter <any> =new EventEmitter<any> ();

    active = false;
    saving = false;
    date: Date;
    patientsName:string;
    medicationScheduleId
    medicationName: string;
    clientMedicationId;
    houseNurseName : CreateOrEditProgramEnrollmentDto;
    patientId;
    patientEMr = new GetEMarForViewDto();
    medicationError = new CreateOrEditMedicationErrorDto();
    errorHistory
    clientData: any[] = [];
    selectclientName:string;
    constructor(injector: Injector,
        private _medicationErrorsServiceProxy: MedicationErrorsServiceProxy,private _EMarsServiceProxy: EMarsServiceProxy,private cd: ChangeDetectorRef) {
        super(injector);
    }

    counter(i: number): any {
        return new Array(i);
    }

   
    show(emar): void {
        if (emar) {
            this.medicationError = new CreateOrEditMedicationErrorDto();            
            this.patientEMr = new  GetEMarForViewDto();
            this.patientEMr = emar;
            this.patientsName = this.patientEMr.clientMedication.patientsname;
            this.medicationScheduleId = this.patientEMr.clientMedicationScheduleTime.id;
            this.clientMedicationId = this.patientEMr.clientMedication.clientMedication.id;
            this.patientId = this.patientEMr.clientMedication.patientId;
            // this.selectclientName = this.houseNurseName.houseNurse;
            // this.medicationError.nurseNotifiedId = this.houseNurseName.houseNurseId.toString();
            this.medicationName = this.patientEMr.clientMedication.drugDescription + "(" + this.patientEMr.clientMedication.strength + " " + this.patientEMr.clientMedication.drug_strengthUnit + " " + this.patientEMr.clientMedication.drug_DoseForm + ")"  ;
            this.modal.show();
            this.medicationError.eMarId = this.patientEMr.clientMedicationScheduleTime.emarId;
            this.medicationError.medicationScheduleTimeId = this.patientEMr.clientMedicationScheduleTime.id;         
            this.onShown();
        }
    }

    nurseNotifiedChecked(event: any)
    {
        if(event.currentTarget.checked)
        {
            this.selectclientName = this.houseNurseName.houseNurse;
            this.medicationError.nurseNotifiedId = this.houseNurseName.houseNurseId.toString();
        }
        else
        {
            this.selectclientName = null;
            this.medicationError.nurseNotifiedId = null;

        }

    }

    onShown(): void {
        var sDate = new Date; 
        this._medicationErrorsServiceProxy.getMedicationErrorForEdit(this.medicationError.eMarId,this.medicationScheduleId,moment(sDate))
            .subscribe(result => {
                if (result.medicationError) {
                    this.medicationError = result.medicationError;
                    this.selectclientName = this.medicationError.nurseName;
                }
            });
            this._medicationErrorsServiceProxy.getMedicationErrorHistory(this.clientMedicationId,this.patientId)
            .subscribe(result => {
                {
                    this.errorHistory = result;
                }
            })
 
        
    }

    save(): void {
        this.medicationError.scheduleDate = moment(this.date);
        this._medicationErrorsServiceProxy.createOrEdit(this.medicationError)
        .subscribe(result => {
            this.close();
           // this.notify.info(this.l('SavedSuccessfully'));
            this.notify.info(this.l('Error recorded successfully.'));
        });
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }
    searchClient(event: any) {
        
        var trimmed = this.selectclientName.trim();
        if (trimmed.length == 0) {         
            this.clientData = null;
            this.clientData = [];           
            this.cd.detectChanges();
        }
        let wordSearch = this.selectclientName.trim();
        setTimeout(() => {
            if (wordSearch == this.selectclientName.trim()) {
                if (this.selectclientName.trim()) {                
                    this._EMarsServiceProxy.getassociatedHouseNurse(wordSearch,this.patientId)
                        .pipe(finalize(() => { }))
                        .subscribe(data => {                         
                            this.clientData = data;                           
                            if (data.length == 0)
                                this.selectclientName = null;                          
                            this.cd.detectChanges();
                        })
                } else {
                    this.selectclientName = null;
                }
            }          
        }, 2000);
    } 

    onSelect(event) {
        //  this.PatientId = patientId;
          // this.addmedicationheader.recordid = this.PatientId;
          let data = event
          this.selectclientName = data.userName; 
         this.medicationError.nurseNotifiedId = data.userId;         
    }
   
}
