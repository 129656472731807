import { Component, Injector, OnInit, ViewEncapsulation, NgZone, Input, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NotificationServiceProxy, UserNotification, GetNotificationsOutput, UserNotificationState } from '@shared/service-proxies/service-proxies';
import { IFormattedUserNotification, UserNotificationHelper } from './UserNotificationHelper';
import * as _ from 'lodash';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
const { htmlToText } = require('html-to-text');
@Component({
    templateUrl: './header-notifications.component.html',
    selector: 'header-notifications',
    styleUrls: ['./header-notifications.css'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderNotificationsComponent extends AppComponentBase implements OnInit {

    notifications: IFormattedUserNotification[] = [];
    unreadNotificationCount = 0;
    @Input() isDropup = false;
    @Input() customStyle = 'btn btn-icon btn-dropdown btn-clean btn-lg mr-1';
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

    title: string;
    announcement: string;
    docId: number;
    docpath: string;
    ancount: number;
    announcementtype: string;
    docname: string;
    isdisabled: boolean = true;
    _notification: IFormattedUserNotification;
    _notificationlist: UserNotification[] = [];
    announcementunreadcount = 0;
    announcementcreator: String;
    announcementcreationtime;
    totalannouncementcount = 0;
    isrecall = false;
    titlemsg='OK'
    constructor(
        injector: Injector,
        private _router: Router,
        private _notificationService: NotificationServiceProxy,
        private _userNotificationHelper: UserNotificationHelper,
        public _zone: NgZone
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.loadNotifications();
        this.registerToEvents();
    }

    loadNotifications(): void {
        try {
            if (UrlHelper.isInstallUrl(location.href)) {
                return;
            }

            this._notificationService.getUserNotifications(undefined, undefined, undefined, 1000, 0).subscribe(result => {

                this.unreadNotificationCount = result.unreadCount;
                this._notificationlist = result.items.filter(s => s.notification.entityId == 1 && s.state == 0);
                let today = new Date();
                today.setDate(today.getDate() + 1);
                var _notificationlistwithoutendson = this._notificationlist.filter(s => today < new Date(s.notification.data.properties.endson));

                this.announcementunreadcount = _notificationlistwithoutendson.length;

                if (this.announcementunreadcount > 0) {
                    this.ancount = 0;
                    this.totalannouncementcount = this.announcementunreadcount
                    let notification: UserNotification = this._notificationlist[0];
                    try {
                        if (!notification.notification.data.properties.endson) {
                            let enddate = new Date()
                            enddate.setDate(today.getDate() + 73000);
                            notification.notification.data.properties.endson = enddate;
                        }

                        let endson = new Date(notification.notification.data.properties.endson);
                        // if (this.isrecall == true)
                        //   this.modalassignment(notification);
                        if ((today < endson) || this.isrecall == true)
                            this.modalassignment(notification);
                    } catch {
                        this.modalassignment(notification)
                    }
                }
                else {
                    try {

                        let notification: UserNotification = this._notificationlist[0];
                        if (notification.notification.data.properties.recall == true)
                            this.modalassignment(notification);
                    }
                    catch { }
                }

                this.notifications = [];
                _.forEach(result.items, (item: UserNotification) => {
                    this.notifications.push(this._userNotificationHelper.format(<any>item));
                });
            });
        } catch {}
    }

    registerToEvents() {
        let self = this;
        function onNotificationReceived(userNotification) {
            
            let notification: UserNotification = userNotification;
            this.ancount = 0;
            this.modalassignment(notification)           
            self._userNotificationHelper.show(userNotification);
            self.loadNotifications();
        }

        abp.event.on('abp.notifications.received', userNotification => {
            self._zone.run(() => {
                onNotificationReceived(userNotification);
            });
        });

        function onNotificationsRefresh() {
            self.loadNotifications();
        }

        abp.event.on('app.notifications.refresh', () => {
            self._zone.run(() => {
                onNotificationsRefresh();
            });
        });

        function onNotificationsRead(userNotificationId) {
            for (let i = 0; i < self.notifications.length; i++) {
                if (self.notifications[i].userNotificationId === userNotificationId) {
                    self.notifications[i].state = 'READ';
                    self.notifications[i].isUnread = false;
                }
            }

            self.unreadNotificationCount -= 1;
        }

        abp.event.on('app.notifications.read', userNotificationId => {
            self._zone.run(() => {
                onNotificationsRead(userNotificationId);
            });
        });
    }

    setAllNotificationsAsRead(): void {
        this._userNotificationHelper.setAllAsRead();
    }

    openNotificationSettingsModal(): void {
        this._userNotificationHelper.openSettingsModal();
    }

    setNotificationAsRead(userNotification: IFormattedUserNotification): void {
        if (userNotification.state !== 'READ') {
            this._userNotificationHelper.setAsRead(userNotification.userNotificationId);
        }
    }
    truncateString(text: any, length: number): string {
        const plaintext = htmlToText(text, {
            wordwrap: 130
        });
        return abp.utils.truncateStringWithPostfix(plaintext, length);
    }
    gotoUrl(url): void {
        if (url) {
            this._router.navigate([url]);
            //location.href = url;
        }
    }
    okclick(ancnt) {
        if (this.isdisabled)
            return;
        this.setNotificationAsRead(this._notification);
        this.totalannouncementcount = this.totalannouncementcount - 1;
        this.modal.hide();
        let cnt = ancnt + 1;
        if (cnt < this.announcementunreadcount) {
            let notification: UserNotification = this._notificationlist[cnt];
            this.ancount = cnt;
            this.modalassignment(notification)
        }
    }
    ondocumentclick() {
        window.open(this.docpath);
        this.isdisabled = false;
    }
    modalassignment(notification: UserNotification) {        
        if (notification.notification.entityId == 1 && notification.state == UserNotificationState.Unread) {
            this.announcement = notification.notification.data.properties.Message.name
            this.title = notification.notification.data.properties.title;
            this.announcementtype = notification.notification.data.properties.type;
            this.docname = notification.notification.data.properties.docname;
            this.docpath = notification.notification.data.properties.docpath;
            this.announcementcreator = notification.notification.data.properties.creator;
            //this.announcementcreationtime = notification.notification.creationTime.format('MM-dd-yyyy hh:mm')            
            this.announcementcreationtime = notification.notification.creationTime;
            if (!this.docpath) {
                this.isdisabled = false;
                this.titlemsg = 'OK';
            }
            else {
                this.isdisabled = true;
                this.titlemsg = 'click only if there is an attachment in the announcement, "Please see attachment"';
            }
            this._notification = this._userNotificationHelper.format(<any>notification);

            try {               
                this.isrecall = notification.notification.data.properties.recall;
            } catch {
                this.isrecall = false;
            }
            //this.ancount = cnt;
            this.modal.show();
        }
    }
}
