
<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid {{isLoading==true ? 'overlay overlay-block' : ''}} "  >
        <sub-header [title]="pageSubHeader" description="Select Client">
            <div role="actions" class="d-flex " id="kt_subheader_search">
                <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div class="flex-column mr-5" id="kt_subheader_search_form" title="Search Client"
                         >
                        <div class="input-group input-group-sm input-group-solid" style="max-width: 175px">
                            <input type="text" class="form-control" id="kt_subheader_search_form"
                                   placeholder="Search Client..." [(ngModel)]="filterText" (input)="serachfilter($event)">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    <span class="svg-icon">
                                        <!--begin::Svg Icon | path:https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Search.svg-->
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                             viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                      fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                      fill="#000000" fill-rule="nonzero"></path>
                                            </g>
                                        </svg>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <!--<i class="flaticon2-search-1 icon-sm"></i>-->
                                </span>
                            </div>
                        </div>
                    </div>
                    <a title="Close" (click)="close()" 
                       class="bg-gray-600 btn btn-circle btn-shadow-custom btn-hover-bg-danger pr-2">
                        <i class="flaticon2-cross icon-sm-1x text-white pr-2"></i>
                    </a>
                </div>
            </div>
        </sub-header>

        <div [class]="containerClass ">
            <div class="d-flex flex-column-fluid  {{isLoading==true ? 'overlay-wrapper' : ''}} ">
                <!--begin::Container-->
                <div class="w-100">
                    <div class="overlay-layer bg-dark-o-10" *ngIf="isLoading">
                        <div class="spinner spinner-primary mt-20"></div>
                    </div>
                    <!--begin::Row-->
                    <div class="row"  *ngIf="!isLoading">
                        <!--begin::Col-->
                        <div class="w-100 card card-custom gutter-b card-stretch" *ngIf="filteredRecord.length == 0" >
                            <div class="card-body pt-4 pb-0" >
                                <div role="alert" class="alert alert-custom alert-notice alert-light-warning fade show margin-b">
                                    <div class="alert-icon"><i class="far fa-lightbulb"></i></div>
                                    <div class="alert-text font-sizem">
                                        <span class="mr-3">
                                            Client doest not exist, please refine your search criteria. Please also check if you are assigned a case load.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6" *ngFor="let client of filteredRecord">
                            <!--begin::Card-->
                            <div class="card card-custom gutter-b card-stretch">
                                <!--begin::Body-->
                                <div class="card-body pt-4">

                                    <!--begin::User-->
                                    <div class="d-flex align-items-end mb-7">
                                        <!--begin::Pic-->
                                        <div class="d-flex align-items-center">
                                            <!--begin::Pic-->
                                            <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                                <div title="Click to got to {{client.clientname}}'s Dashboard" (click)="goToClientDashboard(client.patientsId)" class="symbol symbol-circle symbol-lg-75 cursor-pointer">
                                                    <img src="{{client.photo}}" alt="">
                                                </div>
                                            </div>
                                            <!--end::Pic-->
                                            <!--begin::Title-->
                                            <div class="d-flex flex-column">
                                                <a title="Click to got to {{client.clientname}}'s Dashboard" (click)="goToClientDashboard(client.patientsId)" class="text-dark font-weight-bolder text-hover-primary font-size-h4 mb-0 cursor-pointer">{{client.clientname}}</a>
                                                <span class="text-muted font-weight-bold">{{client.dob==null?'N.A':client.dob| date: "MM/dd/yyyy"}} | {{client.genderName}}/{{calculateage(client.dob)}}</span>
                                            </div>
                                            <!--end::Title-->
                                        </div>
                                        <!--end::Title-->
                                    </div>
                                    <!--end::User-->
                                    <!--begin::Info-->
                                    <div class="mb-7">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span class="text-dark-75 font-weight-bolder mr-2">Location:</span>
                                            <a class="text-muted text-hover-primary">{{client.treatmentSiteName==null? 'NA' : client.treatmentSiteName | titlecase}}</a>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-cente my-1">
                                            <span class="text-dark-75 font-weight-bolder mr-2">Res Manager:</span>
                                            <a class="text-muted text-hover-primary">{{client.resdentialManager==null? 'NA' : client.resdentialManager | titlecase}}</a>
                                        </div>
                                    </div>
                                    <!--end::Info-->
                                    <a class="btn btn-block btn-sm btn-light-primary font-weight-bolder text-uppercase py-4" (click)="selectClient(client.patientsId)">Select</a>
                                </div>
                                <!--end::Body-->
                            </div>
                            <!--end::Card-->
                        </div>
                        <!--end::Col-->
                    </div>
                    <!--end::Row-->
                   
                    <!--begin::Pagination-->
                   <div class="card card-custom" *ngIf="!isLoading && clients.length > 12">
                       <div class="card-body py-7">
                           <div class="d-flex justify-content-center align-items-center flex-wrap">
                               <div class="d-flex flex-wrap mr-3">
                                   <a href="#" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                                       <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                                   </a>
                                   <a href="#" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                                       <i class="ki ki-bold-arrow-back icon-xs"></i>
                                   </a>
                                   <a href="#" class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">...</a>
                                   <a href="#" class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">23</a>
                                   <a href="#" class="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1">24</a>
                                   <a href="#" class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">25</a>
                                   <a href="#" class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">26</a>
                                   <a href="#" class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">27</a>
                                   <a href="#" class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">28</a>
                                   <a href="#" class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">...</a>
                                   <a href="#" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                                       <i class="ki ki-bold-arrow-next icon-xs"></i>
                                   </a>
                                   <a href="#" class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                                       <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                                   </a>
                               </div>
                           </div>
                       </div>
                   </div>
                    <!--end::Pagination-->
                </div>
                <!--end::Container-->
            </div>
        </div>
    </div>
</div>
