import { Component, Injector, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: 'addnew',
    templateUrl: './add-new.component.html'
})
export class AddNewComponent extends AppComponentBase {
    

    public displayCreatePane: boolean;
    count: number = 1;
    filterDoesNotMatch: boolean = false;
    name1 : Array<SearchBarForms>;
    filterText = '';
    public SearchBarlist: Array<SearchBarForms> = [];

    public SearchBar: Array<SearchBarForms> = [
        { formName: 'Certificate Upload', formPermission: this.permission.isGranted('Pages.CertificateUploads'), formIcon: 'fas fa-certificate', formPermissionCreate: this.permission.isGranted('Pages.CertificateUploads.Create') },
        { formName: 'Course', formPermission: this.permission.isGranted('Pages.Courses'), formIcon: 'fas fa-book-reader', formPermissionCreate: this.permission.isGranted('Pages.Courses.Create') },
        //{ formName: 'Nursing Diary', formPermission: this.permission.isGranted('Pages.NursingDiaries'), formIcon: 'kt-menu__link-icon flaticon-interface-3', formPermissionCreate: this.permission.isGranted('Pages.NursingDiaries.Create')},
        { formName: 'Client Profile', formPermission: this.permission.isGranted('Pages.Patients'), formIcon: 'kt-menu__link-icon fas fa-user-tie', formPermissionCreate: this.permission.isGranted('Pages.Patients.Create') },
        { formName: 'Nursing Care Plan', formPermission: this.permission.isGranted('Pages.NursingCarePlans'), formIcon: 'kt-menu__link-icon fa fa-street-view', formPermissionCreate: this.permission.isGranted('Pages.NursingCarePlans.Create') },
        { formName: 'Nursing Assessment', formPermission: this.permission.isGranted('Pages.NursingAssessments'), formIcon: 'far fa-edit', formPermissionCreate: this.permission.isGranted('Pages.NursingAssessments.Create') },
        { formName: 'Incident Report', formPermission: this.permission.isGranted('Pages.IncidentReports'), formIcon: 'kt-menu__link-icon fa fa-map-marker', formPermissionCreate: this.permission.isGranted('Pages.IncidentReports.Create') },
        { formName: 'Sites', formPermission: this.permission.isGranted('Pages.Sites'), formIcon: 'kt-menu__link-icon fa fa-map-marker', formPermissionCreate: this.permission.isGranted('Pages.Sites.Create') },
        { formName: 'Programs', formPermission: this.permission.isGranted('Pages.Programs'), formIcon: 'kt-menu__link-icon fas fa-braille', formPermissionCreate: this.permission.isGranted('Pages.Programs.Create') },
        { formName: 'Roles', formPermission: this.permission.isGranted('Pages.Administration.Roles'), formIcon: 'kt-menu__link-icon flaticon-suitcase', formPermissionCreate: this.permission.isGranted('Pages.Administration.Roles.Create') },
        { formName: 'Users', formPermission: this.permission.isGranted('Pages.Administration.Users'), formIcon: 'kt-menu__link-icon flaticon-users', formPermissionCreate: this.permission.isGranted('Pages.Administration.Users.Create') },
        { formName: 'Announcement', formPermission: this.permission.isGranted('Pages.Announcements'), formIcon: 'fa fa-bullhorn', formPermissionCreate: this.permission.isGranted('Pages.Announcements.Create') },
        { formName: 'Seizure Logs', formPermission: this.permission.isGranted('Pages.SeizureLogs'), formIcon: 'fa fa-headphones', formPermissionCreate: this.permission.isGranted('Pages.SeizureLogs.Create') },
        { formName: 'Bowel Moments', formPermission: this.permission.isGranted('Pages.BowelMovements'), formIcon: 'flaticon2-menu-1', formPermissionCreate: this.permission.isGranted('Pages.BowelMovements.Create') },
        { formName: 'Daily Logs/Attendance', formPermission: this.permission.isGranted('Pages.DailyLogs'), formIcon: 'flaticon2-box-1', formPermissionCreate: this.permission.isGranted('Pages.DailyLogs.Create') },
        { formName: 'Behavior Logs', formPermission: this.permission.isGranted('Pages.BehaviorLogs'), formIcon: 'fab fa-blogger-b', formPermissionCreate: this.permission.isGranted('Pages.BehaviorLogs.Create') },
        { formName: 'Goal / Outcome Logs', formPermission: this.permission.isGranted('Pages.GoalLogs'), formIcon: 'fas fa-bullseye', formPermissionCreate: this.permission.isGranted('Pages.GoalLogs.Create') },
        { formName: 'Health Logs', formPermission: this.permission.isGranted('Pages.HealthLogs'), formIcon: 'fas fa-h-square', formPermissionCreate: this.permission.isGranted('Pages.HealthLogs.Create') },
        { formName: 'Family/Team Member Logs', formPermission: this.permission.isGranted('Pages.FamilyMemberLogs'), formIcon: 'fas fa-users', formPermissionCreate: this.permission.isGranted('Pages.FamilyMemberLogs.Create') },
        { formName: 'Temperature Logs', formPermission: this.permission.isGranted('Pages.TemparatureLogs'), formIcon: 'fas fa-temperature-high', formPermissionCreate: this.permission.isGranted('Pages.TemparatureLogs.Create') },
        { formName: 'Transportation Logs', formPermission: this.permission.isGranted('Pages.TransportationLogs'), formIcon: 'fas fa-bus-alt', formPermissionCreate: this.permission.isGranted('Pages.TransportationLogs.Create') },
        { formName: 'PCP/BP', formPermission: this.permission.isGranted('Pages.PCBs'), formIcon: 'fa fa-life-ring', formPermissionCreate: this.permission.isGranted('Pages.PCBs.Create') },
        { formName: 'Disinfect Logs', formPermission: this.permission.isGranted('Pages.DisinfectLogs'), formIcon: 'fas fa-broom ', formPermissionCreate: this.permission.isGranted('Pages.DisinfectLogs.Create') },
        { formName: 'Blood Sugar Logs', formPermission: this.permission.isGranted('Pages.BloodSugarLogs'), formIcon: 'fas fa-bong', formPermissionCreate: this.permission.isGranted('Pages.BloodSugarLogs.Create') },
        { formName: 'Nursing Logs', formPermission: this.permission.isGranted('Pages.NursingLogs'), formIcon: 'fas fa-user-nurse', formPermissionCreate: this.permission.isGranted('Pages.NursingLogs.Create') },

        { formName: 'Fire Drill', formPermission: this.permission.isGranted('Pages.FireDrills'), formIcon: 'fas fa-user-nurse', formPermissionCreate: this.permission.isGranted('Pages.FireDrills.Create') },
        { formName: 'Facility Report', formPermission: this.permission.isGranted('Pages.FacilityReports'), formIcon: 'fas fa-user-nurse', formPermissionCreate: this.permission.isGranted('Pages.FacilityReports.Create') },

        { formName: 'Document Center', formPermission: this.permission.isGranted('Pages.DcoumentCenter'), formIcon: 'far fa-folder', formPermissionCreate: this.permission.isGranted('Pages.DcoumentCenter.Upload') },

        { formName: 'Nursing Assessent Setting', formPermission: this.permission.isGranted('Pages.WFAssessment'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFAssessment') },
        { formName: 'Nursing Care Plan Setting', formPermission: this.permission.isGranted('Pages.WFPnursingcare'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFPnursingcare') },


        { formName: 'PCP/BP Setting', formPermission: this.permission.isGranted('Pages.WFPcpbp'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFPcpbp') },
        { formName: 'Incident Report Setting', formPermission: this.permission.isGranted('Pages.WFIncidentreport'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFIncidentreport') },
        { formName: 'Nursing Log Setting', formPermission: this.permission.isGranted('Pages.WFNursingLog'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFNursingLog') },

        { formName: 'Behavior Log Setting', formPermission: this.permission.isGranted('Pages.WFBehaviorLog'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFBehaviorLog') },
        { formName: 'BloodSugar Log Setting', formPermission: this.permission.isGranted('Pages.WFBloodSugarLog'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFBloodSugarLog') },
        { formName: 'Bowel Movements Setting', formPermission: this.permission.isGranted('Pages.WFBowelMovement'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFBowelMovement') },

        { formName: 'Daily Log Setting', formPermission: this.permission.isGranted('Pages.WFDailyLog'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFDailyLog') },
        { formName: 'Disinfect Log Setting', formPermission: this.permission.isGranted('Pages.WFDisinfectLog'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFDisinfectLog') },
        { formName: 'Family/Team Member Log Setting', formPermission: this.permission.isGranted('Pages.WFFamilyMemberLog'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFFamilyMemberLog') },

        { formName: 'Goal/Outcome Log Setting', formPermission: this.permission.isGranted('Pages.WFGoalLog'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFGoalLog') },
        { formName: 'Health Log Setting', formPermission: this.permission.isGranted('Pages.WFHealthLog'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFHealthLog') },
        { formName: 'Seizure Log Setting', formPermission: this.permission.isGranted('Pages.WFSeizureLog'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFSeizureLog') },

        { formName: 'Temperature Log Setting', formPermission: this.permission.isGranted('Pages.WFTemperatureLog'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFTemperatureLog') },
        { formName: 'Transportation Log Setting', formPermission: this.permission.isGranted('Pages.WFTransportationLog'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFTransportationLog') },
        { formName: 'FireDrill Setting', formPermission: this.permission.isGranted('Pages.WFFireDrill'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFFireDrill') },
         
        //{ formName: 'Behavior Log Setting', formPermission: this.permission.isGranted('Pages.WFBehaviorLog'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFBehaviorLog') },
        //{ formName: 'BloodSugar Log Setting', formPermission: this.permission.isGranted('Pages.WFBloodSugarLog'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFBloodSugarLog') },
        //{ formName: 'Bowel Movements Setting', formPermission: this.permission.isGranted('Pages.WFBowelMovement'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFBowelMovement') },
      
      
         { formName: 'Referrals', formPermission: this.permission.isGranted('Pages.Referrals'), formIcon: 'fa fa-cubes', formPermissionCreate: this.permission.isGranted('Pages.Referrals.Create') },
        { formName: 'Referrals Setting', formPermission: this.permission.isGranted('Pages.WFReferrals'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFReferrals') },
        { formName: 'Facility Report Setting', formPermission: this.permission.isGranted('Pages.WFFacilityReport'), formIcon: 'fas fa-cog', formPermissionCreate: this.permission.isGranted('Pages.WFFacilityReport') },

        { formName: 'Medication', formPermission: this.permission.isGranted('Pages.ClientMedications'), formIcon: 'fas fa-prescription', formPermissionCreate: this.permission.isGranted('Pages.ClientMedications.Create') },
        { formName: 'Medication Settings', formPermission: this.permission.isGranted('Pages.WFMedication'), formIcon: 'fas fa-file-prescription', formPermissionCreate: this.permission.isGranted('Pages.WFMedication') },
        { formName: 'eMAR Settings', formPermission: this.permission.isGranted('Pages.WFeMAR'), formIcon: 'fas fa-pills', formPermissionCreate: this.permission.isGranted('Pages.WFeMAR') },

    ]

    constructor(
        injector: Injector,
        private _router: Router,
    ) {
        super(injector);
    }

    ngOnInit(): void {

        
        this.SearchBar.sort((a, b) => {
            if (a.formName < b.formName) return -1;
            else if (a.formName > b.formName) return 1;
            else return 0;
        });
        this.SearchBarlist = this.SearchBar;
    }

    openSideBar() {
        this.displayCreatePane = true;
    }
    closeSideBar(){
        this.displayCreatePane = false;
    }
    redirectonForm(formanme: string, id: number, mode: string) {
 
        if (formanme == "Nursing Assessent Setting")
            this._router.navigate(['/app/assessment/settings']);
        if (formanme == "eMAR Settings")
            this._router.navigate(['/app/settings/eMARsettings']);
 
        if (formanme == "Medication")
            this._router.navigate(['/app/medications/select/client']);

        if (formanme == "Medication Settings")
            this._router.navigate(['/app/settings/Medication/clientmedicationsettings']);

        if (formanme == "Nursing Care Plan Setting")
            this._router.navigate(['app/settings/nursingcare/NursingCareSettings']);

        if (formanme == "Incident Report Setting")
            this._router.navigate(['/app/settings/incidentreport/IncidentReportSettings']);

        if (formanme == "Nursing Log Setting")
            this._router.navigate(['/app/settings/nursinglog/NursingLogSettings']);

        if (formanme == "PCP/BP Setting")
            this._router.navigate(['/app/settings/pcpbp/PCBBPSettings']);

        if (formanme == "Document Center")
            this._router.navigate(['app/client/patients/cPdocs/Documentcenter/upload']);

        if (formanme == "Nursing Assessment")
            this._router.navigate(['app/assessment/nursingAssessments/create-or-edit-nursingAssessment-modal', id, 'txt', 0]);
        if (formanme == "Nursing Care Plan")
            this._router.navigate(['app/nursing/nursingCarePlan/create-or-edit-nursingCarePlan-modal', id, 0, mode, 0]);
        if (formanme == "Client Profile")
            this._router.navigate(['app/client/patients/create-or-edit-patients-modal', id, mode]);
        if (formanme == "patient")
            this._router.navigate(['app/client/patients/create-or-edit-patients-modal', id, mode]);
        if (formanme == "Certificate Upload")
            this._router.navigate(['/app/training/ListUploadCerts/list-upload-certs/upload']);
        if (formanme == "Course")
            this._router.navigate(['/app/training/course/courses/createOrEdit', id, mode]);
        //if (formanme == "Nursing Diary")
        //    this._router.navigate(['app/nursing/nursingDiaries/create-or-edit-nursingDiary-modal', id, mode]);
        if (formanme == "Incident Report")
            this._router.navigate(['app/incident/incidentReports/create-or-edit-incidentReport-modal', id, 'txt', 0]);
        if (formanme == "Referrals")
            this._router.navigate(['app/main/referralModule/referrals/createOrEdit', id, 'create']);
                                   
        if (formanme == "Announcement")
            this._router.navigate(['/app/main/announcements/announcements']);

        if (formanme == "Sites")
            this._router.navigate(['/app/masters/site/create-or-edit-site-modal', id]);
        if (formanme == "Programs")
            this._router.navigate(['/app/masters/programDepartment/create-or-edit-program-modal', id]);
        if (formanme == "Roles")
            this._router.navigate(['/app/admin/roles', id.toString()]);
        if (formanme == "Users")
            this._router.navigate(['/app/admin/users', id.toString()]);
        //if (formanme == "Announcement")
        //    this._router.navigate(['/app/main/announcements/announcements', id.toString()]);



        if (formanme == "Seizure Logs")
            this._router.navigate(['app/notes/seizureLogs/create-or-edit-seizureLog-modal', id, 'txt', 0]);
        if (formanme == "Bowel Moments")
            this._router.navigate(['app/notes/bowelMovements/createOrEdit', id, 'create', 0]);
        if (formanme == "Daily Logs/Attendance")
            this._router.navigate(['app/notes/dailyLogs/createOrEdit', id, 'create', 0]);

        if (formanme == "Health Logs")
            this._router.navigate(['app/notes/healthLogs/createOrEdit', id, 'create', 0]);

        if (formanme == "Behavior Logs")
            this._router.navigate(['app/notes/behaviorLogs/createOrEdit', id, 'create', 0]);
        if (formanme == "Goal / Outcome Logs")
            this._router.navigate(['app/notes/goalLogs/createOrEdit', id, 'create', 0]);
        if (formanme == "Family/Team Member Logs")
            this._router.navigate(['app/notes/familyMemberLogs/createOrEdit', id, 'create', 0]);
        if (formanme == "Temperature Logs")
            this._router.navigate(['app/notes/temparatureLogs/createOrEdit', id, 'create', 0]);
        if (formanme == "Transportation Logs")
            this._router.navigate(['app/notes/transportationLogs/createOrEdit', id, 'create', 0]);
        if (formanme == "PCP/BP")
            this._router.navigate(['app/pcbbp/pcBs/create-or-edit-pcb.component', id, 'txt', 0]);
        if (formanme == "Disinfect Logs")
            this._router.navigate(['app/notes/disinfectLogs/createOrEdit', id, 'create', 0]);
        if (formanme == "Blood Sugar Logs")
            this._router.navigate(['app/notes/bloodSugarLogs/createOrEdit', id, 'create', 0]);
        if (formanme == "Nursing Logs")
            this._router.navigate(['app/notes/nursingLogs/createOrEdit', id, 'create', 0]);
        if (formanme == "Fire Drill")
            this._router.navigate(['app/fireDrills/fireDrills/create-or-edit-fireDrill-modal', id, 'txt']);
        if (formanme == "Facility Report")
            this._router.navigate(['app/facilityReports/facilityReports/create-or-edit-facilityReport-modal', id, 'txt']);

        ///////
        if (formanme == "Behavior Log Setting")
            this._router.navigate(['app/settings/behaviorlog/behaviorlogsettings']);

        if (formanme == "BloodSugar Log Setting")
            this._router.navigate(['app/settings/bloodsugar/bloodsugarlogsettings']);

        if (formanme == "Bowel Movements Setting")
            this._router.navigate(['app/settings/bowelmovement/bowelmovementsettings']);

        if (formanme == "Daily Log Setting")
            this._router.navigate(['app/settings/dailylog/dailylogsettings']);
                                   
        if (formanme == "Disinfect Log Setting")
            this._router.navigate(['app/settings/disinfectLog/disinfectlogsettings']);

        if (formanme == "Family/Team Member Log Setting")
            this._router.navigate(['app/settings/familymemberlog/familymemberlogsettings']);

        if (formanme == "Goal/Outcome Log Setting")
            this._router.navigate(['app/settings/goallog/goallogsettings']);

        if (formanme == "Health Log Setting")
            this._router.navigate(['app/settings/healthlog/healthlogsettings']);

        if (formanme == "Seizure Log Setting")
            this._router.navigate(['app/settings/seizurelog/seizurelogsettings']);

        if (formanme == "Temperature Log Setting")
            this._router.navigate(['app/settings/temperaturelog/temperaturelogsettings']);

        if (formanme == "Transportation Log Setting")
            this._router.navigate(['app/settings/transportationlog/transportationlogsettings']);

        if (formanme == "FireDrill Setting")
            this._router.navigate(['app/settings/firedrill/firedrillsettings']);
        
        if (formanme == "Referrals Setting")
            this._router.navigate(['app/settings/referralssetting/referralssetting']);

        if (formanme == "Facility Report Setting")
            this._router.navigate(['app/settings/facilityreportssetting/facilityreportssetting']);


    }
    // searchpanelsforCreateForms(inputid, ulid) {

    //     this.count = 0;
    //     var input, filter, ul, li, a, i, txtValue;
    //     input = document.getElementById(inputid);
    //     filter = input.value.toUpperCase();
    //     // ul = document.getElementById("myUL");
    //     ul = document.getElementById(ulid);
    //     li = ul.getElementsByTagName("li");
    //     for (i = 0; i < li.length; i++) {
    //         a = li[i].getElementsByTagName("a")[1];
    //         txtValue = a.textContent || a.innerText;
    //         if (txtValue.toUpperCase().indexOf(filter) > -1) {
    //             li[i].style.display = "";
    //             this.count++;
    //         } else {
    //             li[i].style.display = "none";
    //         }

    //     }
    //     if (this.count == 0)
    //         this.filterDoesNotMatch = true;
    //     else
    //         this.filterDoesNotMatch = false;
    // }
    // searchpanelsforCreateForms()
    // {
    //     if (this.ClientNameSearch) {
    //         this.ClientNameSearch = this.ClientNameSearch.trim();

    //         if (this.ClientNameSearch) {
    //             this.myclientsall = this.myclients.filter(s => s.clientName.replace(' ', '').toLowerCase().includes(this.ClientNameSearch.replace(' ', '').toLowerCase()));
    //         }
    //         else {
    //             this.myclientsall = this.myclients;                
    //         }
    //     }
    //     else {
    //         this.myclientsall = this.myclients;
    //     }
    // }
    
    filterUsers(event) {
        let filterText = event.target.value;
         
        if(filterText)
        {
            this.SearchBar = this.SearchBar.filter(s => s.formName.replace(' ', '').toLowerCase().includes(filterText.replace(' ', '').toLowerCase()));
        }
        else{
            this.SearchBar = this.SearchBarlist;
            this.SearchBar.sort((a, b) => {
                if (a.formName < b.formName) return -1;
                else if (a.formName > b.formName) return 1;
                else return 0;
            });
        }
        
    }
    close() {
        this.displayCreatePane = false;
    }
    
}
export class SearchBarForms {
    formName: string;
    formPermission: boolean
    formIcon: string
    formPermissionCreate: boolean

}
