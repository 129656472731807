import { Component, OnInit, ViewChild, Output, EventEmitter, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GetAnnouncementForViewDto, AnnouncementDto } from '@shared/service-proxies/service-proxies';

import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    moduleId: module.id,
  selector: 'app-view-acknowledgement',
  templateUrl: './view-acknowledgement.component.html'   
 
})
export class ViewAcknowledgementComponent   {
    @ViewChild('ackmodal', { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  
    active = false;
    title: string = "View Acknowledgement";
    header: string = "View Modal";
    item: GetAnnouncementForViewDto;

    constructor(injector: Injector) {
       //super(injector);
        this.item = new GetAnnouncementForViewDto();
        this.item.announcement = new AnnouncementDto();
    }
    show(formname: string) {
        
        if (formname) {
            this.active = true;
            this.modal.show();
        }
    }
    close() {
        this.active = false;
        this.modal.hide();
    }

    //one sec
}
