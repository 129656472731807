import { Component, Injector, ViewEncapsulation, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as _ from 'lodash';
import * as moment from 'moment';
import { filter, finalize, map, toArray } from 'rxjs/operators';
import { Observable, range } from 'rxjs';
import { Location as backLocation } from '@angular/common';
import { id } from '@swimlane/ngx-charts';
import { ProvidersMastersServiceProxy, CreateOrEditProvidersMasterDto ,ProvidersMasterProvider_Role_LookUpLookupTableDto
					,ProvidersMasterProvider_Title_LookUpLookupTableDto
    , NameValueDto, CommonServiceProxy, ClientAppointmentsServiceProxy
					} from '@shared/service-proxies/service-proxies';
import { NgForm } from '@angular/forms';

@Component({
    templateUrl: './practitioner-add-update.component.html',
    selector:'practitioner-add-update',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class PractitionerAddUpdateComponent extends AppComponentBase {
    @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() onNewProviderGroupSelected: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("myForm") myForm: NgForm;
    providersMaster: CreateOrEditProvidersMasterDto = new CreateOrEditProvidersMasterDto();
    provider_Role_LookUpName = '';
    provider_Title_LookUpName = '';
    bsValue = new Date();
    active = false;
    saving = false;
    private sub: any;
    practitionerId: number;
        
    practiceGroupList: any;
    isLoading = false;
    
    allProvider_Role_LookUps: ProvidersMasterProvider_Role_LookUpLookupTableDto[];
    allProvider_Title_LookUps: ProvidersMasterProvider_Title_LookUpLookupTableDto[];

    CreatedDate: Date;
    curDate = new Date();
    create = false;
    Pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    public Phonemask: Array<string | RegExp>
    public mask: Array<string | RegExp>
    public faxmask: Array<string | RegExp>	
    practiceGroupName: string = "";

    constructor(
             injector: Injector, private _router: Router,
             private _providersMastersServiceProxy: ProvidersMastersServiceProxy , private route: ActivatedRoute,private _CommonService: CommonServiceProxy,  private cd: ChangeDetectorRef,
                private location: backLocation,
                private appProxy: ClientAppointmentsServiceProxy
             ) {
                   super(injector);
                   (moment as any).fn.toString = function () { return this.format("L"); };
                    this.sub = this.route.params.subscribe(params => {
                        this.practitionerId = +params['medId'];
                   });

            this.Phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
            this.faxmask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }

    ngOnInit(): void {
        this.show(this.practitionerId);
    }

    goToListPage(): void {
        this._router.navigate(['app/masters/provider/list']);
    }

    addPracticeGroup(): void {
        this._router.navigate(['/app/masters/provider/group/add/0']);
    }

    show(providersMasterId?: number) {

        this._providersMastersServiceProxy.getAllProvider_Role_LookUpForTableDropdown().subscribe(result => {
            this.allProvider_Role_LookUps = result;

        });
        this._providersMastersServiceProxy.getAllProvider_Title_LookUpForTableDropdown().subscribe(result => {
            this.allProvider_Title_LookUps = result;

        });
        if (!providersMasterId) {
            this.providersMaster = new CreateOrEditProvidersMasterDto();
            this.providersMaster.id = providersMasterId;      
            this.active = true;
            this.create = true;
        } else {
            this._providersMastersServiceProxy.getProvidersMasterForEdit(providersMasterId).subscribe(result => {

                debugger;
                this.providersMaster = result.providersMaster;
                this.practiceGroupName = result.practiceGroupName;
                this.provider_Role_LookUpName = result.provider_Role_LookUpName;
                this.provider_Title_LookUpName = result.provider_Title_LookUpName;
                this.active = true;
                this.create = false;
        
            });
        }			
    }

    save(closeDialog: boolean): void {
        this._providersMastersServiceProxy.createOrEdit(this.providersMaster)
            .pipe(finalize(() => { }))
            .subscribe(result => {
                this.providersMaster.id = result ;
                this.notify.info(this.l('Practitioner Saved Successfully'));
                if (closeDialog) {
                    this.buttonClicked.emit(this.providersMaster)
                }
                this.myForm.reset();
            });
    }

    close(showCofirmDialog): void {
        this.setcanDeactivate(true);
        if (showCofirmDialog) {
            this.message.confirm("Close Dialog", 'Are you sure you want to exit the form?',
                (isConfirmed) => {
                    if (isConfirmed) {
                        this.buttonClicked.emit(this.providersMaster);
                        this.myForm.reset();
                    }
                })
        } else {
            this.buttonClicked.emit(this.providersMaster)
            this.myForm.reset();
        }
    }
    saveOrClose(isSaved): void {
        if (isSaved) {
            this.save(false);
        }
        else {
            this.close(true);
        }
    }

    saveAndClose(): void {
        this.save(true);
    }

    searchPracticeGroup(event) {
        const wordSearch = event.target.value.trim();
        setTimeout(() => {
            if (wordSearch == event.target.value.trim()) {
                if (event.target.value.trim()) {
                    this.appProxy.getAllProvidersForLookup(wordSearch, true)
                        .pipe(finalize(() => { this.isLoading = false; }))
                        .subscribe(result => {
                            this.practiceGroupList = result;
                            if (result.length == 0) {
                                if (this.permission.isGranted('Pages.ProvidersMasters') || this.permission.isGranted('Pages.PracticeGroupMasters')) {
                                    this.practiceGroupList = [{ name: "Add New Practice Group", id: 0 }];
                                } else {
                                    this.practiceGroupList = [];
                                }
                            }
                        });
                } else {

                    this.practiceGroupList = [];
                }
            }
        }, 1000);
    }
    onUserSelectloc(event) {
        if (event.id == 0) {
            this.practiceGroupList = [];
            this.practiceGroupName = "";
            this.onNewProviderGroupSelected.emit();
        } else {
            this.updateProviderMasterInfo(event);
        }
    }

    updateProviderMasterInfo(event) {
        debugger;
        this.providersMaster.practiceGroupId = event.id;
        if (event.address)
            this.providersMaster.address = event.address;
        if (event.address)
            this.providersMaster.city = event.city;
        if (event.zipCode)
            this.providersMaster.zipCode = event.zipCode
        if (event.state)
            this.providersMaster.state = event.state;
        if (event.phone)
            this.providersMaster.phone = event.phone;
        if (event.fax)
            this.providersMaster.fax = event.fax;
        if (event.email)
            this.providersMaster.email = event.email;
        if (event.practiceGroupName)
            this.practiceGroupName = event.practiceGroupName;
        if (event.name)
            this.practiceGroupName = event.name;
    }
}
