<div bsModal #ackmodal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="ackmodal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>Header</span>
                </h4>
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-12 mb-3">
                        <h6>Modal popup</h6>
                        
                    </div>
                   

                </div>

            </div>
            <div class="modal-footer">
                <button  type="button" class="btn btn-primary blue" (click)="close()">{{("Close")}}</button>
            </div>
        </div>
    </div>

</div>
