import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { CreateOrEditOmmisionDto, CreateOrEditProgramEnrollmentDto, EMarsServiceProxy, GetEMarForViewDto, MedicationAdminScheduleDto, MedicationStatusEnum, OmmisionsServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { forkJoin } from '@node_modules/rxjs';


@Component({
    selector: 'add-ommission-dialog',
    templateUrl: './add-ommission-dialog.component.html',
    styleUrls: ['add-ommission-dialog.component.css']
})
export class AddOmissionDialogComponent extends AppComponentBase {

    @ViewChild('addOmissionModal', { static: true }) modal: ModalDirective;
    @ViewChild('addOmissionForm', { static: true }) form: NgForm;
    @Output() saveOmission: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    date: Date;
    medicationScheduleId
    patientsName: string;
    IsLateAdministered: boolean;
    _required = false;
    medicationName: string;
    ommision = new CreateOrEditOmmisionDto();
    ommionHistory
    clientMedicationId;
    patientId;
    houseNurseName: CreateOrEditProgramEnrollmentDto;
    patientEMr = new GetEMarForViewDto();
    status: MedicationStatusEnum;
    medicationStatusEnum = MedicationStatusEnum;
    medicationschedule: MedicationAdminScheduleDto = new MedicationAdminScheduleDto();

    clientData: any[] = [];
    selectclientName: string;
    constructor(injector: Injector,
        private _ommisionsServiceProxy: OmmisionsServiceProxy, private _EMarsServiceProxy: EMarsServiceProxy, private cd: ChangeDetectorRef) {
        super(injector);
    }

    counter(i: number): any {
        return new Array(i);
    }

    show(emar: MedicationAdminScheduleDto): void {
        if (emar) {
            this.ommision = new CreateOrEditOmmisionDto();
            this.medicationschedule = emar;
            this.status = emar.status;
            if (this.status == MedicationStatusEnum.Hold) {
                this.status = MedicationStatusEnum.Omitted;
            }
            this.patientsName = emar.clientname;
            this.patientId = emar.clientId;
            this.clientMedicationId = emar.medicationId;
            this.medicationScheduleId = emar.scheduleId;
            this.medicationName = emar.drugDescription;
            this.modal.show();
            this.onShown();
        }
    }

    onShown(): void {
        var sDate = new Date;
        forkJoin({
            ommisionEdit: this._ommisionsServiceProxy.getOmmisionForEdit(this.ommision.medicationAdminScheduleId, this.medicationScheduleId, moment(sDate)),
            ommisionHistory: this._ommisionsServiceProxy.getOmmisionHistory(this.medicationschedule.medicationId, this.patientId)
        }).subscribe(results => {
            if (results.ommisionEdit?.ommision) {
                this.ommision = results.ommisionEdit.ommision;
                this.selectclientName = this.ommision.nurseName;
            }
            this.ommionHistory = results.ommisionHistory;
        })
    }

    nurseNotifiedChecked(event: any) {
        if (event.currentTarget.checked) {
            this.selectclientName = this.houseNurseName.houseNurse;
            this.ommision.nurseNotifiedId = this.houseNurseName.houseNurseId.toString();
        }
        else {
            this.selectclientName = null;
            this.ommision.nurseNotifiedId = null;

        }

    }
    save() {
        if (this.isformValid()) {
            this._required = true;
            if (this.ommision.comment == null) {
                return false;
            }
            else {
                this.saving = true;
                this.ommision.medicationScheduleTimeId = this.medicationschedule.scheduleTimeId;
                this.ommision.scheduleDate = moment(this.date);
                this.ommision.patientName = this.medicationschedule.clientname;
                this.ommision.drugDescription = this.medicationschedule.drugDescription;
                this.ommision.patientId = this.medicationschedule.clientId;
                let CurrentDate = new Date();
                this.ommision.omittedOn = CurrentDate.toString();
                this.ommision.eMarId = null;
                this.ommision.clientMedicationId = this.medicationschedule.medicationId;
                this.ommision.medicationAdminScheduleId = this.medicationschedule.medicationAdminScheduleId;
                this._ommisionsServiceProxy.createOrEdit(this.ommision).pipe(finalize(() => { this.saving = false; }))
                    .subscribe(result => {
                        this.saveOmission.emit(this.ommision.comment);
                        this.close();
                        this.notify.info(this.l('SavedSuccessfully'));

                    });
            }
        }


    }
    isformValid(): boolean {
        if (this.ommision.nurseNotified && !this.ommision.nurseNotifiedId) {
            this.message.error("Please select a nurse.");
            this._required = true;
            return false;
        }
        if (!this.ommision.omissionReason) {
            this.message.error("Please select Omission Reason.");
            this._required = true;
            return false;
        }

        this._required = false;
        return true;
    }
    searchClient(event: any) {

        var trimmed = this.selectclientName.trim();
        if (trimmed.length == 0) {
            this.clientData = null;
            this.clientData = [];
            this.cd.detectChanges();
        }
        let wordSearch = this.selectclientName.trim();
        setTimeout(() => {
            if (wordSearch == this.selectclientName.trim()) {
                if (this.selectclientName.trim()) {
                    this._EMarsServiceProxy.getassociatedHouseNurse(wordSearch, this.patientId)
                        .pipe(finalize(() => { }))
                        .subscribe(data => {
                            this.clientData = data;
                            if (data.length == 0)
                                this.selectclientName = null;
                            this.cd.detectChanges();
                        })
                } else {
                    this.selectclientName = null;
                }
            }
        }, 2000);
    }
    onNurseNameChange(value: string) {
        if (!value) {
            this.selectclientName = null; // Set to null when cleared
            this.ommision.nurseNotifiedId = null; // Set to null when cleared
        }
    }
    onSelect(event) {
        //  this.PatientId = patientId;
        // this.addmedicationheader.recordid = this.PatientId;
        let data = event
        this.selectclientName = data.userName;
        this.ommision.nurseNotifiedId = data.userId;
    }
    close(): void {
        this.active = false;
        this.selectclientName = null;
        this.modal.hide();
    }
}
