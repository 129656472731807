<div bsModal #holdMedModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="holdMedModal" aria-hidden="true"
     [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #holdMedForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header border-0 d-flex">
                    <div>
                        <h4 class="modal-title text-uppercase  text-primary font-weight-bolder">
                            {{drugName}}
                        </h4>

                        <h4 class="modal-title text-uppercase font-weight-bolder">
                            {{patientName}} 
                        </h4>
                    </div>
                    <button type="button" (click)="close()" class="close mt-n10" [disabled]="saving" title="Close">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body mt-n7">
                    <tabset>
                        <tab>
                            <ng-template tabHeading>
                                <span><i class="fas fa-pause pr-2"></i>Hold Med</span> 
                            </ng-template>
                            
                                <div class="card-body pt-5 p-0">
                                    <div class="row col-lg-12">
                                        <div *ngIf="false" class="form-group col-md-12 col-sm-6">
                                            <label>Hold Reason</label>
                                            <div class="radio-inline">
                                                <label class="btn btn-outline-secondary radio radio-outline radio-info ml-1">
                                                    <input type="radio" name="radios15" id="radios1" value="1" [(ngModel)]="holdMedication.holdReason">
                                                    <span></span>Abnormal Readings
                                                </label>
                                                <label class="btn btn-outline-secondary radio radio-outline radio-primary">
                                                    <input type="radio" name="radios15" id="radios2" [(ngModel)]="holdMedication.holdReason" value="2">
                                                    <span></span>Waiting for Procedure
                                                </label>
                                                <label class="btn btn-outline-secondary radio radio-outline radio-primary">
                                                    <input type="radio" name="radios15" id="radios3" [(ngModel)]="holdMedication.holdReason" value="3">
                                                    <span></span>Physician's order
                                                </label>
                                                <label class="btn btn-outline-secondary radio radio-outline radio-warning">
                                                    <input type="radio" name="radios15" id="radios4" [(ngModel)]="holdMedication.holdReason" value="4">
                                                    <span></span>Other
                                                </label>
                                            </div>
                                        </div>
                                        <div  *ngIf="false" class="form-group col-md-8 col-sm-8">
                                            <label>Hold From</label>
                                            <input type="date" name="holdForm" id="holdForm" [(ngModel)]="holdMedication.holdFrom"  class="form-control" />
                                            <span class="text-danger"
                                              *ngIf="(_required==true) && (holdMedication.holdFrom==null ||holdMedication.holdFrom==undefined)">Required</span>
                                        </div>
                                        <div class="form-group col-md-12 col-sm-12">
                                            <table class="table mat-table-sticky table-hover table-users">
                                                <thead class="thcolorCss">
                                                    <tr class="m-datatable__row tableheaderColorCss">
                                                        <th data-field="phone"
                                                            class="m-datatable__cell m-datatable__cell--sort ">
                                                            <span>Schedule</span>
                                                        </th>

                                                        <!-- <th data-field="ratio" 
                                                            class="m-datatable__cell m-datatable__cell--sort widthratio">
                                                            <span> Hold From</span>
                                                        </th>

                                                        <th class="m-datatable__cell m-datatable__cell--sort">
                                                           Resume From</th> -->

                                                            <th class="m-datatable__cell m-datatable__cell--sort w-25">
                                                            Action</th>
                                                            

                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr  *ngFor="let hc of medSchTime;let i = index">
                                                        <td class="bit2-boldLabel"><span> Dose at {{hc.scheduleTime}}</span>
                                                        </td>
                                                        <!-- <td class="bit2-boldLabel"><span>{{hc.holdScheduleTime.holdFrom | date:"MM/dd/yyyy"}}</span>
                                                           

                                                        </td>
                                                        <td class="outcometextareaCss"><span>{{hc.holdScheduleTime.resumeFrom | date:"MM/dd/yyyy"}}</span>
                                                            
                                                        </td> -->
                                                        <td class="pl-0" >
                                                            <button  type="button" (click)="AddHold(hc,hc.holdScheduleTime)"  class="btn btn-light-primary font-weight-bold ">Add New Hold</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                          
                                        </div>
                                       
                                       
                                        <div  *ngIf="false" class="col-12"></div>
                                        <div  *ngIf="false" class="form-group col-md-8 col-sm-8">
                                            <label>Resume From</label>
                                            <input type="date" name="resumeForm" id="resumeForm" [(ngModel)]="holdMedication.resumeForm" class="form-control" />
                                            <span class="text-danger"
                                                *ngIf="(_required==true) && (holdMedication.resumeForm==null ||holdMedication.resumeForm==undefined)">Required</span>
                                        </div>
                                     
                                        <div  *ngIf="false" class="col-12"></div>
                                  
                                   
                                        <div  *ngIf="false"  class="form-group col-md-6 col-sm-6">
                                            <label>Nurse Notified?</label>
                                            <div class="flex-nowrap input-group">
                                                <span class="input-group-hover input-group-text">
                                                    <label class="checkbox checkbox-danger checkbox-outline">
                                                        <input type="checkbox" name="Checkboxes12" id="Checkboxes12"  [(ngModel)]="holdMedication.nurseNotified">
                                                        <span></span>Yes
                                                    </label>
                                                </span>
                                                <div class="ml-1">
                                                    <input type="text" class="form-control" name="nursNotified" id="nursNotified"  [(ngModel)]="holdMedication.nurseNotifiedId" placeholder="Enter Nurse Name">
                                                </div>
                                            </div>
                                        </div>


                                        <div  *ngIf="false"  class="form-group col-md-6 col-sm-6">
                                            <label>Care Team Notified?</label>
                                            <div class="flex-nowrap input-group">
                                                <span class="input-group-hover input-group-text">
                                                    <label class="checkbox checkbox-danger checkbox-outline">
                                                        <input type="checkbox" name="Checkboxes12" id="checkboxes12"  [(ngModel)]="holdMedication.careTeamNotified">
                                                        <span></span>Yes
                                                    </label>
                                                </span>
                                                <div class="ml-1">
                                                    <input type="text" class="form-control"  name="CareTeamNotified" id="careTeamNotified"  [(ngModel)]="holdMedication.careTeamId" placeholder="Enter Group Name">
                                                </div>
                                            </div>
                                        </div>
                                   
                                       
                                        <div  *ngIf="false" class="form-group col-sm-12">
                                            <label>Comments (required)</label>
                                            <textarea class="form-control" id="CommentsTextarea" name="commentsTextarea" [(ngModel)]="holdMedication.comment" rows="2" placeholder="Enter your comments here"></textarea>
                                            <span class="text-danger"
                                                *ngIf="(_required==true) && (holdMedication.comment==null ||holdMedication.comment==undefined)">Required</span>
                                        </div>
                                    </div>
                                </div>
                           
                            <div class="row card card-custom pb-0">
                                <div class="card-body pt-3">
                                    <h4>Hold History</h4>
                                    <div class="row" >
                                        <div class="form-group col-12">
                                            <table class="table table-hover cursor-pointer">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Schedule</th>
                                                        <th scope="col">Hold Date/Resume Date</th>
                                                        <th scope="col">Hold Reason</th>
                                                        <th scope="col">Action</th>
                                                        
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of holdSchHistory;let i=index;" >
                                                        <th scope="row" >{{item.scheduleTime}}</th>
                                                        <th >{{item.holdScheduleTime.holdFrom | date:"MM/dd/yyyy"}} - {{item.holdScheduleTime.resumeFrom | date:"MM/dd/yyyy"}}</th>
                                                        <th>{{item.holdScheduleTime.holdReasonString}}</th>
                                                        <td>
                                                             <button  *ngIf="item.holdScheduleTime.status=='Resume'"  type="button" (click)="View(item,item.holdScheduleTime)"  class="btn btn-light-primary font-weight-bold">View</button>
                                                            <button *ngIf="item.holdScheduleTime.status=='Hold'" (click)="editHoldSchdl(item,item.holdScheduleTime)" type="button" class="btn btn-light-warning font-weight-bold">Edit
                                                            </button> 
                                                            <button type="button"  *ngIf="item.holdScheduleTime.isResume==true"  (click)="holdSchdlDialog(item,item.holdScheduleTime)" class="btn btn-light-warning font-weight-bold">Resume</button> 
                                                           
                                                        </td>
                                                    </tr>
                                                   
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                       
                    </tabset>
                </div>
                <holdScheduleModalDialog #holdScheduleModalDialog (saveHoldSchedulMed)="getHoldSchedulMed($event)" ></holdScheduleModalDialog>
                <div class="modal-footer">
                    <div class="row d-flex flex-grow-1">
                        <div class="flex-column flex-grow-1 text-right">
                            <button [disabled]="saving" type="button"
                                    class="btn btn-light-danger font-weight-bold mr-2" (click)="close()">
                                {{"Close" | localize }}
                            </button>
                            <!-- <button  *ngIf="status!='Hold'" type="button" (click)="save()"  class="btn btn-light-primary font-weight-bold"  >Save</button> -->
                           
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


