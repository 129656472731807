import { AfterViewChecked, Component, ElementRef, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { outputs } from '@syncfusion/ej2-angular-navigations/src/sidebar/sidebar.component';
import { CreateOrEditMedicatioNoteDto, EMarHistoriesServiceProxy, GetEMarForViewDto, MedicatioNotesServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';

@Component({
    selector: 'administer-Med-dialog',
    templateUrl: './administerMedDialog.component.html',
    styleUrls: ['administerMedDialog.component.css']
})
export class AdministerMedDialogComponent extends AppComponentBase {

    @ViewChild('administerMeddialog', { static: true }) modal: ModalDirective;
    @ViewChild('administerMeddialogForm', { static: true }) form: NgForm;
    @Output() saveAdminister: EventEmitter <any> =new EventEmitter<any> ();
    active = false;
    saving = false;
    IsLateAdministered=true;
    patientEMr = new GetEMarForViewDto();
    patientName:string;
    status:string;

    drugName:string;
    comment:string;
    _required=false;
    hide=false;
    constructor(injector: Injector,
        private _eMarHistoriesServiceProxy: EMarHistoriesServiceProxy) {
        super(injector);        
    }

    counter(i: number): any {
        return new Array(i);
    }
    ngOnInit()
    {
        this.comment="";

    }
    show(emar): void {
        this.patientEMr = new GetEMarForViewDto();
       // this.patientEMr.clientMedication.patientsname = patientName;
        var data = emar
        this.patientEMr  = emar;
        this.status = this.patientEMr.clientMedicationScheduleTime.medicationDoseStatus;
        if(this.status !='Administered'){
            this.hide=true;
        }
        else{
            this.hide=false;
        }
        this.IsLateAdministered = this.patientEMr.emrHistory.isLateAdministerHist;   
      //  this.comment = this.patientEMr.emrHistory.comments;    
        this.patientName = this.patientEMr.clientMedication.patientsname; 
        this.drugName= this.patientEMr.clientMedication.drugDescription;
        this.modal.show();
    }

    onShown(): void {
        var sDate = new Date; 
        this.comment="";
        this._eMarHistoriesServiceProxy.getEMarHistoryComment(this.patientEMr.clientMedicationScheduleTime.emarId,this.patientEMr.clientMedicationScheduleTime.id,moment(sDate))
            .subscribe(result => {
                if (result) {
                    this.comment = result.comments;
                   // this.selectclientName = this.ommision.nurseName;
                    // this.ommision.nurseName = this.ommision.nurseFName[0].toUpperCase() + this.ommision.nurseFName.Substring(1) + this.ommision.nurseLName[0].toUpperCase() + this.ommision.nurseLName.Substring(1);
                     
                }
            });
    }

    save(){
        this._required = true;
        if((this.comment == null || this.comment =='' || this.comment == undefined) && this.IsLateAdministered)
        {
            return false;
        }
        else
        {
            this.saveAdminister.emit(this.comment)
            this.modal.hide();
        }
     
    }

    close(): void {
        this.active = false;
        this.comment="";
        this.modal.hide();
    }

   
}
