<p-sidebar [(visible)]="displayClientsPane" [dismissible]="true" [showCloseIcon]="true" position="right" [style]="{width:'400px'}" > 

    <div class="{{loadclinet==true ? 'overlay overlay-block' : ''}}" style="overflow: scroll; height: inherit; width: 100%;" >

        <div class="bit2-sidebar-header clients text-justify mb-5">
            <i class="fas fa-user-tie iocn-color-myclients mr-2"></i><span>My Clients</span>
        </div>

         <div class="input-group col-md-12 bit2-create-searchbox">
            <input placeholder="Search Clients" class="form-control py-2 border-right-0 border" id="clientname" [(ngModel)]="ClientNameSearch" (input)="searchclients()" type="search" />
            <span class="input-group-append">
                <button class="btn btn-outline-secondary border-left-0 border" type="button" style="padding-top: 0Px;">
                    <i class="fa fa-search"></i>
                </button>
            </span>
        </div>

        <div class="client-pane {{loadclinet==true ? 'overlay-wrapper' : ''}}">
            <div class="overlay-layer bg-dark-o-10" *ngIf="loadclinet">
                <div class="spinner spinner-primary"></div>
            </div>
            <div class="client-main mb-2" *ngFor="let stat of myclientsall; let index = index" [ngClass]="stat.isOpen==true ?'client-pane-open':'client-pane-closed'">
                <!-- client picture and details start here-->
                <div class="client-info d-flex bg-dark-o-15 p-3 mb-n2" (click)="clienPanAction(stat)">
                    <div class="flex-column pr-3" for="pic" (click)="redirectclientdashboard(stat.clientId)">
                        <div class="symbol symbol-65 symbol-circle">
                            <img  class="w-65px" src="{{stat.clientPicUrl}}" alt="{{stat.clientName}}">
                        </div>
                    </div>
                    <div class="flex-column flex-grow-1 text-nowrap" for="info">
                        <div class="flex-column">
                            <span class="d-flex font-weight-bold font-size-h5 text-dark ">{{stat.clientName | titlecase }} <i title="{{stat.clientPfStatus}}"  [ngClass]="[stat.clientPfStatus=='Active'?'flaticon2-correct text-success icon-md ml-2':'fas fa-times-circle text-danger icon-md ml-2']">
                               
                            </i></span>
                            <span class="d-flex text-dark-50 font-size-sm">{{ stat.dob |  date: 'MM/dd/yyyy'}} {{stat.gender}} {{calculateage(stat.dob)}}</span>
                            <span class="d-flex text-dark-50 font-size-sm">{{stat.sitename==null?'NA':stat.sitename}}{{stat.programName==null?'-NA':'-'+stat.programName}}</span>
                        </div>
                    </div>
                    <div class="p-0 flex-column text-right mr-2" for="caret icon">
                        <i *ngIf="stat.isOpen==false" class=" w-100 h-100 fas fa-angle-down fa-angle-down fas font-size-h2-sm text-primary "  ></i>
                        <i *ngIf="stat.isOpen" class="w-100 h-100 fas fa-angle-down fa-angle-up fas font-size-h2-sm text-primary" ></i>
                    </div>
                </div>
                 <!-- client picture and details ends here--> 
                 <!-- buttons for action starts here-->
                <div class="client-actions d-flex flex-wrap p-4" *ngIf="stat.isOpen">
                    <div class="button-section flex-column p-3 w-50">
                        <div class="d-flex justify-content-center">Actions</div>
                        <div class="action-buttons btn btn-custom d-flex justify-content-between">
                            <div title="Edit"  (click)="redirectonmyform(stat.editUrl)">
                                <i class="far fa-edit cursor-pointer"></i>
                            </div>
                            <div title="Go to Dashboard"  (click)="redirectclientdashboard(stat.clientId)"><i class="fas fa-border-all text-primary cursor-pointer"></i></div>
                            <div title="Print"  (click)="redirectonmyform(stat.printUrl)"><i class="far fa-file-pdf text-danger cursor-pointer"></i></div>
                        </div>
                    </div>
                    <div class="button-section flex-column p-3 w-50">
                        <div class="d-flex justify-content-center">Incidents</div>
                        <div class="action-buttons btn btn-custom d-flex justify-content-between">
                            <div title="{{stat.incidents.itemCount > 0 ? 'Recent Incident' : 'Not available'}}"  (click)="redirectonmyform(stat.incidents.recentUrl)"><i class="far fa-clock text-primary cursor-pointer"></i></div>
                            <div title="All Incidents"  class="symbol symbol-circle recent-item cursor-pointer" (click)="redirectonmylistform(stat.incidents.listingUrl,stat.clientName)">
                                {{stat.incidents.itemCount  == null ? 0 : stat.incidents.itemCount}}
                            </div>
                            <div title="New Incident"  (click)="redirectonmyform(stat.incidents.newUrl)">
                                <i class="fas fa-plus text-danger cursor-pointer"></i>
                            </div>
                        </div>
                    </div>
                   

                    <div class="button-section flex-column p-3 w-50">
                        <div class="d-flex justify-content-center">Care Plans</div>
                        <div class="action-buttons btn btn-custom d-flex justify-content-between">
                            <div title="{{stat.carePlans.itemCount > 0 ? 'Recent Care Plan': 'Not available'}}"  (click)="redirectonmyform(stat.carePlans.recentUrl)"><i class="far fa-clock text-primary cursor-pointer"></i></div>
                            <div title="All Care Plan"  class="symbol symbol-circle recent-item cursor-pointer" (click)="redirectonmylistform(stat.carePlans.listingUrl,stat.clientName)">
                                {{stat.carePlans.itemCount  == null ? 0 : stat.carePlans.itemCount}}
                            </div>
                            <div title="New Care Plans"  (click)="redirectonmyform(stat.carePlans.newUrl)">
                                <i class="fas fa-plus text-danger cursor-pointer"></i>
                            </div>
                        </div>
                    </div>
                    <div class="button-section flex-column p-3 w-50">
                        <div class="d-flex justify-content-center">Assessments</div>
                        <div class="action-buttons btn btn-custom d-flex justify-content-between">
                            <div title="{{stat.assessments.itemCount > 0 ? 'Recent Assessment': 'Not available'}}"  (click)="redirectonmyform(stat.assessments.recentUrl)"><i class="far fa-clock text-primary cursor-pointer"></i></div>
                            <div title="All Assessments"  class="symbol symbol-circle recent-item cursor-pointer" (click)="redirectonmylistform(stat.assessments.listingUrl,stat.clientName)">
                                {{stat.assessments.itemCount  == null ? 0 : stat.assessments.itemCount}}
                            </div>
                            <div title="New Assessment"  (click)="redirectonmyform(stat.assessments.newUrl)">
                                <i class="fas fa-plus text-danger cursor-pointer"></i>
                            </div>
                        </div>
                    </div>

                    <div class="button-section flex-column p-3 w-50">
                        <div class="d-flex justify-content-center">Health Logs</div>
                        <div class="action-buttons btn btn-custom d-flex justify-content-between">
                            <div title="{{stat.healthLogs.itemCount > 0 ? 'Recent Health Log': 'Not available'}}"  (click)="redirectonmyform(stat.healthLogs.recentUrl)"><i class="far fa-clock text-primary cursor-pointer"></i></div>
                            <div title="All Health Logs"  class="symbol symbol-circle recent-item cursor-pointer" (click)="redirectonmylistform(stat.healthLogs.listingUrl,stat.clientName)">
                                {{stat.healthLogs.itemCount  == null ? 0 : stat.healthLogs.itemCount}}
                            </div>
                            <div title="New Health Log"  (click)="redirectonmyform(stat.healthLogs.newUrl)">
                                <i class="fas fa-plus text-danger cursor-pointer"></i>
                            </div>
                        </div>
                    </div>

                    <div class="button-section flex-column p-3 w-50">
                        <div class="d-flex justify-content-center">Nursing Notes</div>
                        <div class="action-buttons btn btn-custom d-flex justify-content-between">
                            <div title="{{stat.nursingNotes.itemCount > 0 ? 'Recent Nursing Note': 'Not available'}}"  (click)="redirectonmyform(stat.nursingNotes.recentUrl)"><i class="far fa-clock text-primary cursor-pointer"></i></div>
                            <div title="All Nursing Notes"  class="symbol symbol-circle recent-item cursor-pointer"  (click)="redirectonmylistform(stat.nursingNotes.listingUrl,stat.clientName)">
                                {{stat.nursingNotes.itemCount  == null ? 0 : stat.nursingNotes.itemCount}}
                            </div>
                            <div title="New Nursing Note"  (click)="redirectonmyform(stat.nursingNotes.newUrl)">
                                <i class="fas fa-plus text-danger cursor-pointer"></i>
                            </div>
                        </div>
                    </div>

                    <div class="button-section flex-column p-3 w-50">
                        <div class="d-flex justify-content-center">Charts</div>
                        <div class="action-buttons btn btn-custom d-flex justify-content-between">
                            <div title="Recent Chart"  (click)="redirectonmyform(stat.charts.recentUrl)"><i class="far fa-clock text-primary cursor-pointer"></i></div>
                            <div title="All Charts"  class="symbol symbol-circle recent-item cursor-pointer"  (click)="redirectonmylistform(stat.charts.listingUrl,stat.clientName)">
                                {{stat.charts.itemCount  == null ? 0 : stat.charts.itemCount}}
                            </div>
                            <div title="Upload Chart"  (click)="redirectonmyform(stat.charts.newUrl)">
                                <i class="fas fa-plus text-danger cursor-pointer"></i>
                            </div>
                        </div>
                    </div>

                    <div class="button-section flex-column p-3 w-50">
                        <div class="d-flex justify-content-center">Seizures</div>
                        <div class="action-buttons btn btn-custom d-flex justify-content-between">
                            <div title="{{stat.seizures.itemCount > 0 ? 'Recent Seizures': 'Not available'}}"  (click)="redirectonmyform(stat.seizures.recentUrl)"><i class="far fa-clock text-primary cursor-pointer"></i></div>
                            <div title="All Seizures"  class="symbol symbol-circle recent-item cursor-pointer" (click)="redirectonmylistform(stat.seizures.listingUrl,stat.clientName)">
                                {{stat.seizures.itemCount == null ? 0 : stat.seizures.itemCount }}
                            </div>
                            <div title="New Seizure"  (click)="redirectonmyform(stat.seizures.newUrl)">
                                <i class="fas fa-plus text-danger cursor-pointer"></i>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- buttons for action ends here-->

            </div>
        </div>
    </div>

</p-sidebar>
