<div bsModal #discontinuedMedd="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="discontinuedMedd" aria-hidden="true"
    [config]="{appendToBody:true, backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #medScheduleForm="ngForm" novalidate (ngSubmit)="save('save')">
                <div class="modal-header">
                    <div>
                        <h4 class="modal-title text-primary font-weight-bolder text-uppercase">
                            {{drugName}}
                        </h4>
                        <!-- ({{genderName}}/{{calculateage(dob)}}) -->
                        <h4 class="modal-title  text-uppercase">
                            {{patientName}}
                        </h4>
                    </div>
                    <button type="button" (click)="close()" class="close" [disabled]="saving" title="Close">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <fieldset [disabled]="status=='Discontinued'" class="the-fieldset">
                        <div class="row">

                            <div class="form-group col-md-6">
                                <label>Reason For Discontinue</label>
                                <select class="form-control" placeholder="Select Reason For Discontinue"
                                    id="reasonDiscontinue" name="reasonDiscontinue"
                                    [(ngModel)]="discontinueMedication.discontinueReason">
                                    <option value="0">--select--</option>
                                    <option value="1">Medication risk</option>
                                    <option value="2">Lack of symptoms</option>
                                    <option value="3">Depression</option>
                                    <option value="4">No longer needed</option>
                                    <option value="5">Prescribing error</option>
                                    <option value="6">Ineffective treatment</option>
                                    <option value="7">Adverse drug event</option>
                                    <option value="8">Improve adherence</option>
                                    <option value="9">Other</option>
                                </select>
                                <span class="text-danger"
                                    *ngIf="(_required==true) && (discontinueMedication.discontinueReason==null ||discontinueMedication.discontinueReason==undefined )">Required</span>
                            </div>
                            <!-- <div class="form-group col-md-12">
                            <label for="Discontinue_date">Discontinue Date</label>
                            <ejs-datetimepicker id="DiscontinueDate" #ejDateTime
                                [(ngModel)]="discontinueMedication.discontinueDate" name="DiscontinueDate"
                                placeholder="MM/DD/YYYY:hh:mm">
                            </ejs-datetimepicker>
                           </div> -->
                            <div class="form-group col-md-6 col-sm-4 col-xs-6">
                                <label>Discontinue Date</label>
                                <input type="date" class="form-control" placeholder="Discontinue Date"
                                    [(ngModel)]="discontinueMedication.discontinueDate" id="Discontinue_date"
                                    name="Discontinue_date">
                                <span class="text-danger"
                                    *ngIf="(_required==true) && (discontinueMedication.discontinueDate==null ||discontinueMedication.discontinueDate==undefined ||discontinueMedication.discontinueDate=='')">Required</span>
                            </div>

                            <!-- <div class="form-group-no-label col-md-3 col-sm-4 col-6 col-sm-4 col-6 mb-8"> -->
                            <!-- <label class="col-12">Discontinue From Schedule</label>
                            <div class="form-group col-md-3 col-sm-4 col-6 col-sm-4 col-6 mb-8" *ngFor="let hc of medSchTime;">
                                <label class="btn btn-outline-danger radio radio-outline radio-danger ml-1">
                                    <input type="radio" name="Discontinuescheduale" id="Discontinuescheduale{{hc.id}}" [value]="hc.id"  [(ngModel)]="discontinueMedication.schedualTimeId">
                                    <span></span> Dose at {{hc.scheduleTime}} {{hc.scheduleTimeAt}}
                                </label>
                             </div>
                             <div class="col-12 text-danger" *ngIf="(_required==true) && (discontinueMedication.schedualTimeId==null||discontinueMedication.schedualTimeId==undefined)">Required
                             </div> -->
                            <!-- </div> -->

                            <div class="form-group col-12">
                                <label>Describe Discontinue Reason</label>
                                <textarea class="form-control" placeholder="Enter Notes" id="describeReason"
                                    name="describeReason" [(ngModel)]="discontinueMedication.describeDiscReason">
                             </textarea>
                                <span class="text-danger"
                                    *ngIf="(_required==true) && (discontinueMedication.describeDiscReason==null ||discontinueMedication.describeDiscReason==undefined ||discontinueMedication.describeDiscReason=='')">Required</span>
                            </div>
                            <div class="tab-content col-xl-12 mt-2 pl-1">
                                <div class="form-group col-md-12">
                                    <label>Upload Prescription</label>
                                    <div class="custom-file col-xl-12">
                                        <input type="file" class="custom-file-input" multiple id="customFile"
                                            ng2FileSelect [uploader]="uploader" name="customFile"
                                            (onFileSelected)="onFileSelected($event)">
                                        <label class="custom-file-label" for="customFile">Upload Prescription</label>
                                        <span class="text-danger" *ngIf="(_required==true)">Required</span>

                                    </div>
                                </div>
                                <div *ngIf="_view" class="table-scrollable">
                                    <table class="table table-hover" id="customers">
                                        <!--[hidden]="selecteddocs.length == 0"-->
                                        <thead class="tableheaderCss">
                                            <tr class="uppercase">
                                                <th class="headingTableCss">Prescription Name</th>
                                                <th class="headingTableCss">Size</th>
                                                <th class="headingTableCss">Status</th>
                                                <th class="headingTableCss">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let doc of selecteddocs;let j = index">
                                                <td style="vertical-align:middle;"><strong
                                                        class="ng-binding">{{doc.fileName}}</strong></td>
                                                <td class="text-left" style="height:25px;vertical-align:middle;">{{
                                                    doc.size/1024/1024 | number:'.2' }} MB</td>
                                                <td class="text-left" style="height:25px">
                                                    <span><i class="fa fa-check-circle seagreenColorCss"></i></span>
                                                </td>
                                                <!--<td class="text-left" style="height:25px;vertical-align:middle;">
                                    <span class="btn btn-label-danger btn-sm">Not Approved</span>
                                </td>-->

                                                <td nowrap
                                                    style="padding-left: 0%; padding-top: 1%;vertical-align:middle;">
                                                    <i mat-icon-button color="accent" title="View Prescription"
                                                        (click)="viewdoc(doc.fileName,doc.path)" style="cursor:pointer">
                                                        <mat-icon aria-label="View"
                                                            class="deleteButtonCss">visibility</mat-icon>
                                                    </i>

                                                    <!-- <button type="button" class="fas fa-download text-hover-primary"
                                                   title="View Prescription"  (click)="viewdoc(doc.fileName,doc.path)"
                                                   ></button>
                                           
                                                <button type="button" class="fas fa-trash text-hover-danger"
                                                   title="Delete Prescription"   (click)="deleteDocument(i,doc)"
                                                   ></button>
                                            -->

                                                    <i mat-icon-button color="accent" *ngIf="status!='Discontinued'"
                                                        title="Delete Prescription" style="cursor:pointer">
                                                        <mat-icon aria-label="Delete" (click)="deleteDocument(i,doc)"
                                                            class="downloadButtonCss">delete</mat-icon>
                                                    </i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                    </fieldset>
                </div>
                <div class="modal-footer">
                    <div class="row d-flex flex-grow-1">
                        <div class="flex-column flex-grow-1 text-right">

                            <button [disabled]="saving" type="button" class="btn btn-light-danger font-weight-bold mr-2"
                                (click)="close()">{{"Close" |
                                localize}}</button>
                            <button type="button" (click)="save('save')" *ngIf="status!='Discontinued'"
                                class="btn btn-danger">Discontinue</button>


                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>