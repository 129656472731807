import { Component, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ClientDashboardDto, ClientDashboardServiceProxy } from "@shared/service-proxies/service-proxies";
import * as moment from "moment";
import { finalize } from "rxjs/operators";

@Component({
    selector: 'myclients',
    templateUrl: './my-clients.component.html'
})
export class MyClientsComponent extends AppComponentBase {

    ClientNameSearch: string;
    myclientsall: ClientDashboardDto[];
    loadingmyclient = false;
    myclients: ClientDashboardDto[];
    myclientsTop5: ClientDashboardDto[];
    loadingmyclient1 = false;
    public displayClientsPane: boolean;
    loadclinet = false;

    constructor(
        injector: Injector,
        private _router: Router,
        private _clientDashboardProxy: ClientDashboardServiceProxy,
    ) {
        super(injector);
        this.myclients = [];
        this.myclientsTop5 = [];
    }

    ngOnInit(): void {
        this.loadingmyclient = true;
        this._clientDashboardProxy.getClients()
            .pipe(finalize(() => { this.loadingmyclient = false }))
            .subscribe(result => {
             
            this.myclients = [];
                this.myclientsall = [];
                this.myclients = result;
                this.myclientsall = result;
                this.myclientsTop5 = result.slice(0, 4);
                this.loadingmyclient = false
        });

        // if (!this.clients) {
        //     this.getClients();

        // }
    }

    openSideBar()
    {
        this.displayClientsPane = true;
    }
    closeSideBar(){
        this.displayClientsPane = false;
    }

    clientimgclick(client: ClientDashboardDto) {
        let updateclient = this.myclients.find(s => s.clientId == client.clientId);
        
        this.displayClientsPane = true
        if (!client.isdetailsload) {
            updateclient.isOpen = false;

            this.closeaccordians(client.clientId)
            this.loadingmyclient1 = true;
            this._clientDashboardProxy.getClientDetails(client.clientId)
                .pipe(finalize(() => { this.loadingmyclient1 = false, this.displayClientsPane = true }))
                .subscribe(result => {
                     
                    updateclient.isdetailsload = result.isdetailsload;;
                    updateclient.isOpen = true;
                    updateclient.carePlans = result.carePlans;
                    updateclient.assessments = result.assessments;
                    updateclient.incidents = result.incidents;
                    updateclient.healthLogs = result.healthLogs;
                    updateclient.nursingNotes = result.nursingNotes;
                    updateclient.charts = result.charts;
                    updateclient.seizures = result.seizures;
                    updateclient.editUrl = result.editUrl;
                    updateclient.printUrl = result.printUrl;
                    this.arraymove(client.clientId, 0, 0);
                    this.loadingmyclient1 = false;

                });
        } else {
            updateclient.isOpen = true;
            this.arraymove(client.clientId, 0, 0);
        }
       
    }

    arraymove(clientid, fromIndex, toIndex) {
        fromIndex = this.myclients.findIndex(s => s.clientId == clientid);
        var element = this.myclients[fromIndex];
        this.myclients.splice(fromIndex, 1);
        this.myclients.splice(toIndex, 0, element);
    }

    searchclients() {
        if (this.ClientNameSearch) {
            this.ClientNameSearch = this.ClientNameSearch.trim();

            if (this.ClientNameSearch) {
                this.myclientsall = this.myclients.filter(s => s.clientName.replace(' ', '').toLowerCase().includes(this.ClientNameSearch.replace(' ', '').toLowerCase()));
            }
            else {
                this.myclientsall = this.myclients;                
            }
        }
        else {
            this.myclientsall = this.myclients;
        }
    }

    calculateage(dob) {
        if (dob) {
            var today = new Date();
            var birthDate = moment(dob).toDate();
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        } else {
            return ''
        }
    }

    redirectonmyform(url) {
         
        this._router.navigate([url]);
    }

    redirectclientdashboard(patientId) {
        this._router.navigate(['app/client/dashboard/new/', patientId]);
    }
   
    clienPanAction(client:ClientDashboardDto){
        if(client.isOpen == true){
            this.closeclientpan(client);
        }else{
            this.openclientpan(client);
        }
}
    openclientpan(client:ClientDashboardDto) {
        client.isOpen = true;
        if (client.isOpen && client.isdetailsload == false) {
            let updateclient = this.myclients.find(s => s.clientId == client.clientId);
            updateclient.isOpen = false;
            this.closeaccordians(client.clientId)
            this.loadingmyclient1 = true;
            this._clientDashboardProxy.getClientDetails(client.clientId)
                .pipe(finalize(() => { this.loadingmyclient1 = false }))
                .subscribe(result => {
                     
                    updateclient.isOpen = true;
                    updateclient.isdetailsload = result.isdetailsload;;
                    updateclient.carePlans = result.carePlans;
                    updateclient.assessments = result.assessments;
                    updateclient.incidents = result.incidents;
                    updateclient.healthLogs = result.healthLogs;
                    updateclient.nursingNotes = result.nursingNotes;
                    updateclient.charts = result.charts;
                    updateclient.seizures = result.seizures;
                    updateclient.editUrl = result.editUrl;
                    updateclient.printUrl = result.printUrl;
                    this.loadingmyclient1 = false;
                });
        }
        //  else {
        //     this.closeclient(client)
        // }
    }

    redirectonmylistform(url,param) {
         
        if (url == "app/notes/seizureLogs") {
            this._router.navigate([url], { queryParams: { individualNameFilter: param } });
        }
        else if (url == "app/notes/nursingLogs") {
            this._router.navigate([url], { queryParams: { individualNameFilter: param } });
        }
        else if (url == "app/client/patients/cPdocs/Documentcenter/list") {
            this._router.navigate([url], { queryParams: { patientsnameFilter: param } });
        } 
        else {
            this._router.navigate([url], { queryParams: { filter: param } });
        }
       
    }

    closeclientpan(client:ClientDashboardDto){
       this.closeclient(client);
        
    }

    closeaccordians(clientid) {
        for (let cl of this.myclients) {
            if (cl.clientId != clientid)
                cl.isOpen = false;
            else
                cl.isOpen = true;
        }
    }

    closeclient(client:ClientDashboardDto)
    {
        if(client.isOpen == false)
        {
            client.isOpen = true;
        }
        else{
            client.isOpen = false;
        }
    }
}
