<div appBsModal #mySettingsModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #mySettingsModalForm="ngForm">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"MySettings" | localize}}</span>
                    </h5>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                         <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body" *ngIf="user">
                    <tabset>
                        <tab heading="{{'Profile' | localize}}">
                            <div class="form-group">
                                <label for="Name">{{"FirstName" | localize}} *</label>
                                <input id="Name" #nameInput="ngModel" class="form-control" type="text" name="Name" [(ngModel)]="user.name" required maxlength="64">
                                <validation-messages [formCtrl]="nameInput"></validation-messages>
                            </div>

                            <div class="form-group">
                                <label for="Surname">{{"Surname" | localize}} *</label>
                                <input id="Surname" #surnameInput="ngModel" type="text" name="Surname" class="form-control" [(ngModel)]="user.surname" required maxlength="64">
                                <validation-messages [formCtrl]="surnameInput"></validation-messages>
                            </div>

                            <div class="form-group">
                                <label for="EmailAddress">{{"EmailAddress" | localize}} *</label>
                                <input id="EmailAddress" #emailAddressInput="ngModel" type="email" name="EmailAddress" class="form-control" [(ngModel)]="user.emailAddress" required email maxlength="256">
                                <validation-messages [formCtrl]="emailAddressInput"></validation-messages>
                            </div>
                            <div *ngIf="smsEnabled">
                                <div class="form-group">
                                    <label>{{"PhoneNumber" | localize}}</label>
                                    <div class="input-group">
                                        <input type="text" name="PhoneNumber" class="form-control" [(ngModel)]="user.phoneNumber" maxlength="32">

                                        <span id="SpanSmsVerificationUnverified" class="input-group-btn">
                                            <button id="btnSmsVerification" (click)="smsVerify()" [disabled]="isPhoneNumberConfirmed && savedPhoneNumber == user.phoneNumber" class="btn btn-primary" type="button" tooltip="{{'YourPhoneNumberIsNotVerified' | localize}}">
                                                <i class="fa fa-exclamation-triangle"></i>{{'Verify' | localize}}
                                            </button>
                                        </span>

                                        <div *ngIf="isPhoneNumberConfirmed && savedPhoneNumber == user.phoneNumber" class="input-group-append" id="SpanSmsVerificationVerified">
                                            <span class="input-group-text" tooltip="{{'YourPhoneNumberIsVerified' | localize}}">
                                                <i class="la la-check label-success" [attr.aria-label]="l('Verified')"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="UserName">{{"UserName" | localize}} *</label>
                                <input id="UserName" #userNameInput="ngModel" type="text" [disabled]="!canChangeUserName" class="form-control" [(ngModel)]="user.userName" name="UserName" required maxlength="256">
                                <span class="form-text text-muted" *ngIf="!canChangeUserName">{{"CanNotChangeAdminUserName" | localize}}</span>
                                <validation-messages [formCtrl]="userNameInput"></validation-messages>
                            </div>

                            <div class="form-group" *ngIf="showTimezoneSelection">
                                <label for="Timezone">{{"Timezone" | localize}}</label>
                                <timezone-combo name="Timezone" [(ngModel)]="user.timezone" defaultTimezoneScope="{{defaultTimezoneScope}}"></timezone-combo>
                            </div>
                        </tab>
                        <tab *ngIf="!isMultiTenancyEnabled || isTwoFactorLoginEnabledForApplication" heading="{{'TwoFactorLogin' | localize}}">
                            <div>
                                <h4>
                                    Google Authenticator
                                </h4>

                                <div [hidden]="!isGoogleAuthenticatorEnabled">
                                    <div>
                                        {{"ScanQrCodeWithYourMobileApp" | localize}}
                                    </div>
                                    <div class="text-center">
                                        <img [src]="user.qrCodeSetupImageUrl" />
                                    </div>
                                    <small>
                                        {{"GoogleAuthenticatorReferrerLink" | localize}}: <a href="https://support.google.com/accounts/answer/1066447" target="_blank" rel="noopener noreferrer">Google Authenticator</a>
                                    </small>
                                </div>

                                <div [hidden]="isGoogleAuthenticatorEnabled">
                                    <button type="button" class="btn btn-success" (click)="updateQrCodeSetupImageUrl()">{{"Enable" | localize}}</button>
                                </div>

                                <div [hidden]="!isGoogleAuthenticatorEnabled">
                                    <button type="button" class="btn btn-danger" (click)="disableGoogleAuthenticator()">{{"Disable" | localize}}</button>
                                </div>
                            </div>
                        </tab>


                        <tab heading="{{'Signature' | localize}}" style="padding-top:0.5rem !important">
                            <tabset>
                                <tab heading="Upload Signature" (selectTab)="onSelect($event)">
                                    <div class="form-group">
                                        <input type="file" (change)="fileChangeEvent($event)" accept="image/x-png,image/gif,image/jpeg" />
                                        <span class="help-block m-b-none" style="color:green;">{{"ProfilePicture_Change_Info" | localize:maxSignatureBytesUserFriendlyValue}}</span>
                                    </div>
                                    <image-cropper [imageChangedEvent]="imageChangedEvent"
                                                   [maintainAspectRatio]="true"
                                                   [aspectRatio]="10 / 5"
                                                   [resizeToWidth]="128"
                                                   format="png"
                                                   (imageCropped)="imageCroppedFile($event)"></image-cropper>

                                    <div class="symbol-label col-xl-12 Level">
                                        <label class="col-xl-3">Selected Signature :- </label>
                                        <img [src]="profileSignature" class="symbol-label col-xl-9 imgSize" />
                                    </div>
                                </tab>

                                <tab heading="Draw Signature" (selectTab)="onSelect($event)">
                                    <div class="form-group">
                                        <canvas #sigPad width="700" height="250" (mousedown)="onMouseDown($event)"
                                                (mousemove)="onMouseMove($event)" style="background-color:lightgray;"></canvas>
                                        <br />
                                        <button type="button" class="btn btn-warning" (click)="clear()" [disabled]="disableButton">Clear Signature</button>
                                        &nbsp;&nbsp;
                                        <button type="button" class="btn btn-success" (click)="saveImg()" [disabled]="disableButton">Save Signature</button>
                                        <br />
                                        <img *ngIf="!profileSignature" [src]="img">
                                        <!--<ng-signature-pad doneButtonText="Save Signature"
                                  format="base64"
                                  (done)="showImage($event)">
                </ng-signature-pad>-->
                                    </div>

                                    <div class="symbol-label col-xl-12 Level">
                                        <label class="col-xl-3">Selected Signature :- </label>
                                        <img [src]="profileSignature" class="symbol-label col-xl-9 imgSize" />
                                    </div>

                                </tab>

                                <tab heading="Font Signature" (selectTab)="onSelect($event)">
                                    <canvas #canvasEl width=700 height=150></canvas>
                                    <mat-form-field color="accent">
                                        <label>Select Font type :- </label>
                                        <select matNativeControl class="form-control" name="canvasFont_sign" id="canvasFont_sign" (change)="onSignFontChange($event.target.value)" [(ngModel)]="user.signatureFontFamily">
                                            <option value="70px Andale Mono" style="font-family:'Andale Mono'">Andale Mono</option>
                                            <option value="70px Arial Italic" style="font-family:'Arial Italic'">Arial Italic</option>
                                            <option value="70px Comic Sans MS" style="font-family:'Comic Sans MS'">Comic Sans MS</option>
                                            <option value="70px Courier New Bold" style="font-family: 'Courier New Bold'">Courier New Bold</option>
                                            <option value="70px Georgia" style="font-family: 'Georgia'">Georgia</option>
                                            <option value="70px Georgia Bold Italic" style="font-family: 'Georgia Bold Italic'">Georgia Bold Italic</option>
                                            <option value="70px Lucida Sans Unicode" style="font-family: 'Lucida Sans Unicode'">Lucida Sans Unicode</option>
                                            <option value="70px Symbol" style="font-family: 'Symbol'">Symbol</option>
                                            <option value="70px Times New Roman Italic" style="font-family: 'Times New Roman Italic'">Times New Roman Italic</option>
                                            <option value="70px Trebuchet MS" style="font-family: 'Trebuchet MS'">Trebuchet MS</option>
                                            <option value="70px Trebuchet MS Bold Italic" style="font-family: 'Trebuchet MS Bold Italic'">Trebuchet MS Bold Italic</option>
                                            <option value="70px Verdana Italic" style="font-family: 'Verdana Italic'">Verdana Italic</option>
                                            <option value="70px Arial" style="font-family: 'Arial'">Arial</option>
                                            <option value="70px Arial Bold Italic" style="font-family: 'Arial Bold Italic'">Arial Bold Italic</option>
                                            <option value="70px Comic Sans MS Bold" style="font-family: 'Comic Sans MS Bold'">Comic Sans MS Bold</option>
                                            <option value="70px Courier New Italic" style="font-family: 'Courier New Italic'">Courier New Italic</option>
                                            <option value="70px Georgia Bold" style="font-family: 'Georgia Bold'">Georgia Bold</option>
                                            <option value="70px Impact" style="font-family: 'Impact'">Impact</option>
                                            <option value="70px Marlett" style="font-family: 'Marlett'">Marlett</option>
                                            <option value="70px Times New Roman" style="font-family: 'Times New Roman'">Times New Roman</option>
                                            <option value="70px Times New Roman Bold Italic" style="font-family: 'Times New Roman Bold Italic'">Times New Roman Bold Italic</option>
                                            <option value="70px Trebuchet MS Bold" style="font-family: 'Trebuchet MS Bold'">Trebuchet MS Bold</option>
                                            <option value="70px Verdana" style="font-family: 'Verdana'">Verdana</option>
                                            <option value="70px Verdana Bold Italic" style="font-family: 'Verdana Bold Italic'">Verdana Bold Italic</option>
                                            <option value="70px Arial Bold" style="font-family: 'Arial Bold'">Arial Bold</option>
                                            <option value="70px Arial Black" style="font-family: 'Arial Black'">Arial Black</option>
                                            <option value="70px Courier New" style="font-family: 'Courier New'">Courier New</option>
                                            <option value="70px Courier New Bold Italic" style="font-family: 'Courier New Bold Italic'">Courier New Bold Italic</option>
                                            <option value="70px Georgia Italic" style="font-family: 'Georgia Italic'">Georgia Italic</option>
                                            <option value="70px Lucida Console" style="font-family: 'Lucida Console'">Lucida Console</option>
                                            <option value="70px Minion Web" style="font-family: 'Minion Web'">Minion Web</option>
                                            <option value="70px Times New Roman Bold" style="font-family: 'Times New Roman Bold'">Times New Roman Bold</option>
                                            <option value="70px Tahoma" style="font-family: 'Tahoma'">Tahoma</option>
                                            <option value="70px Trebuchet MS Italic" style="font-family: 'Trebuchet MS Italic'">Trebuchet MS Italic</option>
                                            <option value="70px Verdana Bold" style="font-family: 'Verdana Bold'">Verdana Bold</option>
                                            <option value="70px Webdings" style="font-family: 'Webdings'">Webdings</option>
                                            <option value="70px Cedarville Cursive, cursive" style="font-family: 'Cedarville Cursive, cursive'">Cursive</option>


                                        </select>
                                    </mat-form-field>
                                    <div class="symbol-label col-xl-12 Level">
                                        <label class="col-xl-3 Level">Selected Signature :- </label>
                                        <img [src]="profileSignature" class="symbol-label col-xl-9 imgSize" />
                                    </div>
                                </tab>


                            </tabset>
                            <div class="alert alert-warning" style="margin-bottom: 0; margin-top: 15px">
                                <em>{{'Note_RefreshPageSignatureChanges' | localize}}</em>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light-primary font-weight-bold" (click)="close()" [disabled]="saving">{{"Cancel" | localize}}</button>
                    <button type="submit" class="btn btn-primary font-weight-bold" [disabled]="!mySettingsModalForm.form.valid || saving" (click)="save()"><i class="fa fa-save"></i> <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
<smsVerificationModal *ngIf="user" #smsVerificationModal (modalSave)="changePhoneNumberToVerified()" [newPhoneNumber]="user.phoneNumber"></smsVerificationModal>
<style>
    .imgSize {
        height:35%;
        width:35%;
    }
    .Level {
        padding:0;
    }

</style>
