<div bsModal #emarexportmodal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="emarexportmodal" aria-hidden="true" [config]="{backdrop: 'static'}">

    <div class="modal-dialog modal-md">

        <div class="modal-content">

            <form #emarReportmodalForm="ngForm" novalidate autocomplete="off" class="kt-form">

                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{l("eMAR - Export to PDF")}}</span>
                    </h4>


                    <button type="button" (click)="close()"
                        class="bg-gray-600 btn btn-circle btn-shadow-custom btn-hover-bg-danger pr-2"
                        matTooltip="close">
                        <i class="flaticon2-cross icon-sm-1x text-white"></i>
                    </button>

                </div>
                <div class="modal-body">
                    <div class="row">
                        <!--[hidden]="!advancedFiltersAreShown"-->
                        <div class="col-md-12">
                            <div class="kt-separator kt-separator--border-dashed"></div>
                        </div>
                        <div class="col-md-4" *ngIf="false">
                            <div class="form-group">
                                <label for="clientsnameFilter">Client Name</label>
                                <div class="input-group">

                                    <p-autoComplete [(ngModel)]="filterText" id="filter" name="filterText"
                                        [type]="'search'" (input)="searchfilter($event)" [suggestions]="clientData"
                                        (onSelect)="onClientselect($event)" placeholder="Search Client"
                                        style="width:100%" inputStyleClass="form-control " class="p-autocomplete p-0"
                                        styleClass="width-percent-100">
                                        <ng-template let-client pTemplate="item">
                                            <div class="d-flex align-items-center mb-2 p-2 alternative_cls ">

                                                <div class="d-flex flex-column flex-grow-1 font-weight-bold ">
                                                    <a
                                                        class="text-dark text-hover-primary mb-1 ">{{client.clientName}}</a>
                                                    <span class="text-muted">DOB : {{client.dob | date: "MM/dd/yyyy"}}
                                                        <span></span></span>
                                                </div>
                                                <div class=" ml-2 mr-2 bg-light-primary" data-toggle="tooltip"
                                                    data-placement="left" data-original-title="Quick actions ">
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="StartEndRange">Date(Select Range)</label>
                                <div class="input-group">
                                    <input id="StartEndRange" name="StartEndRange" #drp="bsDaterangepicker"
                                        class="form-control" bsDaterangepicker dateRangePickerMomentModifier
                                        [bsConfig]="{ rangeInputFormat : 'MM/DD/YYYY', dateInputFormat: 'MM/DD/YYYY' }"
                                        [(ngModel)]="dateRange" (ngModelChange)="dateRangeSelected($event)" [maxDate]="maxDate1">
                                    <button *ngIf="dateRange" class="btn bg-transparent z-index"
                                        (click)="clearDaterange()">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="text-right form-group">
                        <button (click)="exportToPDF()" [disabled]="isValid" class="btn btn-outline-success mr-2"
                            [busyText]="('Processing')" [buttonBusy]="export">
                            <i class="far fa-file-pdf"></i>
                            {{l("Print")}}
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>

</div>
