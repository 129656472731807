import { AfterViewChecked, Component, ElementRef, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { outputs } from '@syncfusion/ej2-angular-navigations/src/sidebar/sidebar.component';
import { CreateOrEditHoldScheduleTimeDto, GetHoldMedicationForEditOutput, GetHoldScheduleTimeForEditOutput, HoldMedicationsServiceProxy, HoldScheduleTimesServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import Swal from 'sweetalert2';



@Component({
    selector: 'holdScheduleModalDialog',
    templateUrl: './hold-schedule-dialog.component.html'
})
export class HoldScheduleDialogComponent extends AppComponentBase {

    @ViewChild('holdScheduleModal', { static: true }) modal: ModalDirective;
    @ViewChild('holdMedForm', { static: true }) form: NgForm;
    @Output() saveHoldSchedulMed: EventEmitter <any> = new EventEmitter<any> (); 

    active = false;
    saving = false;
    meddata:any;
    status:string;
    time:any;
    timeAt:any;
    _required=false;
    viewdisbl=false;
    disblHold=false;
    disblResum=false
    holdBtn=false;
    resmBtn=false;
    viewResmBtn=false;
    viewUpdt=false
    _mode:string;
    today= new Date();
    bsValue1 = new Date();
    bsValue2 = new Date();
    dateCustomClasse: DatepickerDateCustomClasses[];
    medSchTime:GetHoldMedicationForEditOutput[];
    medicationId:number;
    holdMedicationSchedule = new CreateOrEditHoldScheduleTimeDto();
    constructor(injector: Injector,
        private _holdMedicationsServiceProxy: HoldMedicationsServiceProxy,
        private _holdScheduleTimeServiceProxy: HoldScheduleTimesServiceProxy) { 
        super(injector);
        (moment as any).fn.toString = function () { return this.format("L"); };
    }

    counter(i: number): any {
        return new Array(i);
    }

    show(holdSchedule?: GetHoldScheduleTimeForEditOutput): void {
        this.time=holdSchedule.scheduleTime
        this.timeAt=holdSchedule.scheduleTimeAt
        if(holdSchedule.holdScheduleTime.status==null ||holdSchedule.holdScheduleTime.status==undefined ||this._mode=="add"){ 
            this.resmBtn=false;
          //  this.disblResum=true
            this.viewUpdt=false;
            this.holdBtn=true;  
            this.viewdisbl=false;
            this.disblHold=false;
            this.holdMedicationSchedule = new CreateOrEditHoldScheduleTimeDto();
            this.dateCustomClasses();
            this.holdMedicationSchedule.scheduleTimeId = holdSchedule.scheduleTimeId;
            this.bsValue1 = moment(this.holdMedicationSchedule.resumeFrom, 'MM/DD/YYYY').toDate();
            this.bsValue2 = moment(this.holdMedicationSchedule.holdFrom, 'MM/DD/YYYY').toDate();           
            this.modal.show();
        }
        else{
           if(this._mode=="view"){
            this.disblHold=true
            this.viewdisbl=true;
            this.disblResum=true
            this.resmBtn=false;
            this.viewUpdt=false;
            this.holdBtn=false;  
           }
          
            this.holdBtn=false;
            this._holdScheduleTimeServiceProxy.getHoldScheduleForEdit(holdSchedule.holdScheduleTime.id)
            .subscribe(result => {
                if (result.holdScheduleTime) {
                    this.holdMedicationSchedule = result.holdScheduleTime;
                    var todayDate = new Date();
                    var cuurntDate = moment(todayDate, 'MM/DD/YYYY').toString();
                    var holdDate = (this.holdMedicationSchedule.holdFrom).toString();
                    if(cuurntDate > holdDate){
                        this.disblHold=true;
                    }
                   
                   if(this._mode=="edit"){
                        this.viewUpdt=true;
                        this.resmBtn=false;
                        this.holdBtn=false;
                        this.viewdisbl=false;
                        this.disblHold=false;
                        
                    }
                    if(this.status=='Hold'){
                        this.resmBtn=true;
                        this.viewUpdt=false;
                        this.holdBtn=false;
                        this.viewdisbl=false;
                        this.disblHold=false;
                       
                      }
                      if(this._mode=="edit" && this.status=='Hold'){
                        this.viewUpdt=true;
                        this.resmBtn=false;
                        this.holdBtn=false;
                        this.viewdisbl=false;
                         this.disblHold=false;
                    }
                      
                }
              })
              if (this.holdMedicationSchedule.resumeFrom)
              this.bsValue1 = moment(this.holdMedicationSchedule.resumeFrom, 'MM/DD/YYYY').toDate();
          else
              this.bsValue1 = null;
              if (this.holdMedicationSchedule.resumeFrom) {
                this.holdMedicationSchedule.resumeFrom = this.holdMedicationSchedule.resumeFrom;
            }
              if (this.holdMedicationSchedule.holdFrom)
              this.bsValue2 = moment(this.holdMedicationSchedule.holdFrom, 'MM/DD/YYYY').toDate();
          else
              this.bsValue2 = null;
              if (this.holdMedicationSchedule.holdFrom) {
                this.holdMedicationSchedule.holdFrom = this.holdMedicationSchedule.holdFrom;
            }
                   
          this.modal.show();
         
        }
      
    }
    dateCustomClasses(){
        
        const now = new Date();
        const twoDaysAhead = new Date();
        twoDaysAhead.setDate(now.getDate());

        this.dateCustomClasse = [
            { date: now, classes: [] },
            { date: twoDaysAhead, classes: ['bg-warning'] }
          ];
    }
    resumeDateSelected(){
        // if( moment(this.holdMedicationSchedule.holdFrom).toDate())
        // {
        //     this.disblResum=false;
        // }
        if( moment(this.holdMedicationSchedule.resumeFrom).toDate() >= moment(this.holdMedicationSchedule.holdFrom).toDate())
        {
            this.dateCustomClasse = [];
        }
        else
        {
            this.dateCustomClasses();
           (<HTMLInputElement>document.getElementById('resumeFrom')).value = null;
           this.holdMedicationSchedule.resumeFrom=null;
           
        }
    }
    holdDateSelected(){
       
        (<HTMLInputElement>document.getElementById('resumeFrom')).value = null;
        this.holdMedicationSchedule.resumeFrom=null;
        if( moment(this.holdMedicationSchedule.holdFrom).toDate()){
            this.dateCustomClasse = [];
        }
        else
        {
            this.dateCustomClasses();
         
           
        }
       
    }

    onShown(): void {
        
        
    }

    save(string?): void {
       if (this.holdMedicationSchedule.comment == " " || this.holdMedicationSchedule.comment == undefined ||this.holdMedicationSchedule.holdFrom == null || this.holdMedicationSchedule.holdFrom == undefined ||this.holdMedicationSchedule.resumeFrom == null || this.holdMedicationSchedule.resumeFrom == undefined) {
            this._required = true;
            
        }
     else{
        if(string!='Update'){
            if(string=='Hold'){
                this.holdMedicationSchedule.status='Hold'
            }
            else{
                this.holdMedicationSchedule.status='Resume'
            }
           
          //  this.holdMedicationSchedule.id=null;
         }
         this.holdMedicationSchedule.medicationId = this.medicationId;
        this._holdScheduleTimeServiceProxy.createOrEdit(this.holdMedicationSchedule)
        .subscribe(result => {
            this.holdMedicationSchedule.id = result;
            this.close();
            this.notify.info(this.l('SavedSuccessfully'));
           
            this.saveHoldSchedulMed.emit(this.medicationId);
        });
    }
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

   
}
