<div bsModal #administerMeddialog="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="administerMeddialog" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #administerMeddialogForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header border-0 d-flex">
                    <div>
                        <h4 class="modal-title text-uppercase text-primary font-weight-bolder">
                            {{patientName}} 
                        </h4>

                        <h4 class="modal-title text-uppercase font-weight-bolder">
                            {{drugName}}
                        </h4>
                       
                    </div>
               
                    <button type="button" (click)="close()" class="close mt-n10"  title="Close">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                 <div class="modal-body mt-n12">
                    <fieldset  class="the-fieldset">
                      <div class="row card card-custom">
                                <div class="card-body pt-5 pb-0">
                                    <div class="row">
                                        <div class=" col-12 alert alert-custom alert-notice alert-light-warning fade show margin-b mt-7"
                                            *ngIf=" IsLateAdministered && hide " role="alert">
                                            <div class="alert-icon"><i class="far fa-lightbulb"></i></div>
                                            <div class="alert-text font-sizem">
                                                It is overdue, comment is required.
                                            </div>
                                        </div>
                                        <div class="form-group col-sm-12">
                                            <label>Comments</label>
                                            <textarea class="form-control" [(ngModel)]="comment" name="commemt" id="CommentsTextarea" rows="2"
                                                [disabled]="status =='Administered'" placeholder="Enter your comments here"></textarea>
                                            <!-- <span class="text-danger"
                                                                                    *ngIf="_required && IsLateAdministered && (comment==null)">*Required</span> -->
                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </fieldset> 
                </div> 
                <div class="modal-footer">
                    <div class="row d-flex flex-grow-1">
                        <div class="flex-column flex-grow-1 text-right">
                            <button [disabled]="saving" type="button"
                                class="btn btn-light-primary font-weight-bold mr-2" (click)="close()">
                                <span>Cancel</span>
                            </button>
                            <button type="button" (click)="save()" class="btn btn-primary font-weight-bold" *ngIf="status !='Administered'" >Administer</button>
                            <!-- <button type="submit" class="btn btn-primary font-weight-bold" >
                                <i class="fas fa-check pr-2"></i>
                                <span>{{"Save" | localize}}</span>
                            </button> -->
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>