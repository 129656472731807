import {PermissionCheckerService} from 'abp-ng2-module';
import {AppSessionService} from '@shared/common/session/app-session.service';

import {Injectable} from '@angular/core';
import {AppMenu} from './app-menu';
import {AppMenuItem} from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {

    }

    getMenu(): AppMenu {
       
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
          
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'fas fa-home', '/app/main/dashboard'),

            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),

            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),

            new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components '),

            new AppMenuItem('Help Desk', '', 'la la-ticket', '', [], [

                new AppMenuItem('Search Help Desk', 'Pages.RequestTickets', 'fa fa-search', '/app/helpdesk/requestTickets/requestTickets'),

                new AppMenuItem('New Help Desk', 'Pages.RequestTickets.Create', 'flaticon2-add-1', '/app/helpdesk/requestTickets/create-or-edit-requestTicket-modal/0'),
            ]),

            new AppMenuItem('Notes', '', 'fas fa-notes-medical', '', [], [

                new AppMenuItem('Behavior Logs', 'Pages.BehaviorLogs', 'fab fa-blogger-b', '/app/notes/behaviorLogs'),
                new AppMenuItem('BloodSugarLogs', 'Pages.BloodSugarLogs', 'fas fa-bong', '/app/notes/bloodSugarLogs'),
                new AppMenuItem('Bowel Movements', 'Pages.BowelMovements', 'fas fa-poo', '/app/notes/bowelMovements'),
                new AppMenuItem('Daily Logs/Attendance', 'Pages.DailyLogs', 'flaticon2-box-1', '/app/notes/dailyLogs'),
                new AppMenuItem('DisinfectLogs', 'Pages.DisinfectLogs', 'fas fa-broom ', '/app/notes/disinfectLogs'),
                new AppMenuItem('Family/Team Member Logs', 'Pages.FamilyMemberLogs', 'fas fa-users', '/app/notes/familyMemberLogs'),
                new AppMenuItem('Goal/Outcome Logs', 'Pages.GoalLogs', 'fas fa-bullseye', '/app/notes/goalLogs'),
                new AppMenuItem('Health Logs', 'Pages.HealthLogs', 'fas fa-h-square', '/app/notes/healthLogs'),
                new AppMenuItem('Nursing Logs', 'Pages.NursingLogs', 'fas fa-user-nurse', '/app/notes/nursingLogs'),
                new AppMenuItem('Seizure Logs', 'Pages.SeizureLogs', 'fa fa-headphones', '/app/notes/seizureLogs'),
                new AppMenuItem('Temperature Logs', 'Pages.TemparatureLogs', 'fas fa-temperature-high', '/app/notes/temparatureLogs'),
                new AppMenuItem('Transportation Logs', 'Pages.TransportationLogs', 'fas fa-bus-alt', '/app/notes/transportationLogs'),
                /* new AppMenuItem('New Notes', '', 'far fa-sticky-note', '/app/newnotes/newNotes') */
              
            ]),
          
            new AppMenuItem('Document Center', '', 'far fa-folder', '',[], [
                new AppMenuItem('Document Center Search', 'Pages.DcoumentCenter', 'fa fa-search', '/app/client/patients/cPdocs/Documentcenter/list'),
                new AppMenuItem('Document Center Upload', 'Pages.DcoumentCenter.Upload', 'fa fa-upload', '/app/client/patients/cPdocs/Documentcenter/upload'),
                //new AppMenuItem('Document Type', '', 'flaticon2-add-1', '/app/client/documentModule/documentTypeForm/create-or-edit-documentTypeForm-modal/0'),
            ]),
           // new AppMenuItem('PCBs', '', 'flaticon-more', '/app/pcbbp/pcBs/pcBs'),
            new AppMenuItem('PCP/BP', '', 'fa fa-life-ring', '', [], [
                new AppMenuItem('Search PCP/BP', 'Pages.PCBs.List', 'fa fa-search', '/app/pcbbp/pcBs/pcBs'),
                new AppMenuItem('New PCP/BP', 'Pages.PCBs.Create', 'flaticon2-add-1', '/app/pcbbp/pcBs/create-or-edit-pcb.component/0/txt/0'),
            ]),

            new AppMenuItem('Client Profile', '', 'fas fa-user-tie', '', [], [
                new AppMenuItem('Search Client Profile', 'Pages.Patients.List', 'fa fa-search', '/app/client/patients/patients'),
                new AppMenuItem('New Client Profile', 'Pages.Patients.Create', 'flaticon2-add-1', '/app/client/patients/create-or-edit-patients-modal/0/txt')
            ]),

            new AppMenuItem('Med Admin Screening Tool', '', 'fas fa-briefcase-medical', '',[], [
                new AppMenuItem('Search Med Admin Screening Tool', 'Pages.MedAdminScreeningTools', 'fa fa-search', '/app/medadmin/medAdminScreeningTools/medAdminScreeningTools'),
                new AppMenuItem('New Med Admin Screening Tool', 'Pages.MedAdminScreeningTools.Create', 'flaticon2-add-1', '/app/medadmin/medAdminScreeningTools/create-or-edit-medAdminScreeningTool-modal/0/txt'),
            ]),

            new AppMenuItem('Behavioral', '', 'fab fa-gg', '', [], [
                new AppMenuItem('ABC', '', 'fa fa-arrows-alt', '', [], [
                    new AppMenuItem('Search ABC', 'Pages.abc', 'fa fa-search', '/app/behavioral/behavioral/abCs'),
                    new AppMenuItem('New ABC', 'Pages.abc.Create', 'flaticon2-add-1', '/app/behavioral/behavioral/create-or-edit-abc-modal/0/txt'),
                ]),
                new AppMenuItem('Behavioral Consultation', '', 'fa fa-indent', '', [], [
                    new AppMenuItem('Search Behavioral Consultation', 'Pages.behavioralConsultation', 'fa fa-search', '/app/behavioral/behavioral/behavioralConsultations'),
                    new AppMenuItem('New Behavioral Consultation', 'Pages.behavioralConsultation.Create', 'flaticon2-add-1', '/app/behavioral/behavioral/create-or-edit-behavioralConsultation-modal/0/txt'),

                ]), 
                new AppMenuItem('BSIS', '', 'fa fa-th-large', '', [], [
                    new AppMenuItem('Search BSIS', 'Pages.BSISs', 'fa fa-search', '/app/behavioral/behavioral/bsiSs'),
                    new AppMenuItem('New BSIS', 'Pages.BSISs.Create', 'flaticon2-add-1', '/app/behavioral/behavioral/create-or-edit-bsis-modal/0/txt'),

                ]),
                new AppMenuItem('Behavioral Diary', '', 'flaticon-interface-3', '', [], [
                    new AppMenuItem('Search Behavioral Diary', 'Pages.BehavioralDiaries', 'fa fa-search', '/app/behavioral/behavioraldiary/behavioralDiaries'),
                    new AppMenuItem('New Behavioral Diary', 'Pages.BehavioralDiaries.Create', 'flaticon2-add-1', '/app/behavioral/behavioraldiary/create-or-edit-behavioralDiary-modal/0/txt'),

                ]),
            ]),
            new AppMenuItem('Nursing', '', 'fas fa-user-nurse', '', [], [

                new AppMenuItem('Nursing Assessment', '', 'la la-edit', '', [], [
                    new AppMenuItem('Search Nursing Assessment', 'Pages.NursingAssessments.List', 'fa fa-search', '/app/assessment/nursingAssessments/nursingAssessments'),
                    new AppMenuItem('New Nursing Assessment', 'Pages.NursingAssessments.Create', 'flaticon2-add-1', '/app/assessment/nursingAssessments/create-or-edit-nursingAssessment-modal/0/txt/0'),
                //new AppMenuItem('Settings', 'Pages.NursingAssessments.Create', 'fas fa-cog', '/app/assessment/settings'),
                ]),

                new AppMenuItem('Nursing Care Plan', '', 'fa fa-street-view', '', [], [
                    new AppMenuItem('Search Nursing Care Plan', 'Pages.NursingCarePlans.List', 'fa fa-search', '/app/nursing/nursingCarePlan/nursingCarePlans'),
                    new AppMenuItem('New Nursing Care Plan', 'Pages.NursingCarePlans.Create', 'flaticon2-add-1', '/app/nursing/nursingCarePlan/create-or-edit-nursingCarePlan-modal/0/0/txt/0'),
                    //new AppMenuItem('Settings', 'Pages.NursingAssessments', 'fas fa-cog', '/app/nursing/nursingCarePlan/settings'),
                ]),

                new AppMenuItem('Health Screening & Nursing Needs', '', 'fa fa-life-ring', '', [], [
                    new AppMenuItem('Search Health Screening & Nursing Needs', 'Pages.HRSTNursingNeeds', 'fa fa-search', '/app/hrst_ist/hrstNursingNeeds/hrstNursingNeeds'),
                    new AppMenuItem('New Health Screening & Nursing Needs', 'Pages.HRSTNursingNeeds.Create', 'flaticon2-add-1', '/app/hrst_ist/hrstNursingNeeds/create-or-edit-hrstNursingNeed-modal/0/txt'),
                ]),

                new AppMenuItem('Nurse Report To Teams', '', 'fa fa-list-ul', '', [], [
                    new AppMenuItem('Search Nurse Report To Teams', 'Pages.NurseReportToTeams', 'fa fa-search', '/app/nursing/nurseReportToTeams/nurseReportToTeams'),
                    new AppMenuItem('New Nurse Report To Teams', 'Pages.NurseReportToTeams.Create', 'flaticon2-add-1', '/app/nursing/nurseReportToTeams/create-or-edit-nurseReportToTeam-modal/0/txt'),
                ]),

                new AppMenuItem('Nursing Diary', '', 'flaticon-interface-3', '', [], [
                    new AppMenuItem('Search Nursing Diary', 'Pages.NursingDiaries', 'fa fa-search', '/app/nursing/nursingDiaries/nursingDiaries'),
                    new AppMenuItem('New Nursing Diary', 'Pages.NursingDiaries.Create', 'flaticon2-add-1', '/app/nursing/nursingDiaries/create-or-edit-nursingDiary-modal/0/txt'),
                ]),
            ]),

            new AppMenuItem('Medications', '', 'fas fa-prescription', '', [], [ 
                new AppMenuItem('Search Medications', 'Pages.ClientMedications', 'fa fa-search', '/app/medications/list'),
                new AppMenuItem('New Medication', 'Pages.ClientMedications.Create', 'flaticon2-add-1', '/app/medications/select/client')
            ]),

            new AppMenuItem('eMAR', '', 'fas fa-pills', '', [], [
                new AppMenuItem('Administer Meds', 'Pages.ClientMedications', 'fas fa-capsules', '/app/medications/qmar/med'),
                new AppMenuItem('eMAR Dashboard', 'Pages.ClientMedications', 'fas fa-chart-line', '/app/medications/emar/dashboard'),
            ]),

            new AppMenuItem('Choking Risk Screening Tool', '', 'fa fa-podcast', '', [], [
                new AppMenuItem('Search Choking Risk Screening Tool', 'Pages.ChokingRiskTools', 'fa fa-search', '/app/crst/chokingRiskTools/chokingRiskTools'),
                new AppMenuItem('New Choking Risk Screening Tool', 'Pages.ChokingRiskTools.Create', 'flaticon2-add-1', '/app/crst/chokingRiskTools/create-or-edit-chokingRiskTool-modal/0/txt'),
            ]),

            new AppMenuItem('E-Signature', '', 'flaticon2-writing', '', [], [
                new AppMenuItem('Search E-Signature', 'Pages.StandingCommiteeMeetings', 'fa fa-search', '/app/meeting/standingCommitee/standingCommiteeMeetings'),
                new AppMenuItem('New E-Signature', 'Pages.StandingCommiteeMeetings.Create', 'flaticon2-add-1', '/app/meeting/standingCommitee/create-or-edit-standingCommiteeMeeting-modal/0'),
            ]),

            new AppMenuItem('Individual Specific Training', '', 'flaticon2-line-chart', '', [], [
                new AppMenuItem('Search Individual Specific Training', 'Pages.IndividualSpecificTrainings', 'fa fa-search', '/app/hrst_ist/individualSpecificTrainings/individualSpecificTrainings'),
                new AppMenuItem('New Individual Specific Training', 'Pages.IndividualSpecificTrainings.Create', 'flaticon2-add-1', '/app/hrst_ist/individualSpecificTrainings/create-or-edit-individualSpecificTraining-modal/0/txt'),
            ]),
            new AppMenuItem('Nutrition Assessment', '', 'fa fa-medkit', '', [], [
                new AppMenuItem('Search Nutrition Assessment', 'Pages.NursingNutritions', 'fa fa-search', '/app/nutrition/nursingNutritions/nursingNutritions'),
                new AppMenuItem('New Nutrition Assessment', 'Pages.NursingNutritions.Create', 'flaticon2-add-1', '/app/nutrition/nursingNutritions/create-or-edit-nursingNutrition-modal/0/txt'),
            ]),

            new AppMenuItem('Nutrition Diary', '', 'la la-balance-scale', '', [], [
                new AppMenuItem('Search Nutrition Diary', 'Pages.NutritionDiaries', 'fa fa-search', '/app/nutrition/nutritionDiaries/nutritionDiaries'),
                new AppMenuItem('New Nutrition Diary', 'Pages.NutritionDiaries.Create', 'flaticon2-add-1', '/app/nutrition/nutritionDiaries/create-or-edit-nutritionDiary-modal/0/txt'),
            ]),
            new AppMenuItem('Fire Drill', '', 'fa fa-fire-extinguisher ', '', [], [
                new AppMenuItem('Search Fire Drill', 'Pages.FireDrills.List', 'fa fa-search', '/app/fireDrills/fireDrills/fireDrills'),
                new AppMenuItem('New Fire Drill', 'Pages.FireDrills.Create', 'flaticon2-add-1', '/app/fireDrills/fireDrills/create-or-edit-fireDrill-modal/0/txt'),
            ]),


            //new AppMenuItem('FacilityReports', 'Pages.FacilityReports', 'flaticon-more', '/app/facilityReports/facilityReports'),
            new AppMenuItem('Facility Report', '', 'fa fa-building ', '', [], [
                new AppMenuItem('Search Facility Report', 'Pages.FacilityReports.List', 'fa fa-search', '/app/facilityReports/facilityReports/facilityReports'),
                new AppMenuItem('New Facility Report', 'Pages.FacilityReports.Create', 'flaticon2-add-1', '/app/facilityReports/facilityReports/create-or-edit-facilityReport-modal/0/txt'),
            ]),
            //  new AppMenuItem('FireDrills', 'Pages.FireDrills', 'flaticon-more', '/app/main/fireDrills/fireDrills'),
           // new AppMenuItem('IncidentReports', 'Pages.IncidentReports', 'fa fa-exclamation-triangle', '/app/incident/incidentReports/incidentReports'),

            new AppMenuItem('Incident Report', '', 'fa fa-exclamation-triangle', '', [], [
                new AppMenuItem('Search Incident Report', 'Pages.IncidentReports.List', 'fa fa-search', '/app/incident/incidentReports/incidentReports'),
                new AppMenuItem('New Incident Report', 'Pages.IncidentReports.Create', 'flaticon2-add-1', '/app/incident/incidentReports/create-or-edit-incidentReport-modal/0/txt/0'),
            ]),

            new AppMenuItem('Client Diary', '', 'fa fa-address-card', '', [], [
                new AppMenuItem('Search', 'Pages.ClientDiaries', 'fa fa-search', '/app/client/clientDiaries/clientDiaries'),
                new AppMenuItem('New', 'Pages.ClientDiaries.Create', 'flaticon2-add-1', '/app/client/clientDiaries/create-or-edit-clientDiary-modal/0/txt'),
                //new AppMenuItem('Client Dashboard', 'Pages.Patients', 'fa fa-id-card', '/app/client/dashboard/patient-dashboard/0'),               
            ]),
            //new AppMenuItem('Document Type', '', 'fa flaticon-folder-1', '', [], [
            //    new AppMenuItem('Search', 'Pages.Documenttypes', 'fa fa-search', '/app/masters/documenttype/documenttypes'),
            //    new AppMenuItem('New', 'Pages.Documenttypes.Create', 'flaticon2-add-1', '/app/masters/documenttype/create-or-edit-documenttype-modal/0'),
            //]),
            new AppMenuItem('Training', '', 'fas fa-graduation-cap', '', [], [
                new AppMenuItem('Courses', '', 'fas fa-book-reader', '', [], [
                    new AppMenuItem('Search Courses', 'Pages.Courses', 'fa fa-search', '/app/training/course/courses'),
                    new AppMenuItem('New Courses', 'Pages.Courses.Create', 'flaticon2-add-1', '/app/training/course/courses/createOrEdit/0/new'),
                    
                ]),
                new AppMenuItem('Certificates', 'Pages.CertificateUploads', 'fas fa-certificate', '', [], [
                    new AppMenuItem('Search Certificates', 'Pages.CertificateUploads', 'fa fa-search', '/app/training/ListUploadCerts/list-upload-certs/search'),
                    new AppMenuItem('Upload Certificates', 'Pages.CertificateUploads.Create', 'flaticon2-add-1', '/app/training/ListUploadCerts/list-upload-certs/upload'),

                ]),
                new AppMenuItem('Dashboard', 'Pages.Training.Dashboard', 'fas fa-border-all', '/app/training/dashboard/0', [], []),
                new AppMenuItem('Calendar', 'Pages.Calenders', 'fas fa-calendar-alt', '/app/calenderPlanner/calenders/createOrEdit/1/create', [], []),      
            ]),
            new AppMenuItem('Announcement', 'Pages.Announcements', 'fa fa-bullhorn', '/app/main/announcements/announcements'),

            new AppMenuItem('Appointments', 'Pages.Appointment', 'far fa-calendar-check', '', [], [
                new AppMenuItem('Search Appointments', 'Pages.Appointment', 'fa fa-search', '/app/appointment/client/detailed-view'),
                new AppMenuItem('New Appointment', 'Pages.Appointment.Create', 'flaticon2-add-1', '/app/appointment/client/select'),
                new AppMenuItem('Appointment Cal', 'Pages.Appointment', 'far fa-calendar-alt', '/app/appointment/client/cal-view'),
            ]),
           
            
            //new AppMenuItem('Calenders', '', 'fas fa-calendar-alt', '/app/calenderPlanner/calenders'),            
            //new AppMenuItem('EventParticipants', 'Pages.EventParticipants', 'flaticon-more', '/app/calenderPlanner/eventParticipants'),
            
            new AppMenuItem('NewProgramMasters', 'Pages.NewProgramMasters', 'flaticon-more', '/app/main/masters/newProgramMasters'),
                        
            new AppMenuItem('HealthCareProviders', 'Pages.HealthCareProviders', 'flaticon-more', '/app/main/nursingAssessment/healthCareProviders'),
            
        
            new AppMenuItem('Referrals', '', 'fa fa-users', '', [], [
                new AppMenuItem('Search Referrals', 'Pages.Referrals', 'fa fa-search', '/app/main/referralModule/referrals'),
                new AppMenuItem('New Referrals', 'Pages.Referrals.Create', 'flaticon2-add-1', '/app/main/referralModule/referrals/createOrEdit/0/create'),
            ]),

           
            
            new AppMenuItem('BehaviorsCategoryMasters', 'Pages.BehaviorsCategoryMasters', 'flaticon-more', '/app/main/masters/behaviorsCategoryMasters'),
            
            new AppMenuItem('BehaviorsMasters', 'Pages.BehaviorsMasters', 'flaticon-more', '/app/main/masters/behaviorsMasters'),
            
            new AppMenuItem('TargatedBehaviors', 'Pages.TargatedBehaviors', 'flaticon-more', '/app/main/targatedBehaviors/targatedBehaviors'),
             new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [], [

                 new AppMenuItem('Masters', '', 'fas fa-layer-group', '', [], [
                   
                     new AppMenuItem('Sites', 'Pages.Sites', 'fa fa-map-marker', '/app/masters/site/sites'),
                     new AppMenuItem('Document Type', 'Pages.Documenttypes', 'fa flaticon-folder-1', '/app/masters/documenttype/documenttypes'),
                     new AppMenuItem('Document Categories', 'Pages.Categories', 'fas fa-list-alt', '/app/main/masters/categories'),

                     new AppMenuItem('Drugs', 'Pages.Documenttypes', 'fas fa-pills', '/app/masters/med/list'),
                     new AppMenuItem('Providers', 'Pages.ProvidersMasters', 'fas fa-user-md', '/app/masters/provider/list'),
                     new AppMenuItem('Practice Groups', 'Pages.PracticeGroupMasters', 'fas fa-clinic-medical', '/app/masters/provider/group/list'),

                     new AppMenuItem('Programs', 'Pages.Programs', 'fas fa-braille', '/app/masters/programDepartment/programDepartment'),
                     new AppMenuItem('Title Master', 'Pages.TitleMasters', 'fas fa-font', '/app/masters/titleMasters/titleMasters'),
                     new AppMenuItem('Departments', 'Pages.UserDepartments', 'fas fa-columns', '/app/admin/users/userDepartments'), 
                 ]),
                 new AppMenuItem('WFSettings', '', 'fas fa-cog', '', [], [
                     new AppMenuItem('WFBehaviorLog', 'Pages.WFBehaviorLog', 'fab fa-blogger-b', '/app/settings/behaviorlog/behaviorlogsettings'),
                     new AppMenuItem('WFBloodSugarLog', 'Pages.WFBloodSugarLog', 'fas fa-bong', '/app/settings/bloodsugar/bloodsugarlogsettings' ),
                     new AppMenuItem('WFBowelMovement', 'Pages.WFBowelMovement', 'fas fa-poo', '/app/settings/bowelmovement/bowelmovementsettings'),
                     new AppMenuItem('WFDailyLog', 'Pages.WFDailyLog', 'flaticon2-box-1', '/app/settings/dailylog/dailylogsettings'),
                     new AppMenuItem('WFDisinfectLog', 'Pages.WFDisinfectLog', 'fas fa-broom', '/app/settings/disinfectLog/disinfectlogsettings'),
                     new AppMenuItem('WFFamilyMemberLog', 'Pages.WFFamilyMemberLog', 'fas fa-users', '/app/settings/familymemberlog/familymemberlogsettings'),
                     new AppMenuItem('WFFireDrill','Pages.WFFireDrill', 'fa fa-fire-extinguisher ', '/app/settings/firedrill/firedrillsettings'),
                     new AppMenuItem('WFGoalLog', 'Pages.WFGoalLog', 'fas fa-bullseye', '/app/settings/goallog/goallogsettings'),
                     new AppMenuItem('WFHealthLog', 'Pages.WFHealthLog', 'fas fa-h-square', '/app/settings/healthlog/healthlogsettings'),
                     new AppMenuItem('WFIncident', 'Pages.WFIncidentreport', 'fa fa-exclamation-triangle', '/app/settings/incidentreport/IncidentReportSettings'),
                     new AppMenuItem('WFassessment', 'Pages.WFAssessment', 'la la-edit', '/app/assessment/settings'),
                     new AppMenuItem('WFnursingcare', 'Pages.WFPnursingcare', 'fa fa-street-view', '/app/settings/nursingcare/NursingCareSettings'),
                     new AppMenuItem('WFnursinglog', 'Pages.WFNursingLog', 'fas fa-user-nurse', '/app/settings/nursinglog/NursingLogSettings'),
                     new AppMenuItem('WFPcpbp', 'Pages.WFPcpbp', 'fa fa-life-ring', '/app/settings/pcpbp/PCBBPSettings'),
                     new AppMenuItem('WFSeizureLog', 'Pages.WFSeizureLog', 'fa fa-headphones', '/app/settings/seizurelog/seizurelogsettings'),
                     new AppMenuItem('WFTemperatureLog', 'Pages.WFTemperatureLog', 'fas fa-temperature-high', '/app/settings/temperaturelog/temperaturelogsettings'),
                     new AppMenuItem('WFTransportationLog', 'Pages.WFTransportationLog', 'fas fa-bus-alt', '/app/settings/transportationlog/transportationlogsettings'),
                     new AppMenuItem('FacilityReport', 'Pages.WFFacilityReport', 'fa fa-building', '/app/settings/facilityreportssetting/facilityreportssetting'),
                     new AppMenuItem('Referrals', 'Pages.WFReferrals', 'fa fa-users', '/app/settings/referralssetting/referralssetting'),
                     new AppMenuItem('Medication Management', 'Pages.WFMedication', 'fas fa-file-prescription', '/app/settings/Medication/clientmedicationsettings'),
                     new AppMenuItem('eMAR', 'Pages.WFeMAR', 'fas fa-pills', '/app/settings/eMARsettings'),
                     
                    ]),
                //new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'fas fa-suitcase', '/app/admin/roles/list'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'fas fa-users', '/app/admin/users/list'),
               new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
               // new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
               // new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'fas fa-eye', '/app/admin/ui-customization'),
                new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
                new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'flaticon-interface-8', '/app/admin/dynamic-property'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'fas fa-cog', '/app/admin/tenantSettings')
            ]),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
