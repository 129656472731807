<div [class]="footerStyle" id="kt_footer">
	<div [class]="containerClass + ' d-flex flex-column flex-md-row align-items-center justify-content-end'">
		<div class="text-dark order-2 order-md-1">
			<span class="text-muted font-weight-bold mr-2">
				<!--Bit2<b>EHR</b> <span *ngIf="appSession.tenant && appSession.tenant.edition && appSession.tenant.edition.displayName">-->
				<!--<span>{{appSession.tenant.edition.displayName}}</span>-->
				<!--| API: v{{appSession.application.version}} | Client: v{{webAppGuiVersion}}-->
				<!--[{{releaseDate}}]-->
				Powered By <a href="https://zkarehealth.com" target="_blank"><img src="../../../assets/z-kare-logo.png" class="mt-n2 ml-1" style="height:23px" /></a>
			</span>
		</div>
		<div class="nav nav-dark nav nav-dark order-1 order-md-2">
            <a href="javascript:;" target="_blank" class="nav-link pl-0 pr-5">&nbsp;</a>
		</div>
	</div>
</div>
