<div>
    <form #myForm="ngForm">
        <div class="card card-custom">
            <div class="card-body">
                <div class="flex-column flex-grow-1">
                    <div class="row">
                        <div class="form-group col-md-6 col-sm-6 col-xs-6">
                            <label>Practice Group Name**</label>
                            <input required #practiceGroupName="ngModel" [class.is-invalid]="practiceGroupName.invalid && practiceGroupName.touched"
                                   type="text" id="PracticeGroupMaster_PracticeGroupName" class="form-control"
                                   [(ngModel)]="practiceGroupMaster.practiceGroupName" name="practiceGroupName" minlength="3" maxlength="500" placeholder="Group Name">
                            <small class="text-danger" [class.d-none]="practiceGroupName.valid || practiceGroupName.untouched">Group Name required</small>
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-6">
                            <label>Address**</label>
                            <input required #address="ngModel" [class.is-invalid]="address.invalid && address.touched"
                                   type="text" id="address" class="form-control"
                                   [(ngModel)]="practiceGroupMaster.address" name="address" minlength="0" maxlength="500" placeholder="Address">
                            <small class="text-danger" [class.d-none]="address.valid || address.untouched">Address is required</small>
                        </div>

                        <div class="form-group col-md-3 col-sm-6 col-xs-6">
                            <label>City**</label>
                            <input required #practiceGroupCity="ngModel" [class.is-invalid]="practiceGroupCity.invalid && practiceGroupCity.touched"
                                   type="text" id="practiceGroupCity" class="form-control"
                                   [(ngModel)]="practiceGroupMaster.city" name="practiceGroupCity" minlength="0" maxlength="20" placeholder="City">
                            <small class="text-danger" [class.d-none]="practiceGroupCity.valid || practiceGroupCity.untouched">City is required</small>
                        </div>

                        <div class="form-group col-md-3 col-sm-6 col-xs-6">
                            <label for="Site_State">State</label>
                            <select class="form-control" id="Site_State" [(ngModel)]="practiceGroupMaster.state"
                                    name="State">
                                <option value="1">{{('Alabama')}}</option>
                                <option value="2">{{('Alaska')}}</option>
                                <option value="3">{{('Arizona')}}</option>
                                <option value="4">{{('Arkansas')}}</option>
                                <option value="5">{{('California')}}</option>
                                <option value="6">{{('Colorado')}}</option>
                                <option value="7">{{('Connecticut')}}</option>
                                <option value="8">{{('Delaware')}}</option>
                                <option value="51">{{('District Of Columbia')}}</option>
                                <option value="9">{{('Florida')}}</option>
                                <option value="10">{{('Georgia')}}</option>
                                <option value="11">{{('Hawaii')}}</option>
                                <option value="12">{{('Idaho')}}</option>
                                <option value="13">{{('Illinois')}}</option>
                                <option value="14">{{('Indiana')}}</option>
                                <option value="15">{{('Iowa')}}</option>
                                <option value="16">{{('Kansas')}}</option>
                                <option value="17">{{('Kentucky')}}</option>
                                <option value="18">{{('Louisiana')}}</option>
                                <option value="19">{{('Maine')}}</option>
                                <option value="20">{{('Maryland')}}</option>
                                <option value="21">{{('Massachusetts')}}</option>
                                <option value="22">{{('Michigan')}}</option>
                                <option value="23">{{('Minnesota')}}</option>
                                <option value="24">{{('Mississippi')}}</option>
                                <option value="25">{{('Missouri')}}</option>
                                <option value="26">{{('Montana')}}</option>
                                <option value="27">{{('Nebraska')}}</option>
                                <option value="28">{{('Nevada')}}</option>
                                <option value="29">{{('New Hampshire')}}</option>
                                <option value="30">{{('New Jersey')}}</option>
                                <option value="31">{{('New Mexico')}}</option>
                                <option value="32">{{('New York')}}</option>
                                <option value="33">{{('North Carolina')}}</option>
                                <option value="34">{{('North Dakota')}}</option>
                                <option value="35">{{('Ohio')}}</option>
                                <option value="36">{{('Oklahoma')}}</option>
                                <option value="37">{{('Oregon')}}</option>
                                <option value="38">{{('Pennsylvania')}}</option>
                                <option value="39">{{('Rhode Island')}}</option>
                                <option value="40">{{('South Carolina')}}</option>
                                <option value="41">{{('South Dakota')}}</option>
                                <option value="42">{{('Tennessee')}}</option>
                                <option value="43">{{('Texas')}}</option>
                                <option value="44">{{('Utah')}}</option>
                                <option value="45">{{('Vermont')}}</option>
                                <option value="46">{{('Virginia')}}</option>
                                <option value="47">{{('Washington')}}</option>
                                <option value="48">{{('West Virginia')}}</option>
                                <option value="49">{{('Wisconsin')}}</option>
                                <option value="50">{{('Wyoming')}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3 col-sm-4 col-xs-6">
                            <label>Zip</label>
                            <input #zipCode="ngModel" [class.is-invalid]="zipCode.invalid && zipCode.touched"
                                   type="text" maxlength="10" pattern="^[0-9]{5}(?:-[0-9]{4})?$" class="form-control" placeholder="Zip Code" [(ngModel)]="practiceGroupMaster.zipCode" id="zipCode" name="zipCode">

                            <small class="text-danger" [class.d-none]="zipCode.valid || zipCode.untouched">Invalid zipcode</small>
                        </div>

                        <div class="form-group col-md-3 col-sm-4 col-xs-6">
                            <label>Phone</label>
                            <input type="tel" id="phone" class="form-control"
                                   [(ngModel)]="practiceGroupMaster.phone" name="Phone" minlength="0" maxlength="20" placeholder="Contact Phone" [textMask]="{mask: Phonemask}"
                                   [pattern]="phonePattern">
                        </div>

                        <div class="form-group col-md-3 col-sm-4 col-xs-6">
                            <label>Fax</label>
                            <input type="tel" id="PracticeGroupMaster_Fax" class="form-control"
                                   [(ngModel)]="practiceGroupMaster.fax" name="Fax" minlength="0" maxlength="50" placeholder="Fax" [textMask]="{mask: faxmask}" [pattern]="phonePattern">
                        </div>

                        <div class="form-group col-md-3 col-sm-4 col-xs-6">
                            <label>Email</label>
                            <input  #email="ngModel" [class.is-invalid]="email.invalid && email.touched"
                                   type="email" id="email" class="form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                   [(ngModel)]="practiceGroupMaster.email" name="email" minlength="0" maxlength="50" placeholder="Contact Email">
                            <small class="text-danger" [class.d-none]="email.valid || email.untouched">Invalid Email</small>

                        </div>
                    </div>
                </div>
            </div>
        </div>
  </form>
  <div class="card card-custom">
            <div class="card-footer">
                <div class="row justify-content-end">
                    <button type="button" class="btn btn-default btn-outline-secondary mr-2" (click)="saveOrClose(false)"><i class="fas fa-times text-danger pr-2"></i>Close</button>
                    <button [disabled]="myForm.form.invalid"
                            type="button" class="btn btn-primary btn-outline-primary text-hover-white mr-2" (click)="saveOrClose(true)">
                            <i class="far fa-save text-primary pr-2"></i>Save
                    </button>
                    <button [disabled]="myForm.form.invalid"
                            type="submit" class="btn btn-primary btn-shadow-custom" (click)="saveAndClose()">
                            <i class="fas fa-save pr-2"></i> Save &amp; Close
                    </button>
                </div>
            </div>
        </div>
</div>
        
