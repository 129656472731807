
import { AfterViewInit, Component, Injector, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { TenantDashboardServiceProxy, CommonServiceProxy, ProgramDepartmentDto, CreateOrEditPatientsDto, ThemeSettingsDto, EMarsServiceProxy, EmarCalenderViewDto } from '@shared/service-proxies/service-proxies';
import { curveBasis } from 'd3-shape';

import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { Stats } from 'fs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';


@Component({ 
    selector: 'e-mar-log',
    templateUrl: './e-mar-log.component.html',
    styleUrls: ['./e-mar-log.component.css']
})
export class EmarLogComponent extends AppComponentBase {
    constructor(injector: Injector, private _router: Router, private _activatedRoute: ActivatedRoute, private _EMarsServiceProxy: EMarsServiceProxy,) {
        super(injector);
    }
    public demographics: any;
    lastDayofMonth:number;    
    scheduleTimes:any;
    input = [];
    clientMedicationId;
    eMRCalender : any;
    weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    dayArray = [];   
    i=0;
    isLoading=false;
    emarCalenderData: EmarCalenderViewDto = new EmarCalenderViewDto(); 
    startDate:string
    scheduleType
    addNewMedication(): void {
        this._router.navigate(['/app/medications/add/1/0']);
    }
    ngOnInit(): void {
        this.getDate();
           
    }

    getDate()
    {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDate();
        this.lastDayofMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        for (var i = 1; i <=  this.lastDayofMonth; i += 1) {
            this.input.push(i);   
            var d = new Date(date.getFullYear(), date.getMonth(), i);
            var dayName = this.weekday[d.getDay()];  
            this.dayArray.push(dayName);                  
        }  
    }

    getEmrHistoryData(moreClicked,clientMedicationId)
    {
        this.isLoading=true;
        if(moreClicked)
        {   
        this.clientMedicationId = clientMedicationId;
        this._EMarsServiceProxy.getEMARHistoryForCalender(this.clientMedicationId).subscribe(result => {
            this.emarCalenderData = result; 
            this.isLoading=false;  
            this.startDate = this.emarCalenderData.scheduleStartDate;
            this.scheduleType = this.emarCalenderData.scheduleType;
            
     });             
    }
    }

    //etclass(item2) {

    //    if (item2.status == 'Administered' && item2.medicationErrorId > 0)
    //        return 'bg-danger text-white';
    //    else if()
    //    (item2.status == 'Administered' ? item2.medicationErrorId > 0) ? 'bg-danger text-white' :
    //        'bg-success text-white' : item2.status == 'Omitted' ? item2.holdScheduleMedicationId != null ? 'bg-warning text-white' :
    //            'bg-info text-white' : item2.missingMed == false ? item2.missingMed == true ? 'bg-white' : 'bg-danger' : 'bg-white
    //}
    calenderClicked(data)
    {

    }
  
}
