<div bsModal #addOmissionModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="addOmissionModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #addOmissionForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header border-0 d-flex">
                    <div>
                        <h4 class="modal-title text-primary font-weight-bolder">
                            {{patientsName}}
                        </h4>

                        <h4 class="modal-title font-weight-bolder">
                            {{medicationName}}
                        </h4>
                    </div>
                    <button type="button" (click)="close()" class="close mt-n10" [disabled]="saving" title="Close">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body mt-n7">
                    <tabset>
                        <tab>
                            <ng-template tabHeading>
                                <span><i class="fas fa-unlink pr-2"></i>New Omission</span>
                            </ng-template>
                            <div class="row card card-custom">
                                <div class="card-body pt-5 pb-0">
                                    <div class="row">
                                        <div class="form-group col-md-12 col-sm-6">
                                            <label>Omission Reason</label>
                                            <div class="radio-inline">
                                                <label
                                                    class="btn btn-outline-secondary radio radio-outline radio-success">
                                                    <input type="radio" id="HoldReason"
                                                        [(ngModel)]="ommision.omissionReason" value="1" name="radios15">
                                                    <span></span>Hold
                                                </label>
                                                <label class="btn btn-outline-secondary radio radio-outline radio-info">
                                                    <input type="radio" id="IndividualReason"
                                                        [(ngModel)]="ommision.omissionReason" value="2" name="radios15"
                                                        checked="checked">
                                                    <span></span>Individual Refused
                                                </label>
                                                <label
                                                    class="btn btn-outline-secondary radio radio-outline radio-primary">
                                                    <input type="radio" id="PhysicallyReason"
                                                        [(ngModel)]="ommision.omissionReason" value="3" name="radios15">
                                                    <span></span>Physically Not Able
                                                </label>

                                                <label
                                                    class="btn btn-outline-secondary radio radio-outline radio-warning">
                                                    <input type="radio" id="OtherReason"
                                                        [(ngModel)]="ommision.omissionReason" value="4" name="radios15">
                                                    <span></span>Other
                                                </label>

                                            </div>
                                            <span class="text-danger"
                                                *ngIf="(_required==true) && (ommision.omissionReason==null ||ommision.omissionReason==undefined )">Required</span>
                                        </div>
                                        <div class="form-group col-md-6 col-sm-6">
                                            <label>Nurse Notified?</label>
                                            <div class="d-flex align-items-center">
                                                <span class="input-group-hover input-group-text">
                                                    <label class="checkbox checkbox-danger checkbox-outline">
                                                        <input type="checkbox" id="nurseNotifie"
                                                            [(ngModel)]="ommision.nurseNotified" name="Checkboxes12"
                                                            (change)="nurseNotifiedChecked($event)">
                                                        <span></span>Yes
                                                    </label>
                                                </span>
                                                <div class="ml-1">
                                                    <!-- <input type="text" name="nurseNofified" [(ngModel)]="ommision.nurseNotifiedId" id="nurseNofified" class="form-control" [disabled]="ommision.nurseNotified!=true"
                                                                                                placeholder="Enter Nurse Name"> -->

                                                    <p-autoComplete [(ngModel)]="selectclientName"
                                                        class="fieldLengthCss " id="ClientInfo_Client"
                                                        [disabled]="ommision.nurseNotified!=true" [type]="'search'"
                                                        [suggestions]="clientData" (input)="searchClient($event)"
                                                        [minLength]="1" (onSelect)="onSelect($event)"
                                                        placeholder="Search Nurse..." inputStyleClass="form-control "
                                                        (ngModelChange)="onNurseNameChange($event)"
                                                        [style]="{'width':'100%'}" name="ClientInfo_Client">
                                                        <ng-template let-clients pTemplate="item">
                                                            <div
                                                                class="d-flex align-items-center mb-2 p-2 alternative_cls">
                                                                <div
                                                                    class="d-flex flex-column flex-grow-1 font-weight-bold">
                                                                    <a
                                                                        class="text-dark text-hover-primary mb-1 ">{{clients.userName}}</a>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </p-autoComplete>
                                                    <div *ngIf="ommision.nurseNotified && !ommision.nurseNotifiedId"
                                                        class="text-danger">
                                                        Please select a nurse.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-sm-12">
                                            <label>Comments</label>
                                            <textarea class="form-control" id="CommentsTextarea"
                                                [(ngModel)]="ommision.comment" name="CommentsTextarea" rows="2"
                                                placeholder="Enter your comments here"></textarea>
                                            <span class="text-danger"
                                                *ngIf="_required  && (ommision.comment==null)">*Required</span>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                <span><i class="fas fa-history pr-2"></i>History</span>
                            </ng-template>

                            <div class="row card card-custom pb-0">
                                <div class="card-body pt-3">
                                    <div class="row">
                                        <div class="form-group col-12">
                                            <table class="table table-hover cursor-pointer">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Date Time</th>
                                                        <th scope="col">Reason</th>
                                                        <th scope="col">Nurse Notified</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let oMiObj of ommionHistory">
                                                        <th scope="row">{{oMiObj.creationTime | date:'short'}}</th>
                                                        <td>{{oMiObj.omissionReason == 1? 'Hold': oMiObj.omissionReason
                                                            == 2 ? 'Individual Refused' : oMiObj.omissionReason == 3 ?
                                                            'Individual Refused' : 'Other' }}</td>
                                                        <td>{{oMiObj.nurseNotified== 'true'?'Yes':'No'}}</td>
                                                    </tr>
                                                    <!-- <tr>
                                                        <th scope="row">12/12/21 10:10 AM</th>
                                                        <td>Individual Refused</td>
                                                        <td>Yes</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">12/12/21 6:00 AM</th>
                                                        <td>Physically Not able</td>
                                                        <td>Yes</td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <div class="row d-flex flex-grow-1">
                        <div class="flex-column flex-grow-1 text-right">
                            <button [disabled]="saving" type="button"
                                class="btn btn-light-primary font-weight-bold mr-2" (click)="close()">
                                {{
                                "Cancel" |
                                localize
                                }}
                            </button>
                            <button type="submit" class="btn btn-primary font-weight-bold"
                                *ngIf="status!=medicationStatusEnum.Omitted" [disabled]="saving">
                                <i class="fas fa-check pr-2"></i>
                                <span>{{"Save" | localize}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>