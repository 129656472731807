import { Component, Injector, ViewEncapsulation, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as _ from 'lodash';
import * as moment from 'moment';
import { filter, finalize, map, toArray } from 'rxjs/operators';
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';
import { NotifyService } from 'abp-ng2-module';
import { LazyLoadEvent } from 'primeng/api';
import { Observable, range } from 'rxjs';
import { Location as backLocation } from '@angular/common';
import { id } from '@swimlane/ngx-charts';
import { OnInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PracticeGroupMastersServiceProxy, CreateOrEditPracticeGroupMasterDto } from '@shared/service-proxies/service-proxies';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'practice-group-add-update',
    templateUrl: './practice-group-add-update.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class PracticeGroupAddUpdateComponent extends AppComponentBase {

    @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() closeButton:boolean = false;
    @ViewChild("myForm") myForm: NgForm;
    active = false;
    saving = false;
    create = false;
    practiceGroupMaster: CreateOrEditPracticeGroupMasterDto = new CreateOrEditPracticeGroupMasterDto();
   
    _isOpenPHI1: boolean;
    _isOpenPHI2: boolean;
    _isOpenPHI3: boolean;
    _isOpenPHI4: boolean;
    practiceGroupId: any;
    private sub: any;
    phonePattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    public Phonemask: Array<string | RegExp>
    public faxmask: Array<string | RegExp>
    public zipmask: Array<string | RegExp>
    public mask: Array<string | RegExp>

    CreatedDate: Date;
    curDate = new Date();

    constructor(injector: Injector,
        private _practiceGroupMastersServiceProxy: PracticeGroupMastersServiceProxy, 
        private route: ActivatedRoute)
         {
        super(injector);

        this.sub = this.route.params.subscribe(params => {
            this.practiceGroupId = +params['medId'];
        });

        this.Phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        this.faxmask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        this.zipmask = [/\d/, /\d/, /\d/, /\d/, /\d/];
    }

    save(closeDialog:boolean): void {
        this._practiceGroupMastersServiceProxy.createOrEdit(this.practiceGroupMaster)
            .pipe(finalize(() => { }))
            .subscribe(result => {
                debugger;
                this.practiceGroupMaster.id = result;
                this.notify.info(this.l('Practice Group Saved Successfully'));
                if (closeDialog) {
                    this.buttonClicked.emit(this.practiceGroupMaster);
                }
                this.myForm.reset();
            });
    }

    close(showCofirmDialog): void {
        this.setcanDeactivate(true);
        if (showCofirmDialog) {
            this.message.confirm("Close Dialog", 'Are you sure you want to exit the form?',
                (isConfirmed) => {
                    if (isConfirmed) {
                        this.buttonClicked.emit(this.practiceGroupMaster);
                        this.myForm.reset();
                    }
                })
        } else {
            this.buttonClicked.emit(this.practiceGroupMaster);
            this.myForm.reset();
        }
    }
   saveOrClose(isSaved): void {
        if (isSaved) {
            this.save(false);
        }
        else {
            this.close(true);
        }
    }

    saveAndClose(): void {
        this.save(true);
    }
}
