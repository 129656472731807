<div>
    <form #myForm="ngForm">
        <div class="card card-custom">
            <div class="card-body">
                <div class="flex-column flex-grow-1">
                    <div class="row">
                        <div class="form-group col-md-3 col-sm-4 col-xs-6">
                            <label>Title**</label>
                            <select required #title="ngModel" [class.is-invalid]="title.invalid && title.touched"
                                    class="form-control" placeholder="Select Route" [(ngModel)]="providersMaster.provider_Title_Id" id="title" name="title">
                                <option>--select--</option>
                                <option *ngFor="let title of allProvider_Title_LookUps" value="{{title.id}}"> {{title.displayName}}</option>
                            </select>
                            <small class="text-danger" [class.d-none]="title.valid || title.untouched">Required</small>
                        </div>

                        <div class="form-group col-md-4 col-sm-4 col-xs-6">
                            <label>First Name**</label>
                            <input required #firstName="ngModel" [class.is-invalid]="firstName.invalid && firstName.touched"
                                type="text" class="form-control" placeholder="First Name" id="firstName" name="firstName" [(ngModel)]="providersMaster.firstName">
                                <small class="text-danger" [class.d-none]="firstName.valid || firstName.untouched">First Name is required</small>
                        </div>

                        <div class="form-group col-md-5 col-sm-4 col-xs-6">
                            <label>Last Name**</label>
                            <input required #lastName="ngModel" [class.is-invalid]="lastName.invalid && lastName.touched"
                                   type="text" class="form-control" placeholder="Last Name" id="lastName" name="lastName" [(ngModel)]="providersMaster.lastName">
                            <small class="text-danger" [class.d-none]="lastName.valid || lastName.untouched">Last Name is required</small>
                        </div>

                        <div class="form-group col-md-3 col-sm-4 col-xs-6">
                            <label>Association**</label> <!-- Role is renamed as association-->
                            <select required #role="ngModel" [class.is-invalid]="role.invalid && role.touched"
                                    class="form-control" placeholder="Select Strength Unit" [(ngModel)]="providersMaster.provider_Role_Id" id="role" name="role">
                                <option>--select--</option>
                                <option *ngFor="let role of allProvider_Role_LookUps" value="{{role.id}}"> {{role.displayName}}</option>
                            </select>
                            <small class="text-danger" [class.d-none]="role.valid || role.untouched">Required</small>
                        </div>

                        <div class="form-group col-md-4 col-sm-4 col-xs-6">
                            <label>Speciality</label>
                            <input type="text" class="form-control" placeholder="Speciality" [(ngModel)]="providersMaster.speciality" id="speciality" name="speciality">
                        </div>

                        <div class="form-group col-md-5 col-sm-4 col-xs-6">
                            <label>Practice Group</label>
                            <p-autoComplete [(ngModel)]="practiceGroupName" class="fieldLengthCss"
                                            id="practiceGroup" placeholder="Practice Group" [type]="'search'"
                                            [suggestions]="practiceGroupList" (input)="searchPracticeGroup($event)" [minLength]="1"
                                            (onSelect)="onUserSelectloc($event)" inputStyleClass="form-control "
                                            styleClass="w-100" name="practiceGroup">

                                <ng-template let-practiceGroups pTemplate="item">
                                    <div class="country-item">
                                        <div>{{practiceGroups.name}} </div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>

                        <div class="form-group col-md-3 col-sm-4 col-xs-6">
                            <label>Phone</label>
                            <input type="tel" class="form-control" placeholder="Contact Phone" [(ngModel)]="providersMaster.phone" id="phone" name="phone" [textMask]="{mask: Phonemask}" [pattern]="Pattern">
                        </div>

                        <div class="form-group col-md-3 col-sm-4 col-xs-6">
                            <label>Fax</label>
                            <input type="tel" class="form-control" placeholder="Fax" [(ngModel)]="providersMaster.fax" id="fax" name="fax" [textMask]="{mask: faxmask}" [pattern]="Pattern">
                        </div>

                        <div class="form-group col-md-3 col-sm-4 col-xs-6">
                            <label>Email</label>
                            <input  #email="ngModel" [class.is-invalid]="email.invalid && email.touched"
                                   pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                   type="email" class="form-control" placeholder="Contact Email" [(ngModel)]="providersMaster.email" id="email" name="email">
                                <small class="text-danger" [class.d-none]="email.valid || email.untouched">Invalid Email</small>
                        </div>

                        <div class="form-group col-md-3 col-sm-6 col-xs-6">
                            <label>Address</label>
                            <input type="text" class="form-control" placeholder="Address" [(ngModel)]="providersMaster.address" id="address" name="address">
                        </div>

                        <div class="form-group col-md-3 col-sm-6 col-xs-6">
                            <label>City**</label>
                            <input required #city="ngModel" [class.is-invalid]="city.invalid && city.touched"
                                   type="text" class="form-control" placeholder="City" [(ngModel)]="providersMaster.city" id="city" name="city">
                            <small class="text-danger" [class.d-none]="city.valid || city.untouched">City is required</small>
                        </div>

                        <div class="form-group col-md-3 col-sm-6 col-xs-6">
                            <label>State</label>
                            <select class="form-control" id="state" [(ngModel)]="providersMaster.state"
                                    name="State">
                                <option value="1">{{('Alabama')}}</option>
                                <option value="2">{{('Alaska')}}</option>
                                <option value="3">{{('Arizona')}}</option>
                                <option value="4">{{('Arkansas')}}</option>
                                <option value="5">{{('California')}}</option>
                                <option value="6">{{('Colorado')}}</option>
                                <option value="7">{{('Connecticut')}}</option>
                                <option value="8">{{('Delaware')}}</option>
                                <option value="51">{{('District Of Columbia')}}</option>
                                <option value="9">{{('Florida')}}</option>
                                <option value="10">{{('Georgia')}}</option>
                                <option value="11">{{('Hawaii')}}</option>
                                <option value="12">{{('Idaho')}}</option>
                                <option value="13">{{('Illinois')}}</option>
                                <option value="14">{{('Indiana')}}</option>
                                <option value="15">{{('Iowa')}}</option>
                                <option value="16">{{('Kansas')}}</option>
                                <option value="17">{{('Kentucky')}}</option>
                                <option value="18">{{('Louisiana')}}</option>
                                <option value="19">{{('Maine')}}</option>
                                <option value="20">{{('Maryland')}}</option>
                                <option value="21">{{('Massachusetts')}}</option>
                                <option value="22">{{('Michigan')}}</option>
                                <option value="23">{{('Minnesota')}}</option>
                                <option value="24">{{('Mississippi')}}</option>
                                <option value="25">{{('Missouri')}}</option>
                                <option value="26">{{('Montana')}}</option>
                                <option value="27">{{('Nebraska')}}</option>
                                <option value="28">{{('Nevada')}}</option>
                                <option value="29">{{('New Hampshire')}}</option>
                                <option value="30">{{('New Jersey')}}</option>
                                <option value="31">{{('New Mexico')}}</option>
                                <option value="32">{{('New York')}}</option>
                                <option value="33">{{('North Carolina')}}</option>
                                <option value="34">{{('North Dakota')}}</option>
                                <option value="35">{{('Ohio')}}</option>
                                <option value="36">{{('Oklahoma')}}</option>
                                <option value="37">{{('Oregon')}}</option>
                                <option value="38">{{('Pennsylvania')}}</option>
                                <option value="39">{{('Rhode Island')}}</option>
                                <option value="40">{{('South Carolina')}}</option>
                                <option value="41">{{('South Dakota')}}</option>
                                <option value="42">{{('Tennessee')}}</option>
                                <option value="43">{{('Texas')}}</option>
                                <option value="44">{{('Utah')}}</option>
                                <option value="45">{{('Vermont')}}</option>
                                <option value="46">{{('Virginia')}}</option>
                                <option value="47">{{('Washington')}}</option>
                                <option value="48">{{('West Virginia')}}</option>
                                <option value="49">{{('Wisconsin')}}</option>
                                <option value="50">{{('Wyoming')}}</option>
                            </select>
                        </div>

                        <div class="form-group col-md-3 col-sm-4 col-xs-6">
                            <label>Zip</label>
                            <input #zipCode="ngModel" [class.is-invalid]="zipCode.invalid && zipCode.touched"
                                   type="text" maxlength="10" pattern="^[0-9]{5}(?:-[0-9]{4})?$" class="form-control" placeholder="Zip Code" [(ngModel)]="providersMaster.zipCode" id="zipCode" name="zipCode">

                            <small class="text-danger" [class.d-none]="zipCode.valid || zipCode.untouched">Invalid zipcode</small>
                        </div>

                        <div class="form-group col-md-3 col-sm-4 col-xs-6">
                            <label>License Number</label>
                            <input type="tel" class="form-control" placeholder="License Number" [(ngModel)]="providersMaster.licenseNumber" id="liecnseNo" name="licenseNo">
                        </div>

                        <div class="form-group col-md-3 col-sm-4 col-xs-6">
                            <label>License Expiration Date</label>
                            <input type="datetime" [bsValue]="bsValue"
                                   [bsConfig]="{dateInputFormat: 'MM/DD/YYYY'}" bsDatepicker
                                   id="ProvidersMaster_LicenseExpirationDate" placeholder="License Expiration Date" name="ProvidersMaster_LicenseExpirationDate"
                                   class="form-control" [(ngModel)]="providersMaster.licenseExpirationDate">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="card card-custom">
        <div class="card-footer">
            <div class="row justify-content-end">
                <button type="button" class="btn btn-default btn-outline-secondary mr-2" (click)="saveOrClose(false)"><i class="fas fa-times text-danger pr-2"></i>Close</button>
                <button [disabled]="myForm.form.invalid"
                    type="button" class="btn btn-primary btn-outline-primary text-hover-white mr-2" (click)="saveOrClose(true)"><i class="far fa-save text-primary pr-2"></i>Save</button>
                <button [disabled]="myForm.form.invalid"
                        type="submit" class="btn btn-primary btn-shadow-custom" (click)="saveAndClose()">
                    <i class="fas fa-save pr-2"></i> Save &amp; Close
                </button>
            </div>
        </div>
    </div>
</div>
