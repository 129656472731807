import { AfterViewChecked, Component, ElementRef, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { outputs } from '@syncfusion/ej2-angular-navigations/src/sidebar/sidebar.component';
import { CreateOrEditClient_Medication_Schedule_TimeDto, CreateOrEditHoldMedicationDto, GetHoldMedicationForEditOutput, GetHoldScheduleTimeForEditOutput, HoldMedicationsServiceProxy, HoldScheduleTimesServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { HoldScheduleDialogComponent } from '../holdScheduleDialog/hold-schedule-dialog.component';


@Component({
    selector: 'holdMediModalDialog',
    styleUrls: ['./hold-Medi-Dialog.component.css'],
    templateUrl: './hold-medi-dialog.component.html'
    
})
export class HoldMediDialogComponent extends AppComponentBase {

    @ViewChild('holdMedModal', { static: true }) modal: ModalDirective;
    @ViewChild('holdMedForm', { static: true }) form: NgForm;
    @ViewChild('holdScheduleModalDialog',{static: true}) holdScheduleModalDialog:HoldScheduleDialogComponent;
    @Output() saveHoldMed: EventEmitter <any> = new EventEmitter<any> (); 

    active = false;
    saving = false;
    meddata:any;
    status:string;
    medicationId
    drugName:string;
    patientName:string;
    _required=false;
    medSchTime:GetHoldScheduleTimeForEditOutput[];
    _idx:number;
    medSchTempObj=new GetHoldScheduleTimeForEditOutput();
    holdSchHistory:GetHoldScheduleTimeForEditOutput[];
    holdMedication = new CreateOrEditHoldMedicationDto();
    constructor(injector: Injector,
        private _holdMedicationsServiceProxy: HoldMedicationsServiceProxy,
        private _holdScheduleTimeServiceProxy: HoldScheduleTimesServiceProxy) { 
        super(injector);
    }

    counter(i: number): any {
        return new Array(i);
    }

    show(clientMedicationId?: number): void {
        this.meddata
        this.drugName = this.meddata.drugDescription;
        this.patientName = this.meddata.patientsname;
        this.holdScheduleModalDialog.medicationId=clientMedicationId;
        this._holdScheduleTimeServiceProxy.getClient_ScheduleTime_Hold(clientMedicationId).subscribe(result => {
            this.medSchTime = result;
           })
           this._holdScheduleTimeServiceProxy.getClient_ScheduleHistory(clientMedicationId).subscribe(data => {
            this.holdSchHistory = data;
          
        })
        if(this.status=='Approved'){
          
            this.holdMedication = new CreateOrEditHoldMedicationDto();
            this.holdMedication.clientMedicationId = clientMedicationId;
            this.modal.show();
        }
        else{
            
            this._holdMedicationsServiceProxy.getHoldMedicationForEdit(clientMedicationId)
            .subscribe(result => {
                if (result.holdMedication) {
                    this.holdMedication = result.holdMedication;
                
                }
              
            })
        
          this.modal.show();
         
        }
      
    }
    holdSchdlDialog(holdschedul){
        this.holdScheduleModalDialog._mode ="holdOrRsm";
    this.holdScheduleModalDialog.viewResmBtn=true;
    this.holdScheduleModalDialog.status =holdschedul.holdScheduleTime.status
    this.holdScheduleModalDialog.show(holdschedul); 
   }
   editHoldSchdl(holdschedul){
    this.holdScheduleModalDialog._mode ="edit";
    this.holdScheduleModalDialog.viewResmBtn=false;
    this.holdScheduleModalDialog.status =holdschedul.holdScheduleTime.status
    this.holdScheduleModalDialog.show(holdschedul); 
   }
   AddHold(holdScheduleTime){
    this.holdScheduleModalDialog._mode ="add";
    this.holdScheduleModalDialog.status = holdScheduleTime.status
    this.holdScheduleModalDialog.show(holdScheduleTime);
   }
   View(holdScheduleTime){
    this.holdScheduleModalDialog._mode ="view";
    this.holdScheduleModalDialog.status = holdScheduleTime.status
    this.holdScheduleModalDialog.show(holdScheduleTime);
   }

    onShown(): void {
        
        
    }
   

    save(): void {
       
        if (this.holdMedication.comment == " " || this.holdMedication.comment == undefined||this.holdMedication.holdFrom == null || this.holdMedication.holdFrom == undefined ||this.holdMedication.resumeForm == null || this.holdMedication.resumeForm == undefined) {
            this._required = true;
            
        }
        else{
        // this.holdMedication.holdFrom = (this.holdMedication.holdFrom);
        // this.holdMedication.resumeForm = (this.holdMedication.resumeForm);
       // this.holdMedication.holdSchedualTimeId = this.medSchTime
        this.holdMedication.resumeFromScheduleTimeId
        this._holdMedicationsServiceProxy.createOrEdit(this.holdMedication)
        .subscribe(result => {
            this.close();
            this.notify.info(this.l('SavedSuccessfully'));
            this.saveHoldMed.emit(this.holdMedication.clientMedicationId);
        });
    }
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    getHoldSchedulMed(medicationId){
      this.show(medicationId);
    }

   
}
