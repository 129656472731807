import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { outputs } from '@syncfusion/ej2-angular-navigations/src/sidebar/sidebar.component';
import { CreateOrEditMedicationErrorDto, CreateOrEditProgramEnrollmentDto, EMarsServiceProxy, GetEMarForViewDto, MedicationAdminScheduleDto, MedicationErrorsServiceProxy, MedicationStatusEnum } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { forkJoin } from '@node_modules/rxjs';
@Component({
    selector: 'add-error-dialog',
    templateUrl: './add-error-dialog.component.html',
    styleUrls: ['add-error-dialog.component.css']
})
export class AddErrorDialogComponent extends AppComponentBase {

    @ViewChild('addErrorModal', { static: true }) modal: ModalDirective;
    @ViewChild('addErrorForm', { static: true }) form: NgForm;
    @Output() saveError: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    date: Date;
    patientsName: string;
    medicationScheduleId
    medicationName: string;
    clientMedicationId;
    houseNurseName: CreateOrEditProgramEnrollmentDto;
    patientId;
    patientEMr = new GetEMarForViewDto();
    medicationError = new CreateOrEditMedicationErrorDto();
    errorHistory
    _required: boolean = false;
    clientData: any[] = [];
    selectclientName: string;
    medicationStatusEnum = MedicationStatusEnum;
    medicationschedule: MedicationAdminScheduleDto = new MedicationAdminScheduleDto();
    constructor(injector: Injector,
        private _medicationErrorsServiceProxy: MedicationErrorsServiceProxy, private _EMarsServiceProxy: EMarsServiceProxy, private cd: ChangeDetectorRef) {
        super(injector);
    }

    counter(i: number): any {
        return new Array(i);
    }


    show(emar: MedicationAdminScheduleDto): void {
        if (emar) {
            this.medicationError = new CreateOrEditMedicationErrorDto();
            this.medicationschedule = emar;
            this.patientsName = emar.clientname;
            this.medicationScheduleId = emar.scheduleId;
            this.clientMedicationId = emar.medicationId;
            this.patientId = emar.clientId;
            this.medicationName = emar.drugDescription;
            this.modal.show();
            this.medicationError.medicationScheduleTimeId = emar.scheduleTimeId;
            this.medicationError.clientMedicationId = emar.medicationId;
            this.medicationError.medicationAdminScheduleId = emar.medicationAdminScheduleId;
            this.medicationError.patientId = emar.clientId;
            this.medicationError.drugDescription = emar.drugDescription;
            this.medicationError.patientName = emar.clientname;
            this.medicationError.errorAddedBy = this.appSession.userId.toString();
            let CurrentDate = new Date();
            this.medicationError.errorAddedOn = CurrentDate.toString();
            this.onShown();
        }
    }

    nurseNotifiedChecked(event: any) {
        if (event.currentTarget.checked) {
            this.selectclientName = this.houseNurseName.houseNurse;
            this.medicationError.nurseNotifiedId = this.houseNurseName.houseNurseId.toString();
        }
        else {
            this.selectclientName = null;
            this.medicationError.nurseNotifiedId = null;

        }

    }

    onShown(): void {
        var sDate = new Date();

        forkJoin([
            this._medicationErrorsServiceProxy.getMedicationErrorForEdit(this.medicationschedule.medicationAdminScheduleId),
            this._medicationErrorsServiceProxy.getMedicationErrorHistory(this.clientMedicationId, this.patientId)
        ]).subscribe(
            ([medicationErrorResult, medicationErrorHistoryResult]) => {

                if (medicationErrorResult.medicationError) {
                    this.medicationError = medicationErrorResult.medicationError;
                    this.selectclientName = this.medicationError.nurseName;
                }

                this.errorHistory = medicationErrorHistoryResult;
            },
            (error) => {
                console.error('Error occurred while fetching medication error data:', error);
            }
        );
    }

    save(): void {
        if (this.isformValid()) {
            this.medicationError.scheduleDate = moment(this.date);
            this._medicationErrorsServiceProxy.createOrEdit(this.medicationError)
                .subscribe(result => {
                    this.saveError.emit();
                    this.close();
                    // this.notify.info(this.l('SavedSuccessfully'));
                    this.notify.info(this.l('Error recorded successfully.'));
                });
        }

    }
    isformValid(): boolean {
        if (this.medicationError.nurseNotified && !this.medicationError.nurseNotifiedId) {
            this.message.error("Please select a nurse.");
            this._required = true;
            return false;
        }

        if (this.medicationError.careTeamNotified && !this.medicationError.careTeamId) {
            this.message.error("Please enter a care team group name.");
            this._required = true;
            return false;
        }

        if (!this.medicationError.errorType || !this.medicationError.comment) {
            this.message.error("Please fill the required fields.");
            this._required = true;
            return false;
        }

        this._required = false;
        return true;
    }
    onNurseNameChange(value: string) {
        if (!value) {
            this.selectclientName = null; // Set to null when cleared
            this.medicationError.nurseNotifiedId = null; // Set to null when cleared
        }
    }

    clear() {
        this._required = false;
        this.medicationError = new CreateOrEditMedicationErrorDto();
    }

    close(): void {
        this.clear();
        this.active = false;
        this.modal.hide();
    }
    searchClient(event: any) {

        var trimmed = this.selectclientName.trim();
        if (trimmed.length == 0) {
            this.clientData = null;
            this.clientData = [];
            this.cd.detectChanges();
        }
        let wordSearch = this.selectclientName.trim();
        setTimeout(() => {
            if (wordSearch == this.selectclientName.trim()) {
                if (this.selectclientName.trim()) {
                    this._EMarsServiceProxy.getassociatedHouseNurse(wordSearch, this.patientId)
                        .pipe(finalize(() => { }))
                        .subscribe(data => {
                            this.clientData = data;
                            if (data.length == 0)
                                this.selectclientName = null;
                            this.cd.detectChanges();
                        })
                } else {
                    this.selectclientName = null;
                }
            }
        }, 2000);
    }

    onSelect(event) {
        //  this.PatientId = patientId;
        // this.addmedicationheader.recordid = this.PatientId;
        let data = event
        this.selectclientName = data.userName;
        this.medicationError.nurseNotifiedId = data.userId;
    }

}
