export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Bit2EHR'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly grid = {
        defaultPageSize: 10
    };

    static readonly MinimumUpgradePaymentAmount = 1;

    /// <summary>
    /// Gets current version of the application.
    /// It's also shown in the web page.
    /// </summary>
    static readonly WebAppGuiVersion = '9.3.0';

    static readonly FormId = {
        Client: 1,
        NursingAssessment: 2,
        NCP: 3,
        NursingDiary:13,
        BehaviorLogs: 4,
        Bowel: 5,
        DailyLogs: 6,
        Family: 7,
        GoalLogs: 8,
        Seizure: 9,
        Temperature: 10,
        Transportation: 11,
        PCP: 12,
        Incident: 14,
        NursingLogs: 15,
        facilityreport: 16,
        firedrill: 17,
        HealthLogs: 18,
        BloodSugarLogs: 19,
        DisinfectLog:20

    };

    static readonly FormName = {
        1: "Client Profile",
        2: "Nursing Assessment",
        3: "Nursing Care Plan",

        4: "Behavior Logs",
        5: "Bowel Movements",
        6: "Daily Logs/Attendance",
        7: "Family/Team Member Logs",
        8: "Goal/Outcome Logs",
        9: "Seizure Logs",
        10: "Temperature Logs",
        11: "Transportation Logs",
        12: "PCP/BP",
        13: "Nursing Dairy",
         14: "Incident Report"
    };
}
