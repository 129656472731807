import { Injector, Component, OnInit, Inject, EventEmitter, Output, ViewChild } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { DOCUMENT } from '@angular/common';
import { OffcanvasOptions } from '@metronic/app/core/_base/layout/directives/offcanvas.directive';
import { AppConsts } from '@shared/AppConsts';
import { ToggleOptions } from '@metronic/app/core/_base/layout/directives/toggle.directive';
import { MyPinsComponent } from '../../nav/mypins/mypins.component';
import { Router } from '@angular/router';
import { AddNewComponent } from '../../nav/add-new/add-new.component';
import { MyClientsComponent } from '../../nav/my-clients/my-clients.compoent';


@Component({
    templateUrl: './default-layout.component.html',
    selector: 'default-layout',
    animations: [appModuleAnimation()]
})
export class DefaultLayoutComponent extends ThemesLayoutBaseComponent implements OnInit {

    @ViewChild('mypins', { static: true }) myPinsComponent: MyPinsComponent;

    @Output() onAddNewClick = new EventEmitter();
    @Output() onMyClientsClick = new EventEmitter();
    @Output() onMyPinsClick = new EventEmitter();

    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.currentTheme.baseSettings.menu.asideSkin + '.svg';
   
    showLogo = false;
    menuCanvasOptions: OffcanvasOptions = {
        baseClass: 'aside',
        overlay: true,
        closeBy: 'kt_aside_close_btn',
        toggleBy: 'kt_aside_mobile_toggle'
    };
    //aside-menu scroll ps ps--active-y
    userMenuToggleOptions: ToggleOptions = {
        target: this.document.body,
        targetState: 'topbar-mobile-on',
        toggleState: 'active'
    };

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        private _router: Router,
    ) {
        super(injector);
        if (document.body.classList.contains('aside-minimize')) {
            this.showLogo = true;
        }       
    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    }

    logoemit() {
        if (document.body.classList.contains('aside-minimize')) {
            this.showLogo = true;
        }
        else
            this.showLogo =false;
    }

    adNew() {
        this.onAddNewClick.emit();
    }

    mypin()
    {
        this.onMyPinsClick.emit();
    }

    newclient(){
        this.onMyClientsClick.emit();
    }
}
