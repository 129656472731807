import { Component, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ClientDashboardDto, ClientDashboardServiceProxy, PatientsEditServiceProxy, Pin, PinnedDashboardServiceProxy } from "@shared/service-proxies/service-proxies";
import { finalize } from "rxjs/operators";

@Component({
    selector: 'mypins',
    templateUrl: './mypins.component.html'
})
export class MyPinsComponent extends AppComponentBase {
    public displayFavPane: boolean;
    getAllPinnedData: Pin[] = new Array();
    filteredData: Pin[] = new Array();
    clientName: any;
    _clientNotFound: boolean = false;
    myclientsall: ClientDashboardDto[];

    myObj = [{ "id": 1, "formname": "Client Profile" }, { "id": 2, "formname": "Nursing Assessment" },
    { "id": 3, "formname": "'Nursing Care Plan'" }, { "id": 4, "formname": "Behavior Logs" },
    { "id": 5, "formname": "Bowel Movements" }, { "id": 6, "formname": "Daily Logs" }, { "id": 7, "formname": "Family/Team Member Logs" }, { "id": 8, "formname": "Goal/Outcome Logs" },

    { "id": 9, "formname": "Seizure Logs" }, { "id": 10, "formname": "Temperature Logs" }, { "id": 11, "formname": "Transportation Logs" }, { "id": 12, "formname": "PCP/BP" },
    { "id": 13, "formname": "Nursing Diary" }, { "id": 14, "formname": "Incident Report" }, { "id": 15, "formname": "Nursing Logs" }, { "id": 16, "formname": "Facility Report" }, { "id": 17, "formname": "Fire Drill" },

    { "id": 20, "formname": "Disinfect Logs" }, { "id": 19, "formname": "BloodSugar Logs" }, { "id": 18, "formname": "Health Logs" },


    ];

    constructor(
        injector: Injector,
        private _pinnedDataService: PinnedDashboardServiceProxy,
        private _router: Router,
        private _clientDashboardProxy: ClientDashboardServiceProxy,
        private _patientsEditServiceProxy: PatientsEditServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.getPinnedData();
        this._clientDashboardProxy.getClients()
            .pipe(finalize(() => { }))
            .subscribe(result => {
             
            this.myclientsall = [];
            this.myclientsall = result;      
        });
    }
    getPinnedData() {
       
        this._pinnedDataService.getPinnnedDataFromSettings().subscribe(data => {

            this.getAllPinnedData = data;
            //this.getclientpic();

            this.filteredData = this.getAllPinnedData;
            if (this.getAllPinnedData) {
                if (this.getAllPinnedData.length == 0) {
                    this._clientNotFound = true;

                }
                else {
                    this._clientNotFound = false;
                }
            }
            for (let formpermissions of this.getAllPinnedData) {
                if (formpermissions.formId == 17 && !this.permission.isGranted('Pages.FireDrills.Edit') && !this.permission.isGranted('Pages.FireDrills.View')) {
                    formpermissions.isGranted = true;
                    formpermissions.formInitials = "FD";
                    formpermissions.clientPresent = true;
                }
                else if (formpermissions.formId == 17 && this.permission.isGranted('Pages.FireDrills.Edit')) {

                    formpermissions.formInitials = "FD";
                    formpermissions.clientPresent = true;
                }
                else {
                    // formpermissions.isGranted = false;

                }

                if (formpermissions.formId == 16 && !this.permission.isGranted('Pages.FacilityReports.Edit') && !this.permission.isGranted('Pages.FacilityReports.View')) {
                    formpermissions.isGranted = true;
                    formpermissions.formInitials = "FR";
                    formpermissions.clientPresent = true;
                }
                else if (formpermissions.formId == 16 && this.permission.isGranted('Pages.FacilityReports.Edit')) {

                    formpermissions.formInitials = "FR";
                    formpermissions.clientPresent = true;
                }
                else {
                    // formpermissions.isGranted = false;

                }
            }


        });
    }

    getformicon(formid) {
        if (formid == 1)
            return 'fas fa-user-tie';
        if (formid == 2)
            return 'la la-edit';
        if (formid == 3)
            return 'fa fa-street-view';
        if (formid == 4)
            return ' fab fa-blogger-b';
        if (formid == 5)
            return 'fas fa-poo';
        if (formid == 6)
            return 'flaticon2-box-1';
        if (formid == 7)
            return 'fas fa-users';
        if (formid == 8)
            return 'fas fa-bullseye';
        if (formid == 9)
            return 'fa fa-headphones';
        if (formid == 10)
            return 'fas fa-temperature-high';
        if (formid == 11)
            return 'fas fa-bus-alt';
        if (formid == 12)
            return 'fa fa-life-ring';
        if (formid == 14)
            return 'fa fa-exclamation-triangle';
        if (formid == 13)
            return 'flaticon-interface-3';
        if (formid == 15)
            return 'fas fa-user-nurse';
        if (formid == 17)
            return 'fa fa-fire-extinguisher';
        if (formid == 16)
            return 'fa fa-building';
        if (formid == 20)
            return 'fas fa-broom'
        if (formid == 19)
            return 'fas fa-bong'
        if (formid == 18)
            return 'fas fa-h-square'

    }
    getFormname(formid) {
        if (formid == 1)
            return 'Client Profile';
        if (formid == 2)
            return 'Nursing Assessment';
        if (formid == 3)
            return 'Nursing Care Plan';
        if (formid == 4)
            return 'Behavior Logs';
        if (formid == 5)
            return 'Bowel Movements';
        if (formid == 6)
            return 'Daily Logs';
        if (formid == 7)
            return 'Family/Team Member Logs';
        if (formid == 8)
            return 'Goal/Outcome Logs';
        if (formid == 9)
            return 'Seizure Logs';
        if (formid == 10)
            return 'Temperature Logs';
        if (formid == 11)
            return 'Transportation Logs';
        if (formid == 12)
            return 'PCP/BP';
        //if (formid == 13)
        //    return 'Nursing Diary';
        if (formid == 14)
            return 'Incident Report';
        if (formid == 15)
            return 'Nursing Logs';
        if (formid == 17)
            return 'Fire Drill';
        if (formid == 16)
            return 'Facility Report';
        if (formid == 20)
            return 'Disinfect Logs';
        if (formid == 19)
            return 'BloodSugar Logs';
        if (formid == 18)
            return 'Health Logs';
    }
    getPinnedPicture(formid) {
        if (formid != 16 && formid != 17 && formid != 20) {
            return '../../../../assets/metronic/noPicMale.png';
        }
        if (formid == 20)
            return '../../../../assets/metronic/broom-icon.png';
        if (formid == 17)
            return '../../../../assets/metronic/download.jpg';
        if (formid == 16) {
            return '../../../../assets/metronic/facilities-icon-5.jpg';
        }
    }
    getclientpic() {
        console.log(this.getAllPinnedData);        
           
        this._patientsEditServiceProxy.getPatientsImage(this.getAllPinnedData).subscribe(result => {
            this.getAllPinnedData = result;
        });           

    }
    unpin(data: Pin) {

        if (data.entityId) {
            var getPinnedIndex = this.getAllPinnedData.findIndex(s => s.entityId == data.entityId && s.formId == data.formId && s.isPinned);
            if (getPinnedIndex > -1) {
                this.getAllPinnedData.splice(getPinnedIndex, 1);
                this._pinnedDataService.savePinSetting(this.getAllPinnedData).subscribe(() => {
                    this.filteredData = this.getAllPinnedData;
                    if (this.getAllPinnedData) {
                        if (this.getAllPinnedData.length == 0) {
                            this._clientNotFound = true;

                        }
                        else {
                            this._clientNotFound = false;
                        }
                    }
                    this.notify.success(this.l('Unpinned Successfully'));
                });
            }
        }
    }
    navigateTo(data: Pin) {

        var FormId = data.formId;
        switch (FormId) {
            case 1:
                if (!this.permission.isGranted('Pages.Patients')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.Patients.Edit') && !this.permission.isGranted('Pages.Patients.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/client/patients/create-or-edit-patients-modal', data.entityId, "pinedit"]);
                break;
            case 2:
                if (!this.permission.isGranted('Pages.NursingAssessments')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.NursingAssessments.Edit') && !this.permission.isGranted('Pages.NursingAssessments.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/assessment/nursingAssessments/create-or-edit-nursingAssessment-modal', data.entityId, "pinedit", 0]);
                break;
            case 3:
                if (!this.permission.isGranted('Pages.NursingCarePlans')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.NursingCarePlans.Edit') && !this.permission.isGranted('Pages.NursingCarePlans.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/nursing/nursingCarePlan/create-or-edit-nursingCarePlan-modal', data.entityId, 0, "pinedit", 0]);
                break;
            case 4:
                if (!this.permission.isGranted('Pages.BehaviorLogs')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.BehaviorLogs.Edit') && !this.permission.isGranted('Pages.BehaviorLogs.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/notes/behaviorLogs/createOrEdit', data.entityId, "pinedit", 0]);
                break;
            case 5:
                if (!this.permission.isGranted('Pages.BowelMovements')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.BowelMovements.Edit') && !this.permission.isGranted('Pages.BowelMovements.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/notes/bowelMovements/createOrEdit', data.entityId, "pinedit", 0]);
                break;
            case 6:
                if (!this.permission.isGranted('Pages.DailyLogs')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.DailyLogs.Edit') && !this.permission.isGranted('Pages.DailyLogs.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/notes/dailyLogs/createOrEdit', data.entityId, "pinedit", 0]);
                break;
            case 7:
                if (!this.permission.isGranted('Pages.FamilyMemberLogs')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.FamilyMemberLogs.Edit') && !this.permission.isGranted('Pages.FamilyMemberLogs.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/notes/familyMemberLogs/createOrEdit', data.entityId, "pinedit", 0]);
                break;
            case 8:
                if (!this.permission.isGranted('Pages.GoalLogs')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.GoalLogs.Edit') && !this.permission.isGranted('Pages.GoalLogs.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/notes/goalLogs/createOrEdit', data.entityId, "pinedit", 0]);
                break;
            case 9:
                if (!this.permission.isGranted('Pages.SeizureLogs')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.SeizureLogs.Edit') && !this.permission.isGranted('Pages.SeizureLogs.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/notes/seizureLogs/create-or-edit-seizureLog-modal', data.entityId, "pinedit", 0]);
                break;
            case 10:
                if (!this.permission.isGranted('Pages.TemparatureLogs')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.TemparatureLogs.Edit') && !this.permission.isGranted('Pages.TemparatureLogs.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/notes/temparatureLogs/createOrEdit', data.entityId, "pinedit", 0]);
                break;
            case 11:
                if (!this.permission.isGranted('Pages.TransportationLogs')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.TransportationLogs.Edit') && !this.permission.isGranted('Pages.TransportationLogs.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/notes/transportationLogs/createOrEdit', data.entityId, "pinedit", 0]);
                break;
            case 12:
                if (!this.permission.isGranted('Pages.PCBs')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.PCBs.Edit') && !this.permission.isGranted('Pages.PCBs.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/pcbbp/pcBs/create-or-edit-pcb.component', data.entityId, "pinedit", 0]);
                break;
            //case 13:
            //    this._router.navigate(['app/nursing/nursingDiaries/create-or-edit-nursingDiary-modal', data.entityId, "pinedit"]);
            //    break;
            case 14:
                if (!this.permission.isGranted('Pages.IncidentReports')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.IncidentReports.Edit') && !this.permission.isGranted('Pages.IncidentReports.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/incident/incidentReports/create-or-edit-incidentReport-modal', data.entityId, "pinedit", 0]);
                break;
            case 15:
                if (!this.permission.isGranted('Pages.NursingLogs')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.NursingLogs.Edit') && !this.permission.isGranted('Pages.NursingLogs.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/notes/nursingLogs/createOrEdit', data.entityId, "pinedit", 0]);
                break;
            case 17:
                if (!this.permission.isGranted('Pages.FireDrills')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.FireDrills.Edit') && !this.permission.isGranted('Pages.FireDrills.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/fireDrills/fireDrills/create-or-edit-fireDrill-modal', data.entityId, "pinedit"]);
                break;
            case 16:
                if (!this.permission.isGranted('Pages.FacilityReports')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.FacilityReports.Edit') && !this.permission.isGranted('Pages.FacilityReports.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/facilityReports/facilityReports/create-or-edit-facilityReport-modal', data.entityId, "pinedit"]);
                break;
            case 20:
                if (!this.permission.isGranted('Pages.DisinfectLogs')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.DisinfectLogs.Edit') && !this.permission.isGranted('Pages.DisinfectLogs.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/notes/disinfectLogs/createOrEdit', data.entityId, "pinedit", 0]);
                break;
            case 19:
                if (!this.permission.isGranted('Pages.BloodSugarLogs')) {
                    this.message.info("You do not have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.BloodSugarLogs.Edit') && !this.permission.isGranted('Pages.BloodSugarLogs.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/notes/bloodSugarLogs/createOrEdit', data.entityId, "pinedit", 0]);
                break;
            case 18:
                if (!this.permission.isGranted('Pages.HealthLogs')) {
                    this.message.info("You donot have Access Permission");
                }
                else if (!this.permission.isGranted('Pages.HealthLogs.Edit') && !this.permission.isGranted('Pages.HealthLogs.View')) {
                    this.message.info("You do not have Access Permission");
                }
                else
                    this._router.navigate(['app/notes/healthLogs/createOrEdit', data.entityId, "pinedit", 0]);
        }
    }
    openSideBar() {
        this.getPinnedData();
        this.displayFavPane = true;
    }
    closeSideBar(){
        this.displayFavPane = false;
    }
    searchClient(event) {

        this.getAllPinnedData = this.filteredData;
        if (this.clientName) {
            var searchterm = this.clientName.toLowerCase().replace(" ", "").trim();
            if (this.clientName) {
                let idarray = [];
                let dataarray = this.myObj.filter(k => k.formname.toLowerCase().replace(" ", "").trim().includes(searchterm));

                for (let a of dataarray)
                    idarray.push(a['id']);

                let samp = this.getAllPinnedData.filter(s => idarray.includes(s.formId));
                this.getAllPinnedData = this.getAllPinnedData.filter(s => s.clientName.toLowerCase().replace(" ", "").trim().includes(searchterm) || idarray.includes(s.formId));
                if (this.getAllPinnedData) {
                    if (this.getAllPinnedData.length == 0) {

                        this._clientNotFound = true;

                    }
                    else {

                        this._clientNotFound = false;
                    }
                }

            }
            else {
                this.getAllPinnedData = this.filteredData;
            }
        }
        else {
            this.getAllPinnedData = this.filteredData;
            if (this.getAllPinnedData) {
                if (this.getAllPinnedData.length == 0) {
                    this._clientNotFound = true;

                }
                else {
                    this._clientNotFound = false;
                }
            }
        }
    }
}
