<p-sidebar [(visible)]="displayCreatePane" [dismissible]="true" position="right" [style]="{width:'375px'}" [showCloseIcon]="true">
    <div class="bit2-sidebar-content text-justify">
        <div class="bit2-sidebar-header create">
            <i class="fas fa-plus iocn-color-addnew"></i><span>New</span>
        </div>
        <!-- <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button> -->
        <div class="input-group col-md-12 bit2-create-searchbox">
            <input placeholder="What do you want to create?" class="form-control py-2 border-right-0 border"
                type="search" id="createsearch" (input)="filterUsers($event)" [(ngModel)]="filterText" />
            <span class="input-group-append">
                <button class="btn btn-outline-secondary border-left-0 border" type="button" style="padding-top: 0Px;">
                    <i class="fa fa-search"></i>
                </button>
            </span>
        </div>
        <div>
            <!-- <div class="card-body pt-2 pl-0 pr-0" id="createpanel">
                <div class="d-flex align-items-center mb-2 p-2 alternative_cls"
                     *ngFor="let formdata of SearchBar;let i=index;">
                    <div class="mr-2">
                        <span class="padding-left-10 pr-1">
                            <i class="{{formdata.formIcon}}"></i>
                        </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1" (click)="redirectonForm(formdata.formName,0,'txt')"
                        style="cursor:pointer; text-align: left;">
                        <span class="bit2-left-nav-text">{{formdata.formName}}</span>
                    </div>
                </div>
            </div> -->
            <ul class="list-group list-group-flush bit2-sidebar-list-items" id="createpanel">
                <li class="list-group-item" *ngFor="let formdata of SearchBar; let i = index"
                    [hidden]="!formdata.formPermissionCreate" (click)="redirectonForm(formdata.formName,0,'txt')">
                    
                    <a class="bit2-nav-link">
                        <i class="{{formdata.formIcon}}"></i>
                        <span class="bit2-left-nav-text">{{formdata.formName}}</span>
                    </a>
                </li>


            </ul>
        </div>
        <span class="list-group-item" [hidden]="count > 0">
            <b>
                Sorry! The item you are searching for is not in the list. Please check for permissions with
                the administration.
            </b>
        </span>
    </div>
</p-sidebar>

