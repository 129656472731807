<p-sidebar [(visible)]="displayFavPane" [dismissible]="true" position="right" [style]="{width:'375px'}" [showCloseIcon]="true">
    <div class="bit2-sidebar-content">
        <div class="bit2-sidebar-header create text-justify">
            <i class="fas fa-thumbtack iocn-color-mypins"></i><span>My Pins</span>
        </div>
        <div class="input-group col-md-12 bit2-create-searchbox">
            <input placeholder="Search" name="SearchClient" id="SearchClient"
                class="form-control py-2 border-right-0 border" (input)="searchClient($event)" type="search"
                [(ngModel)]="clientName" />
            <span class="input-group-append">
                <button class="btn btn-outline-secondary border-left-0 border" type="button" style="padding-top: 0Px;">
                    <i class="fa fa-search"></i>
                </button>
            </span>
        </div>
        <div>
            <div class="card-body pt-2 pl-0 pr-0">

                <div class="d-flex align-items-center mb-2 p-2 alternative_cls"
                    *ngFor="let data of getAllPinnedData;let i=index;">
                    <div class="mr-2" style="cursor:pointer" (click)="unpin(data)">
                        <span class="padding-left-10 pr-1">
                            <i class="fa-xl fas fa-thumbtack"></i>
                        </span>
                    </div>
                    <div class="symbol symbol-40 symbol-light-success mr-4" (click)="navigateTo(data)"
                        style="cursor:pointer">
                        <span class="symbol-label h-50px w-50px bg-white bit2-pins-img">
                            
                             <img src="{{data.clientPhoto}}" class="h-100 align-self-end" alt="">
                        </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1" (click)="navigateTo(data)" style="cursor:pointer">
                        <a class="text-hover-primary mb-1 bit2-left-nav-text">{{data.clientName}}</a>
                        <span class="text-muted">{{getFormname(data.formId)}} <span></span></span>
                    </div>
                    <div class="ml-2 mr-2 bg-light-primary" data-toggle="tooltip" title="{{getFormname(data.formId)}}"
                        data-placement="left" data-original-title="Quick actions ">
                        <span class="btn btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <i class="fa-xl {{getformicon(data.formId)}}"></i>
                        </span>
                    </div>

                </div>
                <span [hidden]="_clientNotFound==false">
                    No pinned items
                </span>

            </div>


        </div>
    </div>
</p-sidebar>
<!--<style>
    .sidebar{
        width: 375px;
        z-index: 1010 !important;
        opacity: 1;
    }
</style>-->
