<div bsModal #addReadingsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="addReadingsModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #addReadingsForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header border-0 d-flex">
                    <div>
                        <h4 class="modal-title text-primary font-weight-bolder">
                            {{patientsName}}
                        </h4>

                        <h4 class="modal-title font-weight-bolder">
                            {{medicationName}}
                        </h4>
                    </div>
                    <button type="button" (click)="close()" class="close mt-n10" [disabled]="saving" title="Close"> 
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body  mt-n7">
                    <tabset>
                        <tab>
                            <ng-template tabHeading>
                                <span><i class="fas fa-temperature-low pr-2"></i>Readings</span>
                            </ng-template>
                        <div class="alert alert-custom alert-notice alert-light-warning fade show margin-b mt-7"
                            *ngIf="hideDiv" role="alert">
                            <div class="alert-icon"><i class="far fa-lightbulb"></i></div>
                            <div class="alert-text font-sizem">
                                As per the doctor's instructions, you must take the following readings: <span class="font-weight-bolder">{{readVitals==true?'Vitals,':readSuger==true?'Blood Sugar,':readWeight==true?'Weight':''}} </span>
                            </div>
                        </div>
                            <div class="row card card-custom">   
                                <div class="card-body pt-5 pb-0">
                                    <div class="row">
                                        <div class="form-group col-md-4 col-sm-6">
                                            <label [ngClass]="readVitals==true? 'text-danger': 'text' ">Temperature(°F)</label>
                                            <span class="text-danger" *ngIf="shouldShowTemperatureWarning()">**</span>
                                                
                                            <div class="input-group">
                                                <input type="text" class="form-control" [disabled]="MediactioReading.temperatureDenied"
                                                    [(ngModel)]="MediactioReading.temperature" id="temperature"
                                                    name="Temperature" placeholder="Temperature">
                                                <div class="ml-1">
                                                    <span class="input-group-text input-group-hover">
                                                        <label class="checkbox checkbox-danger checkbox-outline">
                                                            <input type="checkbox" 
                                                                [(ngModel)]="MediactioReading.temperatureDenied" (change)="temperatureDeniedChecked($event)"
                                                                id="temperatureDenied" name="temperatureDenied">
                                                            <span></span>Denied
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-4 col-sm-6">
                                            <label [ngClass]="readVitals==true? 'text-danger': 'text' ">Pulse Rate (bpm)</label>
                                            <span class="text-danger" *ngIf="shouldShowPulseRateWarning()">**</span>
                                            <div class="input-group">
                                                <input type="text" id="Pulse" [(ngModel)]="MediactioReading.pulseRate"  [disabled]="MediactioReading.pulseRateDenied"
                                                    name="Pulse" class="form-control" placeholder="Pulse Rate (bpm)">
                                                <div class="ml-1">
                                                    <span class="input-group-text input-group-hover">
                                                        <label class="checkbox checkbox-danger checkbox-outline">
                                                            <input type="checkbox" (change)="pulseRateDeniedChecked($event)"
                                                                [(ngModel)]="MediactioReading.pulseRateDenied" id="pulseRateDenied" 
                                                                name="pulseRateDenied">
                                                            <span></span>Denied
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4 col-sm-6">
                                            <label [ngClass]="readVitals==true? 'text-danger': 'text' ">Respiration Rate (bpm)</label>
                                            <span class="text-danger" *ngIf="shouldShowRespirationRateWarning()">**</span>

                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                       [(ngModel)]="MediactioReading.respirationRate" id="Respiration" [disabled]="MediactioReading.respirationRateDenied"
                                                       name="Respiration" placeholder="Respiration Rate (bpm)">
                                                <div class="ml-1">
                                                    <span class="input-group-text input-group-hover">
                                                        <label class="checkbox checkbox-danger checkbox-outline">
                                                            <input type="checkbox" (change)="respirationRateDeniedChecked($event)"
                                                                   [(ngModel)]="MediactioReading.respirationRateDenied"
                                                                   id="respirationRateDenied" name="respirationRateDenied">
                                                            <span></span>Denied
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-4 col-sm-6">
                                            <label [ngClass]="readVitals==true? 'text-danger': 'text' ">Blood Pressure(mm-Hg)</label>
                                            <span class="text-danger" *ngIf="shouldShowBloodPressureWarning()">**</span>


                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                       [(ngModel)]="MediactioReading.bloodPressure" id="blood" name="blood" [disabled]="MediactioReading.bloodPressureDenied"
                                                       placeholder="Blood Pressure">

                                                <div class="ml-1">
                                                    <span class="input-group-text input-group-hover">
                                                        <label class="checkbox checkbox-danger checkbox-outline">
                                                            <input type="checkbox" (change)="bloodPressureDeniedChecked($event)"
                                                                   [(ngModel)]="MediactioReading.bloodPressureDenied"
                                                                   id="bloodPressureDenied" name="bloodPressureDenied">
                                                            <span></span>Denied
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4 col-sm-6">
                                            <label [ngClass]="readWeight==true? 'text-danger': 'text' ">Weight (lb)</label>
                                            <span class="text-danger" *ngIf="shouldShowWeightWarning()">**</span>

                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                       [(ngModel)]="MediactioReading.weight" id="Weight" name="Weight" [disabled]="MediactioReading.weightDenied"
                                                       placeholder="Weight">
                                                <div class="ml-1">
                                                    <span class="input-group-text input-group-hover">
                                                        <label class="checkbox checkbox-danger checkbox-outline">
                                                            <input type="checkbox" (change)="weightDeniedChecked($event)"
                                                                   [(ngModel)]="MediactioReading.weightDenied"
                                                                   id="WeightDenied" name="weightDenied">
                                                            <span></span>Denied
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="form-group col-md-4 col-sm-6">
                                            <label [ngClass]="readVitals==true? 'text-danger': 'text' ">O2 Level</label>
                                            <span class="text-danger" *ngIf="shouldShowO2LevelWarning()">**</span>

                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                       [(ngModel)]="MediactioReading.o2Level" id="Level" name="O2Level" [disabled]="MediactioReading.o2LevelDenied"
                                                       placeholder="O2 Level">
                                                <div class="ml-1">
                                                    <span class="input-group-text input-group-hover">
                                                        <label class="checkbox checkbox-danger checkbox-outline">
                                                            <input type="checkbox" (change)="o2LevelDeniedChecked($event)"
                                                                   [(ngModel)]="MediactioReading.o2LevelDenied"
                                                                   id="o2LevelDenied" name="o2LevelDenied">
                                                            <span></span>Denied
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4 col-sm-6">
                                            <label [ngClass]="readSuger==true? 'text-danger': 'text' ">Blood Sugar(mg/dL)</label>
                                            <span class="text-danger" *ngIf="shouldShowBloodSugarWarning()">**</span>

                                            <div class="input-group">
                                                <input type="text" class="form-control" id="bloodSugar"
                                                       [(ngModel)]="MediactioReading.bloodSugar" name="sugar" [disabled]="MediactioReading.bloodSugarDenied"
                                                       placeholder="Blood Sugar">
                                                <div class="ml-1">
                                                    <span class="input-group-text input-group-hover">
                                                        <label class="checkbox checkbox-danger checkbox-outline">
                                                            <input type="checkbox" (change)="bloodSugerChecked($event)"
                                                                   [(ngModel)]="MediactioReading.bloodSugarDenied"
                                                                   name="bloodSugarDenied" id="bloodSugarDenied">
                                                            <span></span>Denied
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-sm-12">
                                            <label>Comments</label>
                                            <textarea class="form-control" [(ngModel)]="MediactioReading.comment"
                                                name="comment" id="CommentsTextarea" rows="2"
                                                placeholder="Enter your comments here"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                <span><i class="fas fa-history pr-2"></i>History</span>
                            </ng-template>

                            <div class="row card card-custom pb-0">
                                <div class="card-body pt-3">
                                    <div class="row">
                                        <div class="form-group col-12">
                                            <table class="table table-hover cursor-pointer">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Date Time</th>
                                                        <th scope="col">Temp</th>
                                                        <th scope="col">BP</th>
                                                        <th scope="col">Sugar</th>
                                                        <th scope="col">Pulse</th>
                                                        <th scope="col">Resp</th>
                                                        <th scope="col">Weight </th>
                                                        <th scope="col">O2</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr *ngFor="let oMiObj of medReadings">
                                                        <th scope="row">{{oMiObj.creationTime | date:'short'}}</th>
                                                        <th>{{oMiObj.temperature}}</th>
                                                        <th>{{oMiObj.bloodPressure}}</th>
                                                        <th>{{oMiObj.bloodSugar}}</th>
                                                        <th>{{oMiObj.pulseRate}}</th>
                                                        <th>{{oMiObj.respirationRate}}</th>
                                                        <th>{{oMiObj.weight}}</th>
                                                        <th>{{oMiObj.o2Level}}</th>
                                                        <!-- 
        <td>{{oMiObj.omissionReason == 1? 'Hold': oMiObj.omissionReason == 2 ? 'Individual Refused' : oMiObj.omissionReason == 3 ? 'Individual Refused' : 'Other' }}</td>
        <td>{{oMiObj.nurseNotified== 'true'?'Yes':'No'}}</td> -->
                                                    </tr>

                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <div class="row d-flex flex-grow-1">
                        <div class="flex-column flex-grow-1 text-right">
                            <button [disabled]="saving" type="button"
                                class="btn btn-light-primary font-weight-bold mr-2" (click)="close()">{{"Cancel" |
                                localize}}</button>
                            <button type="submit" class="btn btn-primary font-weight-bold"
                                [disabled]="!addReadingsForm.form.valid"><i class="fas fa-check pr-2"></i>
                                <span>{{"Save" | localize}}</span></button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<app-confirmation-dialog #appConfirmationDialog
                         [errorMessages]="errorMessages"
                         (confirm)="handleConfirm($event)"></app-confirmation-dialog>
