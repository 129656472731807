<div dropdown class="topbar-item" data-toggle="dropdown" aria-expanded="true"
     [dropup]="isDropup" container="{{isDropup ? null: 'body'}}">
    <div dropdownToggle [class]="customStyle" id="notificationIcon">
        <i class="flaticon-alert-2 unread-notification" *ngIf="unreadNotificationCount"></i>
        <span class="label label-warning unread-notification-count" *ngIf="unreadNotificationCount">
            {{unreadNotificationCount}}
        </span>
        <i class="flaticon-alarm" *ngIf="!unreadNotificationCount"></i>
    </div>
    <div *dropdownMenu
         class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-lg">
        <perfect-scrollbar>
            <div class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                 [ngStyle]="{ 'background': 'url(' + appRootUrl() + 'assets/metronic/themes/default/images/bg/bg-1.jpg)', 'background-size': 'cover'}">
                <h4 class="d-flex flex-center rounded-top">
                    <span class="text-white">{{"Notifications" | localize}}</span>
                    <span class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                        {{unreadNotificationCount}}
                        {{"New" | localize}}
                    </span>
                </h4>
            </div>
            <div>
                <div class="row m-4">
                    <div class="text-left col-md-8">
                        <a href="javascript:;"
                           (click)="setAllNotificationsAsRead(); $event.preventDefault(); $event.stopPropagation();"
                           id="setAllNotificationsAsReadLink"
                           *ngIf="unreadNotificationCount">{{"SetAllAsRead" | localize}}</a>
                    </div>
                    <div class="text-right col-md-4">
                        <a id="openNotificationSettingsModalLink" class="text-right" href="javascript:;"
                           (click)="openNotificationSettingsModal()">{{"Settings" | localize}}</a>
                    </div>
                </div>
                <hr />

                <div class="navi navi-hover my-4 ps overflow-auto" data-scroll="true" data-height="300" data-mobile-height="200"
                     style="height: 300px; ">
                    <!--overflow: hidden;-->

                    <a *ngFor="let notification of notifications" (click)="gotoUrl(notification.url)"
                       [ngClass]="{'user-notification-item-clickable': notification.url,'user-notification-item-unread': notification.isUnread}"
                       class="navi-item cursor-pointer">
                        <div class="navi-link">
                            <div class="navi-icon mr-2">
                                <i class="{{notification.icon}} {{notification.iconFontClass}}"></i>
                            </div>
                            <div class="navi-text">
                                <div class="font-weight-bold">
                                    {{truncateString(notification.text,120)}}
                                </div>
                                <div class="text-muted">
                                    {{notification.time | momentFromNow}}
                                </div>
                                <span *ngIf="notification.isUnread"
                                      class="btn btn-link-success set-notification-as-read"
                                      (click)="setNotificationAsRead(notification); $event.preventDefault(); $event.stopPropagation();">
                                    {{"SetAsRead" | localize}}
                                </span>
                            </div>
                        </div>
                    </a>
                    <span *ngIf="!notifications.length" class="notification-empty-text p-3">
                        {{"ThereAreNoNotifications" | localize}}
                    </span>
                </div>
                <hr *ngIf="notifications.length" />
                <div class="m-4" *ngIf="notifications.length">
                    <div class="text-center">
                        <a routerLink="notifications">{{"SeeAllNotifications" | localize}}</a>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</div>


<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static',keyboard:false}">
    <div class="modal-dialog modal-lg modal-dialog-centered">
       
        <div class="modal-content">
            <form #announcementdialogForm="ngForm" novalidate autocomplete="off">
                <div class="card card-custom wave wave-animate-slow mb-8 mb-lg-0 ribbon ribbon-clip ribbon-left">
                    <div class="ribbon-target" style="top: 5px;">
                        <span class="ribbon-inner bg-warning"></span>&nbsp;Announcement
                        <span class="label label-sm label-white ml-2">{{totalannouncementcount}}</span>
                    </div>

                    <div class="card-body modal-card bit2-bottom-paddding-0">
                        <div class="alert alert-custom alert-notice alert-light-danger fade show p-2 mt-4" role="alert" *ngIf="isrecall">
                            <div class="alert-icon">
                                <i class="flaticon-warning"></i>
                            </div>
                            <div class="alert-text">Action Recalled! Hey, you are requested to ignore/discard this announcement read previously.</div>
                        </div>
                        <div class="d-flex align-items-center p-5">
                            <div class="mr-6">
                                <span class="svg-icon svg-icon-warning svg-icon-4x">
                                    <span class="btn-light-{{announcementtype}} pulse pulse-{{announcementtype}} mr-5">
                                        <i class="fa-3x fa fa-bullhorn text-{{announcementtype}}"></i>
                                        <span class=""></span>
                                    </span>
                                </span>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="text-{{announcementtype}} text-hover-primary font-weight-bold font-size-h4 mb-3">{{title}}.</span>
                                <div class="text-dark-75" [innerHTML]="announcement">
                                    <!--{{announcement}}-->
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="document-left-list ml-3 mr-3 mt-0" title="Click to open document" (click)="ondocumentclick()" [hidden]="!docpath">
                        <div class="bg-white document-left-list-second mt-3">
                            <div title="Click here to open the document...">
                                <div class="text-center bit2-magrin-bottom-2">
                                    <div class="document-list border border-light-dark">
                                        gh
                                        <div class="m-0 p-2 bg-{{announcementtype}} text-white">
                                            <i class="far fa-file-alt p-1" aria-hidden="true"></i>{{docname}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-right col-xl-12 mb-2">
                        <span class="label label-info label-inline mr-2">Sent By : {{announcementcreator}}</span>
                        <span class="label label-info label-inline">{{announcementcreationtime |  momentFormat:'L' }}</span>
                    </div>
                    <div class="modal-footer modal-footer-custom" style="justify-content:center">
                        <div class="row w-100">

                            <div class="text-center col-xl-12">
                                <button class="btn btn-success bit2-button-width-160 {{isdisabled ? 'disabled' : ''}} " (click)="okclick(ancount)"  title="{{isdisabled ? 'click only if there is an attachment in the announcement, Please see attachment' : 'OK'}}" >
                                    <i class="far fa-thumbs-up"></i>OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>

</div>

