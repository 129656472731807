import { Component, EventEmitter, Injector, Output, ViewChild, OnInit, ChangeDetectorRef, ElementRef, HostListener } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    CurrentUserProfileEditDto,
    SettingScopes,
    ProfileServiceProxy,
    UpdateGoogleAuthenticatorKeyOutput,
    SendVerificationSmsInputDto,

    UpdateProfilePictureInput
} from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SmsVerificationModalComponent } from './sms-verification-modal.component';
import { finalize } from 'rxjs/operators';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { TokenService, IAjaxResponse } from 'abp-ng2-module';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { TabDirective } from 'ngx-bootstrap/tabs';

@Component({
    selector: 'mySettingsModal',
    templateUrl: './my-settings-modal.component.html',
    // providers: [SignaturePadModule]
})
export class MySettingsModalComponent extends AppComponentBase implements OnInit {

    // private cx: CanvasRenderingContext2D;
    //  @ViewChild('canvasEl', { static: true }) public canvas: ElementRef;
    private cx: CanvasRenderingContext2D;
    @ViewChild('mySettingsModal', { static: true }) modal: ModalDirective;
    @ViewChild('smsVerificationModal') smsVerificationModal: SmsVerificationModalComponent;
    @ViewChild('sigPad') sigPad;
    @ViewChild('canvasEl') canvas;
    sigPadElement;
    context;
    isDrawing = false;
    img;
    canvasElement;
    // @ViewChild(SignaturePad) signaturePad: SignaturePad;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    profileSignature = AppConsts.appBaseUrl + '/assets/common/images/defaultsignature.jpg';
    public active = false;
    public saving = false;
    public isGoogleAuthenticatorEnabled = false;
    public isPhoneNumberConfirmed: boolean;
    public smsEnabled: boolean;
    public user: CurrentUserProfileEditDto;
    public showTimezoneSelection: boolean = abp.clock.provider.supportsMultipleTimezone;
    public canChangeUserName: boolean;
    public defaultTimezoneScope: SettingScopes = SettingScopes.User;
    private _initialTimezone: string = undefined;
    public savedPhoneNumber: string;
    public newPhoneNumber: string;
    isMultiTenancyEnabled: boolean = this.multiTenancy.isEnabled;
    isTwoFactorLoginEnabledForApplication = false;
    private maxSignatureBytesUserFriendlyValue = 5;
    imageChangedEvent: any = '';
    drawSignature = false;
    uploadSignature = true;
    selectSignature = false;
    public uploader: FileUploader;
    private _uploaderOptions: FileUploaderOptions = {};
    canvasFont = '';
    disableButton = true;
    //private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    //    'minWidth': 5,
    //    'canvasWidth': 500,
    //    'canvasHeight': 300
    //};

    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy,
        private _tokenService: TokenService,
        private cd: ChangeDetectorRef,
        //   private signaturePad: SignaturePad,

    ) {
        super(injector);

    }

    ngOnInit(): void {
        this.isTwoFactorLoginEnabledForApplication =
            abp.setting.getBoolean('Abp.Zero.UserManagement.TwoFactorLogin.IsEnabled');
        this.initFileUploader();
        this.getProfileSignature();

    }
    @HostListener('document:mouseup', ['$event'])
    onMouseUp(e) {
        this.isDrawing = false;
    }

    onMouseDown(e) {
        this.isDrawing = true;
        this.disableButton = false;
        const coords = this.relativeCoords(e);
        this.context.moveTo(coords.x, coords.y);
    }

    onMouseMove(e) {
        if (this.isDrawing) {
            const coords = this.relativeCoords(e);
            this.context.lineTo(coords.x, coords.y);
            this.context.stroke();
        }
    }
    private relativeCoords(event) {
        const bounds = event.target.getBoundingClientRect();
        const x = event.clientX - bounds.left;
        const y = event.clientY - bounds.top;
        return { x: x, y: y };
    }

    clear() {
        this.disableButton = true;
        this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
        this.context.beginPath();
    }

    saveImg() {
        
        this.img = this.sigPadElement.toDataURL("image/png");
        this.showImage(this.img);
    }
    show(): void {
        this.active = true;
        this._profileService.getCurrentUserProfileForEdit().subscribe((result) => {
            this.smsEnabled = this.setting.getBoolean('App.UserManagement.SmsVerificationEnabled');
            this.user = result;
            this._initialTimezone = result.timezone;
            this.canChangeUserName = this.user.userName !== AppConsts.userManagement.defaultAdminUserName;
            this.cd.detectChanges();
            this.modal.show();
            this.isGoogleAuthenticatorEnabled = result.isGoogleAuthenticatorEnabled;
            this.isPhoneNumberConfirmed = result.isPhoneNumberConfirmed;
            this.savedPhoneNumber = result.phoneNumber;
            this.sigPadElement = this.sigPad.nativeElement;
            this.context = this.sigPadElement.getContext('2d');
            this.context.strokeStyle = '#3742fa';
            


            this.cx = this.canvas.nativeElement.getContext('2d')
            const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
            this.cx = canvasEl.getContext('2d');
            this.cx.lineWidth = 3;
            this.cx.lineCap = 'round';
            this.cx.strokeStyle = '#000';
            if (this.user.signatureFontFamily)
                this.cx.font = this.user.signatureFontFamily;
            else {
                this.cx.font = "70px Courier";
            }
            this.cx.fillText(this.appSession.user.name + " " + this.appSession.user.surname, 100, 100);


        });
    }

    updateQrCodeSetupImageUrl(): void {
        this._profileService.updateGoogleAuthenticatorKey().subscribe((result: UpdateGoogleAuthenticatorKeyOutput) => {
            this.user.qrCodeSetupImageUrl = result.qrCodeSetupImageUrl;
            this.isGoogleAuthenticatorEnabled = true;
        });
    }

    disableGoogleAuthenticator(): void {
        this._profileService.disableGoogleAuthenticator().subscribe(() => {
            this.isGoogleAuthenticatorEnabled = false;
        });
    }

    smsVerify(): void {
        let input = new SendVerificationSmsInputDto();
        input.phoneNumber = this.user.phoneNumber;
        this._profileService.sendVerificationSms(input)
            .subscribe(() => {
                this.smsVerificationModal.show();
            });
    }

    changePhoneNumberToVerified(): void {
        this.isPhoneNumberConfirmed = true;
        this.savedPhoneNumber = this.user.phoneNumber;
    }

    onShown(): void {
        document.getElementById('Name').focus();
    }

    close(): void {
        this.active = false;
        this.imageChangedEvent = '';
        this.uploader.clearQueue();
        this.modal.hide();
    }

    save(): void {
        this.saving = true;
        this.uploader.uploadAll();
        this._profileService.updateCurrentUserProfile(this.user)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(() => {
                this.appSession.user.name = this.user.name;
                this.appSession.user.surname = this.user.surname;
                this.appSession.user.userName = this.user.userName;
                this.appSession.user.emailAddress = this.user.emailAddress;
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);

                if (abp.clock.provider.supportsMultipleTimezone && this._initialTimezone !== this.user.timezone) {
                    this.message.info(this.l('TimeZoneSettingChangedRefreshPageNotification')).then(() => {
                        window.location.reload();
                    });
                }
            });
    }
    fileChangeEvent(event: any): void {
        
        if (event.target.files[0].size > 5242880) { //5MB
            this.message.warn(this.l('ProfilePicture_Warn_SizeLimit', this.maxSignatureBytesUserFriendlyValue));
            return;
        }

        this.imageChangedEvent = event;

    }
    imageCroppedFile(event: ImageCroppedEvent) {
        
        this.uploader.clearQueue();
        this.uploader.addToQueue([<File>base64ToFile(event.base64)]);
        //
    }
    //imageCroppedFile(file: File) {
    //    
    //    let files: File[] = [file];
    //    this.uploader.clearQueue();
    //    this.uploader.addToQueue(files);
    //    this.uploader.uploadAll();
    //}
    initFileUploader(): void {
        
        this.uploader = new FileUploader({ url: AppConsts.remoteServiceBaseUrl + '/Profile/UpdateProfileSignature' });
        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
        this._uploaderOptions.removeAfterUpload = true;
        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            form.append('FileType', fileItem.file.type);
            form.append('FileName', 'ProfileSignature');
            form.append('FileToken', this.guid());
        };

        this.uploader.onSuccessItem = (item, response, status) => {

            const resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                
                this.updateProfileSignature(resp.result.fileToken);
            } else {
                this.message.error(resp.error.message);
            }
        };

        this.uploader.setOptions(this._uploaderOptions);
    }
    updateProfileSignature(fileToken: string): void {
        
        const input = new UpdateProfilePictureInput();
        input.fileToken = fileToken;
        input.x = 0;
        input.y = 0;
        input.width = 0;
        input.height = 0;

        this.saving = true;
        this._profileService.updateProfileSignature(input)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(() => {
                abp.event.trigger('profileSignatureChanged');
                this.close();
                this.notify.success(this.l('SavedSuccessfully'));
            });
    }
    guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    getProfileSignature(): void {
        this._profileService.getUserSignature().subscribe(result => {
            if (result && result.profilePicture) {
                this.profileSignature = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    }
    showImage(data) {
        
        if (data.includes("base64")) {
            this.profileSignature = data;
            this.uploader.clearQueue();
            this.uploader.addToQueue([<File>base64ToFile(this.profileSignature)]);
            return;
        }

    }
    public onSignFontChange(event) {
        
        this.clearCanvas();
        this.cx.font = event;
        this.cx.fillText(this.appSession.user.name + " " + this.appSession.user.surname, 100, 100);
        this.img = this.canvas.nativeElement.toDataURL('image/png');
        this.profileSignature = this.img;
        this.uploader.clearQueue();
        this.uploader.addToQueue([<File>base64ToFile(this.profileSignature)]);
        return;
    }
    private clearCanvas() {
        this.cx.clearRect(0, 0, 700, 150);
    }
    onSelect(data: TabDirective): void {
        
        //var _selectedTab = data.heading.toLowerCase();
        //if (_selectedTab.toLowerCase() == 'draw signature')
        //    this.drawSignature = true
        //else if (_selectedTab.toLowerCase() == 'font signature')
        //    this.drawSignature = false
    }
}
