import { Component, Input, OnInit, Output, EventEmitter, ViewChild, Injector, ViewEncapsulation } from '@angular/core';
import { ViewAcknowledgementComponent } from './view-acknowledgement.component';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '../../../../shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AcknowledgementDto, AcknowledgementServiceProxy } from '../../../../shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import { DateTimePicker } from '@syncfusion/ej2-angular-calendars';
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';
import { LazyLoadEvent } from 'primeng/api';
import { LocalStorageService } from '../../../../shared/utils/local-storage.service';
import { AppConsts } from '../../../../shared/AppConsts';
import { NcpTrainedStaffsComponent } from '../../../nursing/NursingCare/nursingCarePlan/ncpTrainedStaffs/ncpTrainedStaffs.component';

@Component({
   selector: 'app-acknowledgement',
    templateUrl: './acknowledgement.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class AcknowledgementComponent extends AppComponentBase {
    //@ViewChild('app-view-acknowledgement', { static: true }) viewAcknowledgement: ViewAcknowledgementComponent;
    @ViewChild('acknowledgeForm', { static: true }) form: NgForm;
    @ViewChild('ackmodal', { static: true }) modal: ModalDirective;

    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    @Output() AckToParent: EventEmitter<any> = new EventEmitter<any>();

    @Input() data: string;
    @Output() dataChange = new EventEmitter();
    isVisible = false;
    isLoading = false;
    formname: string;
    Username: string;
    CreationTime: any;
    acknowledgeDto: AcknowledgementDto = new AcknowledgementDto();
    _rows: AcknowledgementDto[];
    disableacknowlege = false;
    usernamesearch :string;
    acknoledementlist: AcknowledgementDto[];

    toggleVisibility(): void {
        this.isVisible = !this.isVisible;
    }
    nodata = false;
    constructor(injector: Injector,
        private _localStorageService: LocalStorageService,
        private _ackproxy: AcknowledgementServiceProxy) {
        super(injector);
        
    }

    onChange() {
        this.dataChange.emit(this.data);
    }

    viewModal(formname) {
         
        this.isLoading = true;
        this._rows = [];
        this.acknoledementlist = [];
        this._ackproxy.getAcknowledgeView(this.acknowledgeDto.formID, Number(this.acknowledgeDto.entityID))
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe(result => {               
                this._rows = [];
                this.acknoledementlist = [];
                this.acknoledementlist = result;
                if (this.acknoledementlist.length == 0)
                    this.nodata = true;
                else
                    this.nodata = false;
                this._rows = result;
                this.setUsersProfilePictureUrl(this.acknoledementlist);
                
            });
        this.modal.show();
    }
    close() {

       
        this.modal.hide();
    }
    assignData(clientId, entityId, userId, formID, formname,username) {
        this.acknowledgeDto.formname = formname;
        this.acknowledgeDto.userID = userId;
        this.acknowledgeDto.entityID = entityId;
        this.acknowledgeDto.formID = formID;
        this.acknowledgeDto.clientID = clientId;
        this.acknowledgeDto.username = username;
    }
        
    Onclick() {
        
        
        this.message.confirm(
            this.l('I acknowledge I have read, understood, and will accurately implement ' + this.acknowledgeDto.formname + ' for '+ this.acknowledgeDto.username), ' ',
            //this.l('Are you sure you want to acknowlege ' + this.acknowledgeDto.formname +' for '+ this.acknowledgeDto.username+'?'), ' ',
            (isConfirmed) => {
                if (isConfirmed) {
                    
                    this.disableacknowlege = true;
                    this._ackproxy.createOrEdit(this.acknowledgeDto)
                        .pipe(finalize(() => { }))
                        .subscribe(result => {
                            this.AckToParent.emit(this.acknowledgeDto);
                            this.notify.info(this.l('Acknowledged Successfully'));
                        });
                }
            });
      
      
       //this.acknowledgeDto.entityID=this.
    }
    searchClient() {
        
        this.usernamesearch = this.usernamesearch.trim();
        if (this.usernamesearch) {
            this._rows = this.acknoledementlist.filter(s => s.username.toLowerCase().replace(' ', '').includes(this.usernamesearch.toLowerCase().replace(' ', '')))
            if (this._rows.length == 0)
                this.nodata = true;
            else
                this.nodata = false;
        } else {
            this._rows = this.acknoledementlist;
        }
    }
    setUsersProfilePictureUrl(users: AcknowledgementDto[]): void {
        for (let i = 0; i < users.length; i++) {
            let user = users[i];
            this._localStorageService.getItem(AppConsts.authorization.encrptedAuthTokenName, function (err, value) {
                let profilePictureUrl = AppConsts.remoteServiceBaseUrl + '/Profile/GetProfilePictureByUser?userId=' + user.userID + '&' + AppConsts.authorization.encrptedAuthTokenName + '=' + encodeURIComponent(value.token);
                (user as any).profilePictureUrl = profilePictureUrl;
            });
        }
    }

    getUsersProfilePictureUrl() {

    }
    
}
