import { ChangeDetectorRef, Component, EventEmitter, Inject, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CommonServiceProxy, GetEMARRecordForEditOutput, PatientsServiceProxy } from '@shared/service-proxies/service-proxies';
import { forEach, result, words } from 'lodash';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
//import { AppComponentBase } from '../../../shared/common/app-component-base';
//import { CommonServiceProxy, PatientsServiceProxy } from '../../../shared/service-proxies/service-proxies';

@Component({
    selector: 'emarReportmodal',
    templateUrl: './emarReportmodal.component.html',
    styleUrls: ['./emarReportmodal.component.css']
})
export class EmarReportModalComponent extends AppComponentBase {

    @ViewChild('emarexportmodal', { static: true }) modal: ModalDirective;
    @ViewChild('emarReportmodalForm', { static: true }) form;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() viewPdfReportOnQmar = new EventEmitter();
    @Output() viewPdfReportOnEmar = new EventEmitter();
    patientContact: GetEMARRecordForEditOutput = new GetEMARRecordForEditOutput();
    public dateRange: moment.Moment[];
    export = false;
    active = false;
    today: Date;
    filterText = '';
    maxNoteDateFilter: moment.Moment;
    minNoteDateFilter: moment.Moment;
    propertyDestructionFilter = -1;
    allotmentFilter = -1;
    physicalAggressionFilter = -1;
    stealingFilter = -1;
    emergencyCalledFilter = -1;
    patientsnameFilter = '';
    programNameFilter = '';
    shiftShiftNameFilter = '';
    IndividualNameFilter = '';
    createdByFilter = '';
    noteDateFilter = '';
   selectProgramValue : any;
    programs: any[];
    maxFormDateFilter: moment.Moment;
    minFormDateFilter: moment.Moment;
    createdOnfilter: moment.Moment;
    onlyAssosiateStaffFilter = false;
    eventdata;
    loadqueyparam = true;
    patientId: any;
    isLoading = false;
    clientData: any[] = [];
    slectProgram: any[];
    isValid = true;
    day = new Date();
    month = new Date();
    year = new Date();
    bsValue = new Date(this.day.getFullYear(), 0, 1);
    minDate = new Date(this.day.getFullYear(), 0, 1);
    maxDate1 = new Date();
    maxDate = Date.now();
  //  selectProgram:any;

    constructor(
        injector: Injector,
        public dialogRef: MatDialogRef<EmarReportModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _activatedRoute: ActivatedRoute,
        private cd: ChangeDetectorRef,
        private _patientsServiceProxy: PatientsServiceProxy,
        private _commonServiceProxy: CommonServiceProxy,
        private _router: Router) {
        super(injector);
        this.today = new Date();
    }

    ngOnInit(): void {
        this.validation()
        //let minCreatedOnfilter = moment(this.minDate, 'MM/DD/YYYY');
        let MaxCreatedOnfilter = moment(this.maxDate1, 'MM/DD/YYYY');
        //this.dateRange = [minCreatedOnfilter, MaxCreatedOnfilter];
        //this.minFormDateFilter = moment(this.dateRange[0], "MM/DD/YYYY");
        //this.maxFormDateFilter = moment(this.dateRange[1], "MM/DD/YYYY");
    }

    validation() {
        if (this.maxFormDateFilter == undefined || this.minFormDateFilter == undefined ||
            this.maxFormDateFilter == null || this.minFormDateFilter == null ||
            this.dateRange == undefined || this.dateRange == null
        ) {
            this.isValid = true;
        }
        else {
            this.isValid = false;
        }

    }

    dateRangeSelected(event) {
        debugger;
        var data = event;
        // this.CreatedOnDateRangeSelected = true;
        if (data) {
            
            this.maxFormDateFilter = moment(this.dateRange[0]);//moment.utc(this.dateRange[0], 'MM-DD-YYYY').format('DD-MM-YY');
            this.minFormDateFilter = moment(this.dateRange[1]);
            //this.minFormDateFilter = this.getOriginaldate(moment(this.dateRange[1]));
            //var temp = moment(this.minFormDateFilter, 'MM-DD-YYYY');
            //this.minFormDateFilter = temp;

            //this.maxFormDateFilter = this.getOriginaldate(moment(this.dateRange[0]));
            //var temp1 = moment(this.maxFormDateFilter, 'MM-DD-YYYY');
            //this.maxFormDateFilter = temp1;
        }
        this.validation();

    }

    getOriginaldate(_date) {
        return _date._i;
    }

    searchfilter(event) {
        this.filterText = event.target.value;


        
        if (this.filterText) {
            let wordSearch = this.filterText.trim();
            setTimeout(() => {
                if (wordSearch == this.filterText.trim()) {
                    this.isLoading = true;

                    this._commonServiceProxy.getClientInfoForExcel(wordSearch, this.onlyAssosiateStaffFilter)
                        .pipe(finalize(() => { this.isLoading = false; }))
                        .subscribe(data => {
                            if (data.length != 0) {
                                this.isLoading = false;
                                this.clientData = data;
                                this.cd.detectChanges();
                            }
                            else {
                                this.clientData = [];
                                this.isLoading = false;
                                this.form.reset();
                            }

                        })

                   
                }
            }, 2000);
        }
        else {
            this.loadqueyparam = false;
            try {
                this.filterText = null;
            }
            catch { }
        }
    }

    onClientselect(event) {
        debugger;
        this.filterText = event.clientName;
    }

    show(event) {
        this.patientId = event;
        this.modal.show();
    }

    hide() {
        this.modal.hide();
    }
    assignvalue(clientname, maxdate, mindate, datearr, associatdStaff) {
        this.filterText = clientname;
       
        this.dateRange = datearr;

        this.maxFormDateFilter = maxdate;
        this.minFormDateFilter = mindate;

        this.onlyAssosiateStaffFilter = associatdStaff;
    }

    close(): void {
        this.programs = null;
        this.modal.hide();
        
    }

    clearDaterange(){
       
    }

    exportToPDF(){
        debugger;
        this.patientContact.patientId = this.patientId;
        this.patientContact.startDate = this.maxFormDateFilter;
        this.patientContact.endDate = this.minFormDateFilter;
        this.viewPdfReportOnQmar.emit(this.patientContact);
        //this.viewPdfReportOnEmar.emit(this.patientContact);
    }
}

export class transferDate {
    patientId: number;
    startDate: moment.Moment;
    endDate: moment.Moment;
}
