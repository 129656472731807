<div bsModal #errorlistModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="errorlistModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header justify-content-center">
                <i class="fas fa-exclamation-triangle text-warning fa-3x"></i>
            </div>
            <div class="modal-body text-center p-0">
                <h5 class="modal-title text-danger">
                    <i class="fas fa-exclamation-circle"></i> {{ titleMessage }}
                </h5>
                <div *ngFor="let message of errorMessages; let i = index" class="d-flex align-items-center mt-5">
                    <span class="number-circle">{{ i + 1 }}</span>
                    <h6>{{ message }}</h6>
                </div>
            </div>
            <div class="modal-footer justify-content-center flex-column">
                <p class="text-muted">If you click "Yes," the error will be ignored and the data will be saved.</p>
                <div>
                    <button type="button" class="btn btn-primary mx-2" (click)="onYesClick()">Yes</button>
                    <button type="button" class="btn btn-danger mx-2" (click)="onNoClick()">No</button>
                </div>
            </div>
        </div>
    </div>
</div>
