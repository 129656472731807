import { AfterViewChecked, Component, ElementRef, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { CreateOrEditMedicationReadingDto, EmarSetting, EmarSettingsServiceProxy, GetEMarForViewDto, MedicationAdminScheduleDto, MedicationReadingsServiceProxy, MedicationStatusEnum } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from '../../../medications/eMAR/quick-emarClientList/confirmationDialog.component';
import { forkJoin } from '@node_modules/rxjs';

@Component({
    selector: 'add-readings-dialog',
    templateUrl: './add-readings-dialog.component.html',
    styleUrls: ['add-readings-dialog.component.css']
})
export class AddReadingsDialogComponent extends AppComponentBase {

    @ViewChild('addReadingsModal', { static: true }) modal: ModalDirective;
    @ViewChild('addReadingsForm', { static: true }) form: NgForm;
    @ViewChild('appConfirmationDialog', { static: true }) errorListModal: ConfirmationDialogComponent;


    @Output() saveReading: EventEmitter<any> = new EventEmitter<any>();
    active = false;
    saving = false;
    patientsName: string;
    medicationName: string;
    readVitals = false;
    readSuger = false;
    readWeight = false;
    _required = false;
    date: Date;
    medicationScheduleId;
    clientMedicationId;
    patientId;
    patientEMr = new GetEMarForViewDto();
    medicationReadingsSaved: boolean;
    medReadings
    tempEmarId: any;
    hideDiv = false
    view = false;
    temperatureDenied = false;
    bloodPressureDenied = false;
    pulseRateDenied = false;
    respirationRateDenied = false;
    o2LevelDenied = false;
    weightDenied = false;
    bloodSugarDenied = false;
    MediactioReading: CreateOrEditMedicationReadingDto = new CreateOrEditMedicationReadingDto();
    EmarSettingData: EmarSetting = new EmarSetting;
    showDialog = false;
    errorMessages: string[];
    medicationschedule: MedicationAdminScheduleDto = new MedicationAdminScheduleDto();
    medicationStatusEnum = MedicationStatusEnum;

    constructor(injector: Injector,
        private _EmarSettingService: EmarSettingsServiceProxy,
        private _medicationReadingsServiceProxy: MedicationReadingsServiceProxy) {
        super(injector);
        this.date = new Date();
        this.getAllAssessmentSetting();
    }

    counter(i: number): any {
        return new Array(i);
    }

    show(): void {

        const sDate = new Date();

        forkJoin([
            this._medicationReadingsServiceProxy.getMedicationReadingForEdit(this.medicationschedule.medicationAdminScheduleId, this.medicationScheduleId, moment(sDate)),
            this._medicationReadingsServiceProxy.getMedicationReadingsHistory(this.clientMedicationId, this.patientId)
        ]).subscribe(
            ([medicationReadingResult, medicationReadingsHistoryResult]) => {
                if (medicationReadingResult.medicationReading) {
                    this.MediactioReading = medicationReadingResult.medicationReading;
                }
                this.medReadings = medicationReadingsHistoryResult;
            },
            (error) => {
                console.error('Error occurred while fetching medication readings:', error);
            }
        );

        this.modal.show();
    }

    getAllAssessmentSetting() {
        this._EmarSettingService.getEmarDataFromSettings().subscribe(data => {
            this.EmarSettingData = data;
        });
    }
    save() {
        this._required = true;
        this.MediactioReading.medicationScheduleTimeId = this.medicationschedule.scheduleTimeId;
        this.MediactioReading.scheduleDate = moment(this.date);
        this.MediactioReading.medicationAdminScheduleId = this.medicationschedule.medicationAdminScheduleId;
        this.MediactioReading.medicationScheduleTimeId = this.medicationschedule.scheduleTimeId;
        this.MediactioReading.medicationScheduleId = this.medicationschedule.scheduleId;
        this.MediactioReading.clientMedicationId = this.medicationschedule.medicationId;


        if (this.isVitalsOrMeasurementsRequired()) {
            return false;
        }

        this.errorMessages = this.getValidationErrorMessages();

        if (this.errorMessages.length > 0) {
            this.showErrorMessages(this.errorMessages);
        } else {
            this.saveMedicationReading();
        }
    }
    assignValues(emar: MedicationAdminScheduleDto) {
        if (emar) {
            this.medicationschedule = emar;
            this.patientsName = emar.clientname;
            this.readVitals = emar.isVitalsReadingRequired;
            this.readSuger = emar.isbloodSugarReadingRequired;
            this.patientId = emar.clientId;
            this.clientMedicationId = emar.medicationId;
            this.readWeight = emar.isWeightReadingRequired;

            this.hideDiv = this.readVitals || this.readSuger || this.readWeight;
            this.medicationScheduleId = emar.scheduleId;
            //this.medicationName = `${emar.drugDescription} (${emar.strength} ${clientMedication?.drug_strengthUnit} ${clientMedication?.drug_DoseForm})`;
            this.medicationName = `${emar.drugDescription}(${emar.strength})`
            this.show();
        }
    }

    private saveMedicationReading(): void {
        this._medicationReadingsServiceProxy.createOrEdit(this.MediactioReading)
            .subscribe(result => {
                this.medicationReadingsSaved = true;
                const status = this.medicationschedule.status;
                this.saveReading.emit();
                this.close();
                this.notify.info(this.l('Readings recorded successfully'));
            });
    }
    parseBloodPressure(bloodPressure: string): [number, number] {
        if (!bloodPressure) {
            return [NaN, NaN];
        }
        const [systolic, diastolic] = bloodPressure.split('-').map(value => {
            const num = parseFloat(value.trim());
            return isNaN(num) ? NaN : num;
        });
        return [systolic, diastolic];
    }
    getValidationErrorMessages(): string[] {
        const errorMessages = [];
        const { temperature, bloodPressure, pulseRate, respirationRate, o2Level, weight, bloodSugar } = this.MediactioReading;

        if (this.EmarSettingData.temperature.min !== null && this.EmarSettingData.temperature.max !== null) {
            if (!this.isValidRange(this.EmarSettingData.temperature.min, this.EmarSettingData.temperature.max, temperature)) {
                errorMessages.push('Temperature values are not within the valid range.');
            }
        }

        const [systolicValue, diastolicValue] = this.parseBloodPressure(bloodPressure);
        if (this.EmarSettingData.systolicBloodPressure.min !== null && this.EmarSettingData.systolicBloodPressure.max !== null) {
            if (!this.isValidBloodPressure(systolicValue, diastolicValue)) {
                errorMessages.push('Blood Pressure values are not within the valid range.');
            }
        }

        if (this.EmarSettingData.pulseRate.min !== null && this.EmarSettingData.pulseRate.max !== null) {
            if (!this.isValidRange(this.EmarSettingData.pulseRate.min, this.EmarSettingData.pulseRate.max, pulseRate)) {
                errorMessages.push('Pulse Rate values are not within the valid range.');
            }
        }

        if (this.EmarSettingData.respirationRate.min !== null && this.EmarSettingData.respirationRate.max !== null) {
            if (!this.isValidRange(this.EmarSettingData.respirationRate.min, this.EmarSettingData.respirationRate.max, respirationRate)) {
                errorMessages.push('Respiration Rate values are not within the valid range.');
            }
        }

        if (this.EmarSettingData.o2Level.min !== null && this.EmarSettingData.o2Level.max !== null) {
            if (!this.isValidRange(this.EmarSettingData.o2Level.min, this.EmarSettingData.o2Level.max, o2Level)) {
                errorMessages.push('O2 Level values are not within the valid range.');
            }
        }

        if (this.EmarSettingData.weight.min !== null && this.EmarSettingData.weight.max !== null) {
            if (!this.isValidRange(this.EmarSettingData.weight.min, this.EmarSettingData.weight.max, weight)) {
                errorMessages.push('Weight values are not within the valid range.');
            }
        }

        if (this.EmarSettingData.bloodSugar.min !== null && this.EmarSettingData.bloodSugar.max !== null) {
            if (!this.isValidRange(this.EmarSettingData.bloodSugar.min, this.EmarSettingData.bloodSugar.max, bloodSugar)) {
                errorMessages.push('Blood Sugar values are not within the valid range.');
            }
        }

        return errorMessages;
    }


    isValidBloodPressure(systolicValue: any, diastolicValue: any): boolean {
        let systolicMin = this.EmarSettingData.systolicBloodPressure.min;
        let systolicMax = this.EmarSettingData.systolicBloodPressure.max;
        let diastolicMin = this.EmarSettingData.diastolicBloodPressure.min;
        let diastolicMax = this.EmarSettingData.diastolicBloodPressure.max;

        const isSystolicValid = this.isValidRange(systolicMin, systolicMax, systolicValue);
        const isDiastolicValid = this.isValidRange(diastolicMin, diastolicMax, diastolicValue);
        return isSystolicValid && isDiastolicValid;
    }

    isVitalsOrMeasurementsRequired(): boolean {
        const { temperature, bloodPressure, pulseRate, respirationRate, o2Level, weight, bloodSugar } = this.MediactioReading;

        const isVitalsRequired = this.readVitals && (
            (!this.temperatureDenied && !temperature) ||
            (!this.bloodPressureDenied && !bloodPressure) ||
            (!this.pulseRateDenied && !pulseRate) ||
            (!this.respirationRateDenied && !respirationRate) ||
            (!this.o2LevelDenied && !o2Level)
        );

        const isWeightRequired = this.readWeight && !this.weightDenied && !weight;
        const isSugarRequired = this.readSuger && !this.bloodSugarDenied && !bloodSugar;

        return isVitalsRequired || isWeightRequired || isSugarRequired;
    }


    showErrorMessages(errorMessages: string[]): void {
        this.errorMessages = errorMessages;
        let titleMessage = 'Please verify or confirm the following list of doubtful readings as they are not within the valid range';
        this.errorListModal.show(titleMessage);
    }




    isValidRange(min: number, max: number, currentVal: any): boolean {
        // Check if currentVal is undefined
        if (currentVal === undefined) {
            return true;
        }
        if (!currentVal) {
            return true;
        }
        // Convert currentVal to a number
        const currentValNumber = parseFloat(currentVal);

        // Check if currentValNumber is NaN
        if (isNaN(currentValNumber)) {
            return false;
        }

        // Check if min and max are undefined or unhandled
        if (min === undefined && max === undefined) {
            return true;
        }

        // Check if min or max is NaN
        if (isNaN(min as number) || isNaN(max as number)) {
            return false;
        }

        // Check if currentValNumber is within the range
        return currentValNumber >= (min as number) && currentValNumber <= (max as number);
    }

    close(): void {
        this.active = false;
        this.MediactioReading = new CreateOrEditMedicationReadingDto();
        this.medicationschedule = new MedicationAdminScheduleDto();
        this.medReadings = [];
        this.medicationReadingsSaved = false;
        this.modal.hide();
    }

    bloodSugerChecked(event: any) {
        if (event.currentTarget.checked) {
            this.bloodSugarDenied = true;
            this.MediactioReading.bloodSugar = "";
        }
        else {
            this.bloodSugarDenied = false;
        }
    }
    temperatureDeniedChecked(event: any) {
        if (event.currentTarget.checked) {
            this.temperatureDenied = true;
            this.MediactioReading.temperature = "";
        }
        else {
            this.temperatureDenied = false;
        }
    }
    pulseRateDeniedChecked(event: any) {
        if (event.currentTarget.checked) {
            this.pulseRateDenied = true;
            this.MediactioReading.pulseRate = "";
        }
        else {
            this.pulseRateDenied = false;
        }
    }
    respirationRateDeniedChecked(event: any) {
        if (event.currentTarget.checked) {
            this.respirationRateDenied = true;
            this.MediactioReading.respirationRate = "";
        }
        else {
            this.respirationRateDenied = false;
        }
    }
    bloodPressureDeniedChecked(event: any) {
        if (event.currentTarget.checked) {
            this.bloodPressureDenied = true;
            this.MediactioReading.bloodPressure = "";
        }
        else {
            this.bloodPressureDenied = false;
        }
    }
    weightDeniedChecked(event: any) {
        if (event.currentTarget.checked) {
            this.weightDenied = true;
            this.MediactioReading.weight = "";
        }
        else {
            this.weightDenied = false;
        }
    }
    o2LevelDeniedChecked(event: any) {
        if (event.currentTarget.checked) {
            this.o2LevelDenied = true;
            this.MediactioReading.o2Level = "";
        }
        else {
            this.o2LevelDenied = false;
        }
    }

    shouldShowPulseRateWarning(): boolean {
        return (
            (this.MediactioReading.pulseRateDenied === false || this.MediactioReading.pulseRateDenied === undefined) &&
            (this.MediactioReading.pulseRate === null || this.MediactioReading.pulseRate === undefined) &&
            this.readVitals === true
        );
    }
    shouldShowTemperatureWarning(): boolean {
        return (
            (this.MediactioReading.temperatureDenied === false || this.MediactioReading.temperatureDenied === undefined) &&
            (this.MediactioReading.temperature === null || this.MediactioReading.temperature === undefined) &&
            this.readVitals === true
        );
    }
    shouldShowRespirationRateWarning(): boolean {
        return (
            (this.MediactioReading.respirationRateDenied === false || this.MediactioReading.respirationRateDenied === undefined) &&
            (this.MediactioReading.respirationRate === null || this.MediactioReading.respirationRate === undefined) &&
            this.readVitals === true
        );
    }
    shouldShowBloodPressureWarning(): boolean {
        return (
            (this.MediactioReading.bloodPressureDenied === false || this.MediactioReading.bloodPressureDenied === undefined) &&
            (this.MediactioReading.bloodPressure === null || this.MediactioReading.bloodPressure === undefined) &&
            this.readVitals === true
        );
    }
    shouldShowWeightWarning(): boolean {
        return (
            (this.MediactioReading.weightDenied === false || this.MediactioReading.weightDenied === undefined) &&
            (this.MediactioReading.weight === null || this.MediactioReading.weight === undefined) &&
            this.readWeight === true
        );
    }
    shouldShowO2LevelWarning(): boolean {
        return (
            (this.MediactioReading.o2LevelDenied === false || this.MediactioReading.o2LevelDenied === undefined) &&
            (this.MediactioReading.o2Level === null || this.MediactioReading.o2Level === undefined) &&
            this.readVitals === true
        );
    }
    shouldShowBloodSugarWarning(): boolean {
        return (
            (this.MediactioReading.bloodSugarDenied === false || this.MediactioReading.bloodSugarDenied === undefined) &&
            (this.MediactioReading.bloodSugar === null || this.MediactioReading.bloodSugar === undefined) &&
            this.readSuger === true
        );
    }



    handleConfirm(result: boolean): void {
        this.showDialog = false;
        return;
        //if (result) {
        //    this.saveMedicationReading();
        //    console.log('Confirmed');
        //} else {            
        //    console.log('Cancelled');
        //    return;
        //}
    }
}
