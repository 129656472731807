import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { CreateOrEditOmmisionDto, CreateOrEditProgramEnrollmentDto, EMarsServiceProxy, GetEMarForViewDto, OmmisionsServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';


@Component({
    selector: 'add-ommission-dialog',
    templateUrl: './add-ommission-dialog.component.html',
    styleUrls: ['add-ommission-dialog.component.css']
})
export class AddOmissionDialogComponent extends AppComponentBase {

    @ViewChild('addOmissionModal', { static: true }) modal: ModalDirective;
    @ViewChild('addOmissionForm', { static: true }) form: NgForm;
    @Output() saveOmission: EventEmitter <any> =new EventEmitter<any> ();
    
    active = false;
    saving = false;
    date: Date;
    medicationScheduleId
    patientsName:string;
    IsLateAdministered:boolean;
    _required=false;
    medicationName: string;
    ommision = new CreateOrEditOmmisionDto();
    ommionHistory
    clientMedicationId;
    patientId; 
    houseNurseName : CreateOrEditProgramEnrollmentDto;
    patientEMr = new GetEMarForViewDto();
    status:string;
    clientData: any[] = [];
    selectclientName:string;
    constructor(injector: Injector,
        private _ommisionsServiceProxy: OmmisionsServiceProxy,private _EMarsServiceProxy: EMarsServiceProxy,private cd: ChangeDetectorRef) {
        super(injector);
    }

    counter(i: number): any {
        return new Array(i);
    }

    show(emar): void {
        if (emar) {
            this.ommision = new CreateOrEditOmmisionDto();
            this.patientEMr = new  GetEMarForViewDto();
            this.patientEMr = emar;
            this.status= this.patientEMr.clientMedicationScheduleTime.medicationDoseStatus;
            if(this.status=="Hold")
            {
            this.status = "Omitted";
            }
            this.IsLateAdministered = this.patientEMr.emrHistory.isLateAdministerHist;
            this.patientsName = this.patientEMr.clientMedication.patientsname;
           // this.houseNurseName = new CreateOrEditProgramEnrollmentDto();
          //  this.selectclientName = this.houseNurseName.houseNurse;
          //  this.ommision.nurseNotifiedId = this.houseNurseName.houseNurseId.toString();
            this.patientId = this.patientEMr.clientMedication.patientId;
            this.clientMedicationId = this.patientEMr.clientMedication.clientMedication.id;
            this.medicationScheduleId = this.patientEMr.clientMedicationScheduleTime.id;
            this.medicationName = this.patientEMr.clientMedication.drugDescription + "(" + this.patientEMr.clientMedication.strength + " " + this.patientEMr.clientMedication.drug_strengthUnit + " " + this.patientEMr.clientMedication.drug_DoseForm + ")"  ;
            this.modal.show();
            this.ommision.eMarId = this.patientEMr.clientMedicationScheduleTime.emarId;
            this.onShown();
        }
    }

    onShown(): void {
        var sDate = new Date; 
        this._ommisionsServiceProxy.getOmmisionForEdit(this.ommision.eMarId,this.medicationScheduleId,moment(sDate))
            .subscribe(result1 => {
                if (result1.ommision) {
                    this.ommision = result1.ommision;
                    this.selectclientName = this.ommision.nurseName;
                    // this.ommision.nurseName = this.ommision.nurseFName[0].toUpperCase() + this.ommision.nurseFName.Substring(1) + this.ommision.nurseLName[0].toUpperCase() + this.ommision.nurseLName.Substring(1);
                     
                }
            });
            this._ommisionsServiceProxy.getOmmisionHistory(this.clientMedicationId,this.patientId)
            .subscribe(result => {
                {
                    this.ommionHistory = result;
                }
            })
 
    }

    nurseNotifiedChecked(event: any)
    {
        if(event.currentTarget.checked)
        {
            this.selectclientName = this.houseNurseName.houseNurse;
            this.ommision.nurseNotifiedId = this.houseNurseName.houseNurseId.toString();
        }
        else
        {
            this.selectclientName = null;
            this.ommision.nurseNotifiedId = null;

        }

    }
    save() {
        this._required = true;
        if(this.ommision.comment == null && this.patientEMr.emrHistory.isLateAdministerHist)
        {
            return false;
        }
        else{
            this.ommision.medicationScheduleTimeId = this.patientEMr.clientMedicationScheduleTime.id;           
            this.ommision.scheduleDate = moment(this.date);
            this.ommision.patientName = this.patientEMr.clientMedication.patientsname;
            this.ommision.drugDescription = this.patientEMr.clientMedication.drugDescription;
            this.ommision.patientId = this.patientId;
            let CurrentDate = new Date();
            this.ommision.omittedOn = CurrentDate.toString();
            this._ommisionsServiceProxy.createOrEdit(this.ommision)
            .subscribe(result => {
                this.saveOmission.emit(this.ommision.comment);
                this.close();
              //  this.notify.info(this.l('SavedSuccessfully'));
               
            });
        }
      
    }
    searchClient(event: any) {
        
        var trimmed = this.selectclientName.trim();
        if (trimmed.length == 0) {         
            this.clientData = null;
            this.clientData = [];           
            this.cd.detectChanges();
        }
        let wordSearch = this.selectclientName.trim();
        setTimeout(() => {
            if (wordSearch == this.selectclientName.trim()) {
                if (this.selectclientName.trim()) {                
                    this._EMarsServiceProxy.getassociatedHouseNurse(wordSearch,this.patientId)
                        .pipe(finalize(() => { }))
                        .subscribe(data => {                         
                            this.clientData = data;                           
                            if (data.length == 0)
                                this.selectclientName = null;                          
                            this.cd.detectChanges();
                        })
                } else {
                    this.selectclientName = null;
                }
            }          
        }, 2000);
    } 

    onSelect(event) {
        //  this.PatientId = patientId;
          // this.addmedicationheader.recordid = this.PatientId;
          let data = event
          this.selectclientName = data.userName; 
         this.ommision.nurseNotifiedId = data.userId;         
    }
    close(): void {
        this.active = false;
        this.selectclientName = null;
        this.modal.hide();
    }
}
