import { AfterViewChecked, Component, ElementRef, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { outputs } from '@syncfusion/ej2-angular-navigations/src/sidebar/sidebar.component';
import { CreateOrEditMedicatioNoteDto, EMarHistoriesServiceProxy, GetEMarForViewDto, MedicationAdminScheduleDto, MedicatioNotesServiceProxy, MedicationStatusEnum } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';

@Component({
    selector: 'administer-Med-dialog',
    templateUrl: './administerMedDialog.component.html',
    styleUrls: ['administerMedDialog.component.css']
})
export class AdministerMedDialogComponent extends AppComponentBase {

    @ViewChild('administerMeddialog', { static: true }) modal: ModalDirective;
    @ViewChild('administerMeddialogForm', { static: true }) form: NgForm;
    @Output() saveAdminister: EventEmitter<any> = new EventEmitter<any>();
    active = false;
    saving = false;
    IsLateAdministered = true;
    patientEMr: MedicationAdminScheduleDto = new MedicationAdminScheduleDto();
    patientName: string;
    status: MedicationStatusEnum;
    medicationschedule: MedicationAdminScheduleDto = new MedicationAdminScheduleDto();
    medicationStatusEnum = MedicationStatusEnum;
    drugName: string;
    comment: string;
    _required = false;
    hide = false;
    constructor(injector: Injector,
        private _eMarHistoriesServiceProxy: EMarHistoriesServiceProxy) {
        super(injector);
    }

    counter(i: number): any {
        return new Array(i);
    }
    ngOnInit() {
        this.comment = "";

    }
    show(emar: MedicationAdminScheduleDto): void {

        var data = emar
        this.patientEMr = emar;
        this.status = this.medicationschedule.status;
        if (this.status != MedicationStatusEnum.Administered) {
            this.hide = true;
        }
        else {
            this.hide = false;
        }
        this.patientName = this.patientEMr.clientname;
        this.drugName = this.patientEMr.drugDescription;
        this.modal.show();
    }

    onShown(): void {
        var sDate = new Date;
        this.comment = "";
        this.comment = this.patientEMr.comments;
    }

    save() {
        this._required = true;
        if (this.comment == null || this.comment == '' || this.comment == undefined) {
            return false;
        }
        else {
            this.saveAdminister.emit(this.comment)
            this.modal.hide();
        }

    }

    close(): void {
        this.active = false;
        this.comment = "";
        this.modal.hide();
    }


}
