
<form #acknowledgeForm="ngForm" autocomplete="off" *ngIf="this.acknowledgeDto.entityID">
    <div class="content d-flex flex-column flex-column-fluid">
        <div>
            <div class="card-footer mb-4">
                <div class="row">
                    <div class="col-xl-12 text-lg-right">                       
                        <button type="button" class="btn btn-primary {{disableacknowlege ? 'not-allowed disabled' : ''}} mr-3" (click)="Onclick()" [disabled]="disableacknowlege">
                            <i class="fa-thumbs-up fas" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Acknowledge"></i>
                            {{l("I Acknowledge")}}
                        </button>
                        <button type="button" mat-raised-button (click)="viewModal('bhevaiour')" class="btn btn-warning" data-toggle="tooltip" data-placement="bottom" title="View">
                            <i class="fas fa fa-eye" aria-hidden="true"></i>{{l("Show Acknowledgements")}}
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<!--<app-view-acknowledgement #app-view-acknowledgement></app-view-acknowledgement>-->


<div bsModal #ackmodal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="ackmodal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content text-center">
            <div class="modal-header text-white d-flex pt-3 pb-3  font-weight-bold">
                <h5 class="modal-title text-left col-8 ml-n2">
                    <span>Acknowlegement : {{acknowledgeDto.username}}</span>
                </h5>               
                <div class="input-icon input-icon-right">
                    <input type="search" class="form-control h-30px" placeholder="Search..."  name="SearchClient"
                           id="SearchClient" [(ngModel)]="usernamesearch" (input)="searchClient()">
                    <span>
                        <i class="flaticon2-search-1 icon-md"></i>
                    </span>
                </div>
                <!-- <button type="button" class="border-0 font-size-h5 mr-n2 bg-white" data-dismiss="modal" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button> -->
            </div>
            <div class="card-body pt-2  {{isLoading==true ? 'overlay overlay-block' : ''}}">
                <div class="overlay-layer bg-dark-o-10" *ngIf="isLoading">
                    <div class="spinner spinner-primary"></div>
                </div>
                <div class="{{isLoading==true ? 'overlay-wrapper' : 'row'}}">

                    <div class="d-flex align-items-center mb-2 p-2 alternative_cls col-4" *ngFor="let data of _rows;let i=index;">
                        <div class="symbol symbol-40 symbol-light-success mr-3" style="cursor:pointer">
                            <span class="symbol-label h-40px w-40px bg-white  mt-n2">
                                <img src="{{data.profilePictureUrl}}" class="h-100 align-self-end rounded-circle" alt="">
                            </span>
                        </div>
                        <div class="d-flex flex-column flex-grow-1 text-left">
                            <a class="text-hover-primary mb-0 font-weight-bold">{{data.username}}</a>
                            <span class="text-muted">{{data.creationOn | date : "MM/dd/yyyy h:mm a"}} <span></span></span>
                        </div>
                    </div>
                    <p class="text-center font-weight-normal font-size-lg w-100" *ngIf="nodata"><br>No data</p>
                    
                </div>
            </div>           
            
            <div class="modal-footer justify-content-center pb-2 pt-2">
                <button type="button" class="btn btn-primary blue" (click)="close()">Close</button>
            </div>
        </div>
    </div>

</div>



